import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Heading from 'components/base/Heading'
import './Dropzone.scss'

export default function Dropzone({ handler, accept, header, body }) {
  const onDrop = useCallback(handler, [handler])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div id="Dropzone" {...getRootProps()} accept={accept}>
      <input {...getInputProps()} accept={accept} />
      <Heading size={4} fullWidth align="center" wrap>
        {header}
      </Heading>
      <p>{body}</p>
    </div>
  )
}
