import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getExpirationMessage } from 'utils/common'
import { Link } from 'react-router-dom'
import PreviewIcon from 'assets/preview-icon.svg'
import EditIcon from 'assets/edit.svg'
import Tooltip from 'components/base/Tooltip'
import Ellipsis from 'components/base/Ellipsis'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState, setModal } from 'redux/app/appReducer'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import './AdminCollectionHeader.scss'

function AdminCollectionHeader() {
  const description = useSelector(store => store.collection.description)
  const expirationEpoch = useSelector(store => store.collection.expirationEpoch)
  const suppressExpiration = useSelector(
    store => store.collection.suppressExpiration
  )
  const title = useSelector(store => store.collection.title)
  const visibility = useSelector(store => store.collection.visibility)
  const userId = useSelector(store => store.collection.userId)
  const errorState = useSelector(store => store.collection.errorState)
  const collectionId = useSelector(store => store.collection.collectionId)
  const projectId = useSelector(store => store.collection.projectId)
  const owner = useSelector(store => store.collection.owner)
  const spireFile = useSelector(store => store.collection.spireFile)
  const spireProject = useSelector(store => store.collection.spireProject)
  const [expiresMessage, setExpiresMessage] = useState()
  const [copyCollectionIdIcon, setCopyCollectionIdIcon] = useState(
    'fa fa-clipboard'
  )
  const [copyProjectIdIcon, setCopyProjectIdIcon] = useState('fa fa-clipboard')
  const dispatch = useDispatch()

  useEffect(() => {
    if (spireFile) {
      const message = getExpirationMessage({
        expirationEpoch,
        suppressExpiration,
      })
      setExpiresMessage(message || 'No Expiration')
    }
  }, [expirationEpoch, suppressExpiration, spireFile])

  const togglePreview = () => dispatch(setModal('AdminPreviews'))
  const toggleEdit = () => dispatch(setModal('AdminEdit'))
  const toggleFiles = () => dispatch(setModal('AdminFiles'))
  const toggleComments = () => dispatch(setModal('AdminComments'))

  function copyCollectionId() {
    setCopyCollectionIdIcon('fa fa-check')
    navigator.clipboard.writeText(collectionId)
    window.setTimeout(() => {
      setCopyCollectionIdIcon('fa fa-clipboard')
    }, 2000)
  }

  function copyProjectId() {
    setCopyProjectIdIcon('fa fa-check')
    navigator.clipboard.writeText(projectId)
    window.setTimeout(() => {
      setCopyProjectIdIcon('fa fa-clipboard')
    }, 2000)
  }

  return (
    <div id="AdminCollectionHeader">
      <Helmet>
        <title>{`Spire Admin | ${title || 'powered by iZotope'}`}</title>
      </Helmet>
      <Link to="/">
        <div className="Back">
          <i className="fa fa-arrow-left" />
          <Heading size={4}>back</Heading>
        </div>
      </Link>
      <div className="Header">
        {title ? (
          <>
            <Heading size={2}>
              {visibility === 'privatePath' && spireProject && spireProject.name
                ? spireProject.name
                : title}
            </Heading>
            <div className="ButtonContainer">
              <Button
                type="simple"
                className="ImgButton"
                onClick={toggleFiles}
                id="collectionFiles"
              >
                <i className="fa fa-file-code" />
                <Tooltip
                  id="collectionFiles"
                  position="bottom"
                  text="View Collection Files"
                />
              </Button>
              <Button
                type="simple"
                className="ImgButton"
                onClick={toggleComments}
                id="collectionComments"
              >
                <i className="fa fa-comment-alt" />
                <Tooltip
                  id="collectionComments"
                  position="bottom"
                  text="View Collection Comments"
                />
              </Button>
              <Button
                type="simple"
                className="ImgButton"
                id="copyCollectionId"
                onClick={copyCollectionId}
              >
                <i className={copyCollectionIdIcon} />
                <Tooltip
                  id="copyCollectionId"
                  position="bottom"
                  text="Copy Collection ID to Clipboard"
                />
              </Button>
              <Button
                type="simple"
                className="ImgButton"
                id="copyProjectId"
                onClick={copyProjectId}
              >
                <i className={copyProjectIdIcon} />
                <Tooltip
                  id="copyProjectId"
                  position="bottom"
                  text="Copy Project ID to Clipboard"
                />
              </Button>
              <Button
                type="simple"
                className="ImgButton"
                id="previewCollection"
                onClick={togglePreview}
              >
                <img src={PreviewIcon} alt="Preview" />
                <Tooltip
                  id="previewCollection"
                  position="bottom"
                  text="View Public Collection Previews"
                />
              </Button>
              <Button
                type="simple"
                className="ImgButton"
                id="editCollection"
                onClick={toggleEdit}
              >
                <img src={EditIcon} alt="Edit" />
                <Tooltip
                  id="editCollection"
                  position="bottom"
                  text="Edit Collection"
                />
              </Button>
            </div>
          </>
        ) : (
          <Heading size={2} fullWidth>
            {errorState ? 'Failed to load.' : 'Loading Collection'}
            {!errorState && <Ellipsis />}
          </Heading>
        )}
      </div>
      <div id="UserDetails">
        {title && (
          <>
            <Heading size={4} className={visibility} id="CollectionVisibility">
              {visibility === 'privatePath'
                ? 'Private Collection'
                : 'Public Collection'}
            </Heading>
            <Heading size={6}>
              <i className="fa fa-clock" />
              {'  '}
              {expiresMessage || 'No Expiration'}
            </Heading>
            <Heading
              size={6}
              id="CollectionUserId"
              onClick={() =>
                dispatch(
                  setAppState({
                    modal: 'PublicUserProfile',
                    modalState: { user: owner },
                  })
                )
              }
            >
              <i className="fa fa-user" />{' '}
              {owner && owner.displayName
                ? owner.displayName
                : userId === '0'
                ? 'Unauthenticated User'
                : userId}
              <Tooltip
                id="CollectionUserId"
                position="bottom"
                text="View public profile for this user"
              />
            </Heading>
          </>
        )}
      </div>
      <Heading size={3}>
        {!title
          ? errorState
            ? 'Check the developer tools console for more information (⌘ + ↑ + i)'
            : ''
          : description || '[ This collection has no description. ]'}
      </Heading>
    </div>
  )
}

export default AdminCollectionHeader
