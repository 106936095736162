import Button from 'components/base/Button'
import ExternalLink from 'components/base/ExternalLink'
import Loading from 'components/base/Loading'
import { callApi, createSpireFile } from 'graphql/izo_api'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateNativeAppImportLink } from 'utils/common'
import Heading from 'components/base/Heading'
import Ellipsis from 'components/base/Ellipsis'
import Lottie from 'react-lottie'
import FinishedAnimation from 'assets/finished-animation.json'
import './CreateSpireFile.scss'
import { log } from 'redux/analytics/analyticsReducer'
import { NATIVE_APP_BASE_URL } from 'utils/constants'

export default function CreateSpireFile({ close }) {
  const s3_prefix = useSelector(store => store.collection.s3_prefix)
  const collectionId = useSelector(store => store.collection.collectionId)
  const spireProject = useSelector(store => store.collection.spireProject)
  const title = useSelector(store => store.collection.title)
  const isPrivate = useSelector(store => store.collection.isPrivate)
  const owner = useSelector(store => store.collection.title)
  const [error, setError] = useState()
  const [link, setLink] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    async function handleImport() {
      try {
        if (!spireProject) setError(true)
        const res = await callApi(createSpireFile, {
          id: collectionId,
          s3_prefix,
        })
        const spire_file_signed_url = res.data.createSpireFile
        const link = await generateNativeAppImportLink(
          spire_file_signed_url,
          spireProject ? spireProject.name : title
        )
        setLink(link)
        dispatch(log({ event: 'createSpireFileSuccess' }))
      } catch (e) {
        setError(e)
        console.error(e)
        dispatch(log({ event: 'createSpireFileError' }))
      }
    }
    if (owner && isPrivate) {
      window.open(NATIVE_APP_BASE_URL, '_blank')
    }
    handleImport()
  }, []) // eslint-disable-line

  const supportUrl = 'https://support.izotope.com/hc/en-us/requests/new'
  const SupportButton = ({ text = 'Need Help?' }) => (
    <ExternalLink to={supportUrl}>
      <Button type="primary" center>
        {text}
      </Button>
    </ExternalLink>
  )

  return (
    <div id="CreateSpireFile">
      {error ? (
        <>
          <Heading size={1} fullWidth align="center">
            😢
          </Heading>
          <br />
          <Heading size={4} fullWidth align="center">
            Uh oh...
          </Heading>
          <p>This project is not available to be imported to the Spire App.</p>
          <br />
          <SupportButton />
        </>
      ) : link ? (
        <>
          <Lottie options={FinishedAnimation} height={200} width={200} />
          <p>
            Done! Your project is ready to import.
            <br />
            Click to the link below to get started.
          </p>
          <br />
          <br />
          <ExternalLink to={link}>
            <Button type="primary" center onClick={close}>
              Open Project in the Spire App
            </Button>
          </ExternalLink>
        </>
      ) : (
        <>
          <Loading relative noDelay />
          <br />
          <Heading size={4} fullWidth align="center">
            Preparing your project...
            <Ellipsis />
          </Heading>
          <br />
          <p>
            Hang tight! We're packaging your project to be imported to the Spire
            app.
          </p>
        </>
      )}
    </div>
  )
}
