import React from 'react'
import './ExternalLink.scss'

export default function ExternalLink({ to, children, className, ...props }) {
  return (
    <a
      href={to}
      className={`iz_link${className ? ` ${className}` : ''}`}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  )
}
