export * from './queries'

export const listCollectionsBySearchParameter = /* GraphQL */ `
  query ListCollectionsBySearchParameter($input: SearchParameterInput!) {
    listCollectionsBySearchParameter(input: $input) {
      items {
        id
        identityId
        userId
        visibility
        title
        createdAt
        expirationEpoch
        suppressExpiration
        sortKey
        themeColor
      }
    }
  }
`

export const listCollectionsForCurrentUser = `
  query ListCollectionsForCurrentUser($visibility: Visibility!, $limit: Int, $nextToken: String) {
    listCollectionsForCurrentUser(visibility: $visibility, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        spireProject{
          duration
          modified
          name
          tracks {
            color_index
          }
        }
        visibility
        title
        createdAt
        updatedAt
        deleted
        suppressExpiration
        expirationEpoch
      }
      nextToken
    }
  }
`

export const listPublicCollectionsForCurrentUser = `
  query ListCollectionsForCurrentUser($visibility: Visibility!, $limit: Int, $nextToken: String) {
    listCollectionsForCurrentUser(visibility: $visibility, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        visibility
        title
        createdAt
        updatedAt
        deleted
        suppressExpiration
        expirationEpoch
      }
      nextToken
    }
  }
`

export const getProjectInfo = /* GraphQL */ `
  query GetProjectInfo($id: String!) {
    getCollectionByProjectId(projectId: $id) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      deleted
      preventComments
      s3_prefix
      processing {
        items {
          id
          opInfo
        }
      }
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        links
      }
    }
  }
`

export const getCollectionInfo = /* GraphQL */ `
  query GetCollectionInfo($id: ID!) {
    getCollectionById(id: $id) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      deleted
      preventComments
      s3_prefix
      processing {
        items {
          id
          opInfo
        }
      }
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        links
      }
    }
  }
`



export const getProjectAudio = /* GraphQL */ `
  query GetCollectionAudio($id: String!) {
    getCollectionByProjectId(projectId: $id) {
      publicAccess
      visibility
      identityId
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      spireFile {
        key
        name
        size
        signed_url
        last_modified
      }
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
      stemFiles {
        name
        key
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getCollectionAudio = /* GraphQL */ `
  query GetCollectionAudio($id: ID!) {
    getCollectionById(id: $id) {
      publicAccess
      visibility
      identityId
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      spireFile {
        key
        name
        size
        signed_url
        last_modified
      }
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
      stemFiles {
        name
        key
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getProjectAudioAsAdmin = /* GraphQL */ `
  query GetProjectAudioAsAdmin($id: String!) {
    getCollectionById(projectId: $id) {
      publicAccess
      visibility
      identityId
      deleted
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      spireFile {
        key
        name
        size
        signed_url
        last_modified
      }
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        instrumentID
        track_id
        pan
        gain
        mute
        flac_key
        mp3_key
        stereoize
        color_index
      }
      stemFiles {
        name
        key
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getCollectionAudioAsAdmin = /* GraphQL */ `
  query GetCollectionAudio($id: ID!) {
    getCollectionById(id: $id) {
      publicAccess
      visibility
      identityId
      deleted
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              type
              signed_url
            }
            mp3_file {
              key
              name
              type
              signed_url
            }
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      spireFile {
        key
        name
        size
        signed_url
        last_modified
      }
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        instrumentID
        track_id
        pan
        gain
        mute
        flac_key
        mp3_key
        stereoize
        color_index
      }
      stemFiles {
        name
        key
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getProjectMixes = /* GraphQL */ `
  query GetProjectMixes($id: String!) {
    getCollectionByProjectId(projectId: $id) {
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getCollectionMixes = /* GraphQL */ `
  query GetCollectionMixes($id: ID!) {
    getCollectionById(id: $id) {
      mixFiles {
        name
        key
        enhanced
        type
        size
        signed_url
        last_modified
      }
    }
  }
`

export const getProjectComments = /* GraphQL */ `
  query GetProjectComments($id: ID!) {
    getCollectionById(id: $id) {
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          links
        }
        updatedAt
        timestamp
        comment
        reactions {
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            links
          }
          emoji
          updatedAt
        }
      }
    }
  }
`

export const getProject = `
query GetProject($id: ID!) {
  getSyncedProject(id: $id) {
    serverState
  }
}
`
