export default function AuthReducer(state, action) {
  const { payload, type } = action

  if (!type) {
    console.log(`missing action type`)
  }

  switch (type) {
    case 'AUTH_DATA_INIT':
      return {
        ...state,
        isAuthenticating: true,
        isError: false,
      }
    case 'AUTH_DATA_RETRIEVE_FAILURE':
      return {
        ...state,
        apiAuthMode: 'AWS_IAM',
        isAuthenticated: false,
        isAuthenticating: false,
        isError: false,
      }
    case 'AUTH_DATA_RETRIEVE_SUCCESS':
      return {
        ...state,
        apiAuthMode: 'AMAZON_COGNITO_USER_POOLS',
        isAuthenticated: true,
        isAuthenticating: false,
        isError: false,
      }
    case 'AUTH_DATA_SUCCESS':
      return {
        ...state,
        apiAuthMode: 'AMAZON_COGNITO_USER_POOLS',
        isAuthenticated: true,
        isAuthenticating: false,
        isError: false,
        oAuthFinished: true,
        user: payload.user,
      }
    case 'AUTH_DATA_FAILURE':
      return {
        ...state,
        apiAuthMode: 'AWS_IAM',
        isAuthenticated: false,
        isAuthenticating: false,
        isError: true,
      }
    case 'RESET_AUTH_DATA':
      return {
        ...state,
        apiAuthMode: 'AWS_IAM',
        isAuthenticated: false,
        isAuthenticating: false,
        isError: false,
        user: null,
        oAuthFinished: false,
      }
    case 'USER_PROFILE_SUCCESS':
      return {
        ...state,
        profile: action.profile,
      }
    default:
      throw new Error(
        `Unknown action type ${JSON.stringify(action)} for authReducer`
      )
  }
}
