import React, { memo, useEffect } from 'react'
import './Tooltip.scss'

const Tooltip = ({ id, position = 'bottom', text, style = {} }) => {
  useEffect(() => {
    const element = document.getElementById(id)
    if (element) {
      const tooltip = document.getElementById(`tooltip-${id}`)
      element.onmouseenter = () => {
        tooltip.className = 'visible'
      }
      element.onmouseleave = () => {
        tooltip.className = 'invisible'
      }
    }
  }, [id])

  return (
    <div className={`Tooltip ${position}`} style={style}>
      <p id={`tooltip-${id}`} className="invisible">
        {text}
      </p>
    </div>
  )
}

export default memo(Tooltip)
