import React, { useEffect } from 'react'
import {
  getBrowserName,
  getBrowserVersion,
  isDeviceSupportedBySpire,
} from 'utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie'
import { load } from 'redux/audio/audioReducer'
import ExpiredAnimation from 'assets/expired.json'
import UploadingAnimation from 'assets/uploading.json'
import ProcessingAnimation from 'assets/loading_instruments.json'
import Oops from 'assets/oops.svg'
import Ellipsis from 'components/base/Ellipsis'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import ExternalLink from 'components/base/ExternalLink'
import './UnavailableProject.scss'
import { log } from 'redux/analytics/analyticsReducer'

const supportUrl = 'https://support.izotope.com/hc/en-us/requests/new'
const spireAppMobileUrl = 'https://spirestudio.app.link'
const spireAppDesktopUrl =
  'https://www.izotope.com/en/products/spire-studio/spire-ios-app.html'

const UnavailableProject = ({ status }) => {
  const mobile = useSelector(store => store.app.mobile)
  const createdAt = useSelector(store => store.collection.createdAt)
  const failedToLoad = useSelector(store => store.audio.failedToLoad)
  const audio = useSelector(store => store.collection.audio)
  const elapsedMins = (new Date(Date.now()) - new Date(createdAt)) / (1000 * 60)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!['expired', 'deleted'].includes(status)) {
      dispatch(log({ event: 'projectUnavailable', params: { status } }))
    }
  }, [status]) // eslint-disable-line

  const OpenAppButton = ({ text = 'Open Spire App' }) => (
    <ExternalLink
      to={isDeviceSupportedBySpire() ? spireAppMobileUrl : spireAppDesktopUrl}
    >
      <Button type="primary">{text}</Button>
    </ExternalLink>
  )

  const SupportButton = ({ text = 'Need Help?' }) => (
    <ExternalLink to={supportUrl}>
      <Button type="primary">{text}</Button>
    </ExternalLink>
  )

  const HomeButton = ({ text = 'View your shared projects' }) => (
    <Link to="/">
      <Button type="primary">{text}</Button>
    </Link>
  )

  switch (status) {
    case 'expired': {
      return (
        <div id="UnavailableProject">
          <Lottie options={ExpiredAnimation} height={264} width={264} />
          <Heading size={3} fullWidth align="center">
            This shared project has expired 😢
          </Heading>
          <p>
            No worries, you can still re-share this project from the Spire App!
          </p>
          <div id="ErrorButtons">
            <OpenAppButton />
          </div>
        </div>
      )
    }
    case 'no-spire-file': {
      return elapsedMins < 10 ? (
        <div id="UnavailableProject">
          <Lottie options={UploadingAnimation} height={264} width={264} />
          <Heading size={3} fullWidth align="center">
            Hang tight! {mobile && <br />}Your project is uploading ☁
          </Heading>
          <p>
            Make sure to <strong>keep the Spire App open</strong>
            <br />
            to track progress and make sure the upload can finish.
          </p>
          <div id="ErrorButtons">
            <OpenAppButton />
            <HomeButton />
          </div>
        </div>
      ) : (
        <div id="UnavailableProject">
          <img src={Oops} alt="Oops" />
          <Heading size={3} fullWidth align="center">
            Looks like this project never finished uploading 😢
          </Heading>
          <br />
          <p>
            Make sure to <strong>keep the Spire App open while sharing </strong>
            {!mobile && <br />}
            to track progress and make sure the upload can finish.
          </p>
          <div id="ErrorButtons">
            <OpenAppButton />
            <HomeButton />
            <SupportButton />
          </div>
        </div>
      )
    }
    case 'loading': {
      const minDimension = Math.min(window.innerWidth, window.innerHeight)
      const animationSize = Math.min(Math.floor(minDimension * 0.5), 200)
      return (
        <div id="UnavailableProject">
          <div className="AnimationWrapper">
            <Lottie
              options={ProcessingAnimation}
              height={animationSize}
              width={animationSize}
            />
          </div>
          <br />
          <br />
          {failedToLoad ? (
            <>
              <Heading size={3} fullWidth align="center">
                Whoops - something didn't load right 🤔
              </Heading>
              <p>
                It takes a lot of data to play high quality audio. Give it
                another shot!
              </p>
              <div id="ErrorButtons">
                <Button
                  type="primary"
                  onClick={() => dispatch(load({ audio, shouldUnload: true }))}
                >
                  Reload your Audio
                </Button>
              </div>
            </>
          ) : (
            <>
              <Heading size={3} fullWidth align="center">
                Loading your audio
                <Ellipsis style={{ padding: 0 }} />
              </Heading>
              <p className="loading-delay">
                <span style={{ fontWeight: 900 }}>
                  This is taking a while...
                </span>
                <br />
                If you have a weak mobile connection, it might help to refresh.
                <br />
                <strong onClick={() => window.location.reload()}>
                  Click here to refresh
                </strong>
              </p>
            </>
          )}
        </div>
      )
    }
    case 'browser-error': {
      return (
        <div id="UnavailableProject">
          <img src={Oops} alt="Oops" />
          <Heading size={3} fullWidth align="center">
            Looks like something went wrong 😔
          </Heading>
          <br />
          <p>
            Your browser might not support our audio engine.
            <br />
            We recommend using the latest version of Google Chrome on desktop or
            mobile.
            <br />
            You are using {getBrowserName()} {getBrowserVersion()}
          </p>
          <br />
          <div id="ErrorButtons">
            <Button type="primary" onClick={() => window.location.reload()}>
              Try Reloading the Page
            </Button>
            <SupportButton text="Report an Error" />
          </div>
        </div>
      )
    }
    default: {
      return (
        <div id="UnavailableProject">
          <img src={Oops} alt="Oops" />
          <Heading size={3} fullWidth align="center">
            Hmm... something's not right 🧐
          </Heading>
          <p>
            The project you're looking for may be private, deleted,{' '}
            {!mobile && <br />}
            or improperly uploaded.
          </p>
          <div id="ErrorButtons">
            <HomeButton />
            <SupportButton text="Need help sharing your project?" />
          </div>
        </div>
      )
    }
  }
}

export default UnavailableProject
