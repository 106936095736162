export * from './mutations'

export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CommentCreateInput!) {
    createComment(input: $input) {
      projectId
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
        }
        updatedAt
        timestamp
        comment
        reactions {
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            links
          }
          emoji
          updatedAt
        }
      }
    }
  }
`;

export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: CommentUpdateInput!) {
    updateComment(input: $input) {
      projectId
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
            bio
            city
            country
            links
        }
        updatedAt
        timestamp
        comment
        reactions {
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            links
          }
          emoji
          updatedAt
        }
      }
    }
  }
`;

export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: CommentDeleteInput!) {
    deleteComment(input: $input) {
      projectId
    }
  }
`;

export const createReaction = /* GraphQL */ `
mutation CreateReaction($input: ReactionCreateInput!) {
  createReaction(input: $input) {
    projectId
    comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          links
        }
        updatedAt
        timestamp
        comment
        reactions {
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            links
          }
          emoji
          updatedAt
        }
      }
  }
}
`;

export const deleteReaction = /* GraphQL */ `
mutation DeleteReaction($input: ReactionDeleteInput!) {
  deleteReaction(input: $input) {
    projectId
    comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          links
        }
        updatedAt
        timestamp
        comment
        reactions {
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            links
          }
          emoji
          updatedAt
        }
      }
  }
}
`;

export const createUnpackedSpireProject = /* GraphQL */ `
mutation CreateUnpackedSpireProject($input: CreateUnpackedProjectInput!) {
  createUnpackedSpireProject(input: $input) {
    spireProject {
      id
      project_file_version
      duration
      dirty
      tempo
      modified
      time_signature {
        beats
        divisions
      }
      tracks {
        id
        gain
        mute
        pan
        stereoize
        steroize
        color_index
      }
      metronome_on
      created
      num_tracks
      name
    }
  }
}
`;

export const syncProject = /*GraphQL*/ `
mutation SyncProject($input: SyncProjectInput!) {
  syncProject(input: $input) {
      id
      resolvedState
      serverState
  }
}`;

export const transcodeAudioFile = /* GraphQL */ `
  mutation TranscodeAudioFile($input: TranscodeInput!) {
    transcodeAudioFile(input: $input) {
      id
    }
  }
`;

export const invokeDSP = `
mutation InvokeDSP($input: InvokeDSPInput!) {
  invokeDSP(input: $input) {
    id 
  }
}
`