import React, { useState, useEffect } from 'react'
import { getCollectionIdFromProjectId } from './utils'
import { useParams } from 'react-router'
import Loading from 'components/base/Loading'
import { useDispatch } from 'react-redux'
import { log } from 'redux/analytics/analyticsReducer'
import AdminCollection from 'components/admin/AdminCollection'
import UnavailableProject from 'components/shared/UnavailableProject'

export default function AdminProject({ visibility }) {
  const { projectId } = useParams()
  const [collectionId, setCollectionId] = useState()
  const [error, setError] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(log({ event: 'projectLoad', params: { projectId } }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function handleCollectionId() {
      const id = await getCollectionIdFromProjectId(projectId, visibility)
      if (id) {
        setCollectionId(id)
      } else {
        setError(true)
      }
    }
    handleCollectionId()
  }, [projectId, visibility])

  if (error) {
    return <UnavailableProject />
  }
  return collectionId ? (
    <AdminCollection collectionId={collectionId} />
  ) : (
    <Loading />
  )
}
