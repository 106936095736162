import { Storage } from 'aws-amplify'
import { getFileNameWithoutExtension } from 'utils/common'
import {
  STORAGE_BUCKET,
  STORAGE_REGION,
  TIME_SIGNATURE_LOOKUP,
} from 'utils/constants'
import {
  callApi,
  createSpireProjectFromStems,
  createStem,
} from 'graphql/izo_api'

export const DEFAULT_PROJECT_ATTRS = {
  name: 'Untitled',
  timeSignature: '4/4',
  tempo: 110,
}

export const parseTitle = str => {
  const parsedStr = getFileNameWithoutExtension(str)
  return parsedStr
    .replace(/[_\\-\\.-]/g, ' ')
    .replace('  ', ' ')
    .replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

export async function createNewStemRecord(collectionId, file, key) {
  try {
    await callApi(createStem, {
      input: {
        id: collectionId,
        original: {
          bucket: STORAGE_BUCKET,
          region: STORAGE_REGION,
          key,
          name: file.name,
        },
        visibility: 'publicPath',
      },
    })
    return
  } catch (e) {
    console.error(e)
  }
}

export async function uploadFile(key, file, visibility, progressCallback) {
  const res = await Storage.put(key, file, {
    level: visibility ? 'private' : 'public',
    contentType: file.type,
    progressCallback,
  })
  return res
}

export const createS3Key = (collectionId, filename) =>
  `collections/${collectionId}/uploads/${filename}`

export const createUnpackedS3Key = (collectionId, filename) =>
  `collections/${collectionId}/unpacked/${filename}`

export async function createSpireProject({
  collectionId,
  projectId,
  title,
  files,
}) {
  try {
    const { name, tempo, timeSignature } = DEFAULT_PROJECT_ATTRS
    const stemFiles = files.map(f => ({
      location: {
        bucket: STORAGE_BUCKET,
        region: STORAGE_REGION,
        name: getFileNameWithoutExtension(f.name) + '.flac',
        key: getFileNameWithoutExtension(f.key) + '.flac',
      },
    }))
    await callApi(createSpireProjectFromStems, {
      input: {
        stemFiles,
        id: collectionId,
        projectAttrs: {
          name: title || name,
          tempo,
          timeSignature: TIME_SIGNATURE_LOOKUP[timeSignature],
          projectId,
        },
      },
    })
  } catch (e) {
    console.error('Failed to create spire project:', e)
    throw e
  }
}
