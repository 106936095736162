import React from 'react'
import Heading from 'components/base/Heading'
import './Input.scss'

export default function Input({
  id = null,
  className = null,
  type = 'text',
  label,
  value = '',
  onChange,
  error = false,
  validated = false,
  style,
  rows = 3,
  ...props
}) {
  return (
    <div
      className={`izo_input${className ? ` ${className}` : ''}${
        !value.length ? ' empty' : ''
      }`}
      style={style}
    >
      {type === 'textarea' ? (
        <textarea
          id={id}
          className={error ? 'error' : validated ? 'validated' : ''}
          value={value}
          onChange={onChange}
          rows={rows}
          {...props}
        />
      ) : (
        <input
          id={id}
          className={`${error ? 'error' : validated ? 'validated' : ''}`}
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
      <label htmlFor={id}>{label}</label>
      {error ? (
        <Heading size={6} className="InputError">
          {error}
        </Heading>
      ) : null}
    </div>
  )
}
