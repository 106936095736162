import {
  DEFAULT_STEREOIZE_DELAY,
  DEFAULT_STEREOIZE_PAN,
  SAMPLE_RATE,
} from '../../utils/constants'

export function initAudioContext() {
  if (window.izo_audio_context) window.izo_audio_context.close()
  window.izo_audio_context = new (window.AudioContext ||
    window.webkitAudioContext)({
    latencyHint: 'playback',
    sampleRate: SAMPLE_RATE,
  })
  window.izo_audio_context.resume()
}

export function getCachedPlayheadPosition() {
  const cachedPlayheadPosition = sessionStorage.getItem(
    'cachedPlayheadPosition'
  )
  if (cachedPlayheadPosition) {
    sessionStorage.removeItem('cachedPlayheadPosition')
  }
  return cachedPlayheadPosition
}

export async function loadClip(clip) {
  const payload = {
    file_name: clip.file_name,
    type: 'clip',
  }
  let arrayBuffer
  // attempt fetching mp3 src, fallback to flac if not available
  payload.key = clip.mp3_file.key
  payload.src = clip.mp3_file.signed_url
  if (payload.src) {
    arrayBuffer = await fetchAudio(payload.src)
  }
  if (!arrayBuffer) {
    payload.key = clip.flac_file.key
    payload.src = clip.flac_file.signed_url
    if (!payload.src) {
      console.error(
        'No valid signed url for audio file:',
        JSON.stringify(payload)
      )
      return { ...payload, failedToLoad: true }
    }
    arrayBuffer = await fetchAudio(payload.src)
  }
  if (!arrayBuffer) {
    console.error(
      'Invalid response after fetching audio from S3:',
      JSON.stringify(payload)
    )
    return { ...payload, failedToLoad: true }
  }
  payload.audioBuffer = await decodeAudio(arrayBuffer)
  return payload
}

export async function loadMixOrStemFile(file) {
  const payload = {
    file_name: file.name,
    enhanced: file.enhanced || false,
    key: file.key,
    type: file.type,
    src: file.signed_url,
  }
  const arrayBuffer = await fetchAudio(payload.src)
  payload.audioBuffer = await decodeAudio(arrayBuffer, file.name, payload.src)
  return payload
}

export async function fetchAudio(src) {
  try {
    const res = await fetch(src)
    if (!res.ok) {
      console.error(`Error fetching audio file ${src}:`, res)
      return null
    }
    return res.arrayBuffer()
  } catch (e) {
    console.error(e + `, in fetchAudio()`)
    return null
  }
}

export async function decodeAudio(arrayBuffer) {
  return await new Promise(res => {
    return window.izo_audio_context.decodeAudioData(
      arrayBuffer,
      buf => res(buf),
      e => {
        throw new Error(e + `, in decodeAudio()`)
      }
    )
  })
}

export function stereoizeBuffer(buffer) {
  // NOTES: This process is not run as a real time process
  const DELAY_FRAMES =
    DEFAULT_STEREOIZE_DELAY * window.izo_audio_context.sampleRate
  const originalDataLeft = buffer.getChannelData(0)
  const originalDataRight =
    buffer.numberOfChannels === 2 ? buffer.getChannelData(1) : originalDataLeft
  const delayedData = new Float32Array(DELAY_FRAMES + originalDataLeft.length)
  for (let i = 0; i < originalDataLeft.length; i++) {
    delayedData[i + DELAY_FRAMES] = originalDataLeft[i] * DEFAULT_STEREOIZE_PAN
  }
  const combinedDataLeft = new Float32Array(delayedData.length)
  const combinedDataRight = new Float32Array(delayedData.length)
  for (let j = 0; j < combinedDataLeft.length; j++) {
    const originalFrameLeft =
      j < originalDataLeft.length ? originalDataLeft[j] : 0
    const originalFrameRight =
      j < originalDataRight.length ? originalDataRight[j] : 0
    combinedDataLeft[j] = originalFrameLeft + delayedData[j]
    combinedDataRight[j] = originalFrameRight - delayedData[j]
  }
  const stereoizedBuffer = window.izo_audio_context.createBuffer(
    2,
    delayedData.length,
    window.izo_audio_context.sampleRate
  )
  stereoizedBuffer.copyToChannel(combinedDataLeft, 0, 0)
  stereoizedBuffer.copyToChannel(combinedDataRight, 1, 0)
  return stereoizedBuffer
}
