import React, { useState, useEffect, Suspense, lazy } from 'react'
import BeatToSpire from 'components/shared/BeatToSpire'
import SpireLabs from 'components/public/SpireLabs'
import Loading from 'components/base/Loading'
import EditUserProfile from 'components/shared/EditUserProfile'
import EditCollection from 'components/public/EditCollection'
import PublicUserProfile from 'components/shared/PublicUserProfile'
import CreateSpireFile from 'components/public/CreateSpireFile'
import SpirePro from 'components/public/SpirePro'
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from 'redux/app/appReducer'
import { log } from 'redux/analytics/analyticsReducer'
import RepairCompare from 'components/shared/RepairCompare/RepairCompare'
import Button from '../Button'
import MissingAudio from 'components/public/MissingAudio'
import './Modal.scss'

const Modal = () => {
  const modal = useSelector(store => store.app.modal)
  const modalState = useSelector(store => store.app.modalState)
  const dispatch = useDispatch()
  const [style, setStyle] = useState({})
  useEffect(() => updateStyle(), [modal]) // eslint-disable-line react-hooks/exhaustive-deps
  const updateStyle = () => {
    switch (modal) {
      case 'AdminPreviews':
        setStyle({ maxWidth: '92%' })
        break
      case 'SpireLabs':
        setStyle({ maxWidth: '600px' })
        break
      case 'SpirePro':
        setStyle({ padding: 0 })
        break
      case 'BeatToSpire':
        setStyle({ maxWidth: '600px' })
        break
      case 'PublicUserProfile':
        setStyle({ maxWidth: '400px' })
        break
      default:
        setStyle({})
    }
  }
  const close = () => {
    document.getElementById('ModalBackground').className = 'closing'
    document.getElementById('Modal').className = 'closing'
    window.setTimeout(() => dispatch(setModal(false)), 320)
    dispatch(log({ event: 'modalClose' }))
  }
  const checkForClose = e => {
    if (e.target.id === 'ModalBackground') {
      close(e)
    }
  }

  let CollectionEditor, AdminPreviews, AdminFiles, AdminEditUser, AdminComments
  if (process.env.REACT_APP_ADMIN === 'true') {
    CollectionEditor = lazy(() => import('../../admin/CollectionEditor'))
    AdminPreviews = lazy(() => import('../../admin/AdminPreviews'))
    AdminFiles = lazy(() => import('../../admin/AdminFiles'))
    AdminEditUser = lazy(() => import('../../admin/AdminEditUser'))
    AdminComments = lazy(() => import('../../admin/AdminComments'))
  }

  const renderModal = () => {
    switch (modal) {
      case 'SpireLabs':
        return <SpireLabs content={modalState ? modalState : null} />
      case 'SpirePro':
        return <SpirePro />
      case 'CreateSpireFile':
        return <CreateSpireFile close={close} />
      case 'MissingAudio':
        return <MissingAudio close={close} />
      case 'BeatToSpire':
        return (
          <BeatToSpire
            close={close}
            initFiles={modalState ? modalState.files : null}
          />
        )
      case 'EditUserProfile':
        return <EditUserProfile close={close} />
      case 'PublicUserProfile':
        return <PublicUserProfile publicUser={modalState.user} close={close} />
      case 'RepairCompare':
        return (
          <RepairCompare
            clip={modalState ? modalState.clip : null}
            close={close}
          />
        )
      case 'EditCollection':
        return (
          <EditCollection
            title={modalState.title}
            description={modalState.description}
            close={close}
          />
        )
      case 'AdminEditUser':
        return (
          <AdminEditUser
            close={close}
            profile={modalState ? modalState.profile : null}
          />
        )
      case 'AdminPreviews':
        return AdminPreviews ? <AdminPreviews /> : null
      case 'AdminEdit':
        return CollectionEditor ? <CollectionEditor action="update" /> : null
      case 'AdminCreate':
        return CollectionEditor ? <CollectionEditor action="create" /> : null
      case 'AdminFiles':
        return AdminFiles ? <AdminFiles /> : null
      case 'AdminComments':
        return AdminComments ? <AdminComments /> : null
      default:
        return null
    }
  }
  return modal ? (
    <div id="ModalBackground" onClick={checkForClose}>
      <div id="Modal" style={style}>
        <Button type="close" onClick={close} aria-label="Close Popup Menu" />
        <Suspense fallback={<Loading />}>{renderModal()}</Suspense>
      </div>
    </div>
  ) : null
}
export default Modal
