import React, { useEffect, useState } from 'react'
import { createSpireProject } from './utils'
import Ellipsis from 'components/base/Ellipsis'
import { useDispatch, useSelector } from 'react-redux'
import AdminAudioViews from 'components/admin/AdminAudioViews'
import Loading from 'components/base/Loading'
import { setSource } from 'redux/audio/audioReducer'
import Heading from 'components/base/Heading'
import './AdminStems.scss'

const AdminStems = () => {
  const stemFiles = useSelector(store => store.collection.stemFiles)
  const spireFile = useSelector(store => store.collection.spireFile)
  const title = useSelector(store => store.collection.title)
  const collectionId = useSelector(store => store.collection.collectionId)
  const [creatingProject, setCreatingProject] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSource('stemFiles'))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleCreateSpire() {
    setCreatingProject(true)
    createSpireProject(collectionId, title, stemFiles)
  }

  return !stemFiles ? (
    <div id="AdminStems">
      <Loading />
    </div>
  ) : !stemFiles.length ? (
    <div id="AdminStems">
      <Heading size={4}>Project has no stem files.</Heading>
    </div>
  ) : (
    <div id="AdminStems">
      {spireFile === false && stemFiles && stemFiles.length ? (
        <div>
          <button onClick={handleCreateSpire}>
            {creatingProject
              ? '⏳ Creating Spire File'
              : 'Create Spire File from Stems'}
            {creatingProject && <Ellipsis />}
          </button>
        </div>
      ) : null}
      <AdminAudioViews style={{ height: `calc(100% - 96px)` }} />
    </div>
  )
}

export default AdminStems
