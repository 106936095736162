import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { Credentials } from '@aws-amplify/core'
import AuthReducer from './AuthReducer'
import { COGNITO_IDENTITY_POOL_ID } from '../../utils/constants'
import { getIconSignedUrl } from 'utils/common'
import { useDispatch } from 'react-redux'
import {
  updateApiAuthMode,
  updateUserId,
  updateUserProfile,
} from 'redux/auth/authReducer'
import { log } from 'redux/analytics/analyticsReducer'
import {
  callApi,
  getPublicUserProfileById,
  createUserProfile,
} from 'graphql/izo_api'

const CACHED_IDENTITY_ID_KEY = `CognitoIdentityId-${COGNITO_IDENTITY_POOL_ID}`
const EXCLUDED_ANALYTICS_FIELDS = ['email', 'family_name', 'given_name', 'name']
// TODO investigate if `isAuthenticated`, `isAuthenticating`, and
// `oAuthFinished` are ALL necessary
const initialAuthData = {
  apiAuthMode: null,
  isAuthenticated: null, // Check for browser session
  isError: false,
  isAuthenticating: true,
  oAuthFinished: false,
  user: null,
}
let creatingProfile = false

const AuthDataContext = createContext(initialAuthData)
AuthDataContext.displayName = 'AuthData'
const useAuth = () => useContext(AuthDataContext)

function AuthDataProvider(props) {
  const [state, control] = useReducer(AuthReducer, initialAuthData)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchCurrentUser()
    HubListener()
    return () => Hub.remove('auth')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // placeholder to access auth in redux until we migrate fully
    dispatch(updateApiAuthMode(state.apiAuthMode))
  }, [state.apiAuthMode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // placeholder to access auth in redux until we migrate fully
    state.user && state.user.sub && dispatch(updateUserId(state.user.sub))
  }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        if (state.user && state.user.sub) {
          const res = await callApi(getPublicUserProfileById, {
            userId: state.user.sub,
          })
          const payload = res.data.getPublicUserProfileById
          if (payload) {
            payload.displayIcon = await getIconSignedUrl(payload.displayIcon)
            control({ type: 'USER_PROFILE_SUCCESS', profile: payload })
            dispatch(updateUserProfile(payload))
          } else if (!creatingProfile && state.user.name) {
            creatingProfile = true
            handleCreateUserProfile()
          }
        }
      } catch (e) {
        console.error('Error while fetching user profile:', e)
      }
    }
    fetchUserProfile()
  }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

  const signIn = async provider => {
    try {
      control({ type: 'AUTH_DATA_INIT' })
      // CLOUD-431: similar to ios/android codebase, clear unauthenticated identity id
      await Credentials.clear()
      window.localStorage.removeItem(CACHED_IDENTITY_ID_KEY)

      // on success, window.location will change due to redirects
      await Auth.federatedSignIn({ provider })
      // any state changes below this line will be cleared out by redirects
    } catch (e) {
      console.error(`Error signing in ${e}`)
    }
  }

  // Listing all the Auth events because they are poorly documented
  const onAuthEvent = async payload => {
    // NOTE: control payloads should always contain data inside nested objects
    // EXAMPLE: `control({ type: 'AUTH_DATA_FAILURE', payload: { user: payload.user }, })`
    switch (payload.event) {
      case 'configured':
        break
      case 'signIn': // use this state when NOT using customState in Federated Sign In
        break
      case 'signOut':
        // accessToken & idToken payload unavailable at this point
        break
      case 'signUp':
        break
      case 'signIn_Failure':
        control({
          type: 'AUTH_DATA_FAILURE',
          payload: { user: payload.user },
        })
        const params = {}
        // Populate user object with NON-PII fields
        for (const key in payload.user) {
          if (!EXCLUDED_ANALYTICS_FIELDS.includes(key)) {
            params[`authUser_${key}`] =
              typeof payload.user[key] === 'string'
                ? payload.user[key]
                : JSON.stringify(payload.user[key])
          }
        }
        dispatch(log({ event: 'authLoginFail', params }))
        break
      case 'customOAuthState': // use this state when using customState in Federated Sign In
        break
      case 'cognitoHostedUI':
        fetchCurrentUser()
        break
      case 'oAuthSignOut':
        // payload.data will only contain `oAuth: 'signOut'`
        break
      default:
        return
    }
  }

  const HubListener = () =>
    Hub.listen('auth', data => {
      const { payload } = data
      onAuthEvent(payload)
    })

  async function authSessionExists() {
    try {
      return await Auth.currentSession()
    } catch (e) {
      return false
    }
  }

  async function handleCreateUserProfile() {
    try {
      const res = await callApi(createUserProfile, {
        input: {
          userId: state.user.sub,
          displayName: state.user.name,
        },
      })
      const payload = res.data.createUserProfile
      payload.displayIcon = await getIconSignedUrl(payload.displayIcon)
      control({
        type: 'USER_PROFILE_SUCCESS',
        profile: payload,
      })
      dispatch(updateUserProfile(payload))
      dispatch(log({ event: 'createUserProfile' }))
      creatingProfile = false
    } catch (e) {
      console.error('Error while auto creating user profile:', e)
    }
  }

  // Associate IdentityId with Cognito User Pool Record
  // only update custom attribute identity Id if empty
  // Other cloud resources would need to be changed if the custom attribute
  // was updated on the cognito user group record
  async function didUpdateIdentityIdOnUserPool(
    clientIdentityId, // required
    user
  ) {
    const { payload: idTokenPayload } = user.signInUserSession.idToken
    const userPoolIdentityId = idTokenPayload['custom:IdentityId'] || ''
    if (clientIdentityId === userPoolIdentityId) return false

    if (userPoolIdentityId === '') {
      await Auth.updateUserAttributes(user, {
        'custom:IdentityId': clientIdentityId,
      })

      // Populate user object with NON-PII fields
      const params = {}
      for (const key in idTokenPayload) {
        if (!EXCLUDED_ANALYTICS_FIELDS.includes(key)) {
          params[`authUser_${key}`] =
            typeof idTokenPayload[key] === 'string'
              ? idTokenPayload[key]
              : JSON.stringify(idTokenPayload[key])
        }
      }
      dispatch(
        log({
          event: 'authWriteCustomIdentityId',
          params: { ...params, clientIdentityId, userPoolIdentityId },
        })
      )

      return true
    }

    if (userPoolIdentityId !== '' && clientIdentityId !== userPoolIdentityId) {
      // Populate user object with NON-PII fields
      const params = {}
      for (const key in idTokenPayload) {
        if (!EXCLUDED_ANALYTICS_FIELDS.includes(key)) {
          params[`authUser_${key}`] =
            typeof idTokenPayload[key] === 'string'
              ? idTokenPayload[key]
              : JSON.stringify(idTokenPayload[key])
        }
      }
      dispatch(
        log({
          event: 'authIdentityIdMismatch',
          params: { ...params, clientIdentityId, userPoolIdentityId },
        })
      )
    }

    return false
  }

  // Get User And Set Analytics
  // only idToken will have custom attributes available in the payload
  async function fetchCurrentUser() {
    try {
      const existingSession = await authSessionExists()
      // CLOUD-431: guard against running any auth code without a session
      if (!existingSession) {
        control({ type: 'AUTH_DATA_RETRIEVE_FAILURE' })
        return
      }

      const user = await Auth.currentAuthenticatedUser()
      const { payload: idTokenPayload } = user.signInUserSession.idToken
      const userPoolIdentityId = idTokenPayload['custom:IdentityId'] || ''
      const { identityId: clientIdentityId } = await Auth.currentCredentials()
      // append identity information to auth user object

      idTokenPayload.clientIdentityId = clientIdentityId
      idTokenPayload.userPoolIdentityId = userPoolIdentityId

      const userUpdated = await didUpdateIdentityIdOnUserPool(
        clientIdentityId,
        user
      )

      if (userUpdated) {
        // session must be refreshed so JWT contains new attributes
        await user.refreshSession(
          existingSession.getRefreshToken(),
          async (err, _) => {
            if (err) throw err
          }
        )
      }

      control({
        type: 'AUTH_DATA_SUCCESS',
        payload: { user: idTokenPayload },
      })

      const params = {}
      for (const key in idTokenPayload) {
        if (!EXCLUDED_ANALYTICS_FIELDS.includes(key)) {
          params[`authUser_${key}`] =
            typeof idTokenPayload[key] === 'string'
              ? idTokenPayload[key]
              : JSON.stringify(idTokenPayload[key])
        }
      }
      dispatch(log({ event: 'authLoginSuccess', params }))
    } catch (e) {
      console.error(e)
    }
  }

  /* handleSignOut
   *
   * order of events matters here to avoid a null idToken
   * - ['datalayer push', 'control', 'Auth.signOut']
   */
  const handleSignOut = async () => {
    try {
      const { idToken: signOutIdToken } = await Auth.currentSession()
      control({
        type: 'RESET_AUTH_DATA',
      })

      const params = {}
      for (const key in signOutIdToken.payload) {
        if (!EXCLUDED_ANALYTICS_FIELDS.includes(key)) {
          params[`authUser_${key}`] =
            typeof signOutIdToken.payload[key] === 'string'
              ? signOutIdToken.payload[key]
              : JSON.stringify(signOutIdToken.payload[key])
        }
      }
      dispatch(log({ event: 'authLogoutSuccess', params }))
      // on success, window.location will change due to redirects
      // CLOUD-431: similar to ios/android codebase, clear unauthenticated identity id
      await Credentials.clear()
      window.localStorage.removeItem(CACHED_IDENTITY_ID_KEY)

      await Auth.signOut()
      // any state changes below this line will be cleared out by redirects
    } catch (e) {
      console.error(`Error signing out ${e}`)
    }
  }

  async function updateProfile(profile) {
    profile.displayIcon = await getIconSignedUrl(profile.displayIcon)
    control({ type: 'USER_PROFILE_SUCCESS', profile })
    dispatch(updateUserProfile(profile))
  }

  // return Provider component and expose callable functions
  return (
    <AuthDataContext.Provider
      value={{
        ...state,
        signIn,
        handleSignOut,
        updateProfile,
      }}
      {...props}
    />
  )
}

export { AuthDataProvider as default, useAuth }
