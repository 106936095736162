export function sortByTimestamp(comments) {
  const copy = comments.slice()
  return copy.sort((a, b) => {
    if (a.timestamp < b.timestamp) {
      return -1
    }
    if (a.timestamp > b.timestamp) {
      return 1
    }
    return 0
  })
}

export function shouldOpenComment(elapsed, prevPlayhead, note) {
  return (
    elapsed > note.timestamp &&
    prevPlayhead < note.timestamp &&
    Math.abs(elapsed - note.timestamp) < 1
  )
}

export function checkForExistingReaction(emoji, user, comment) {
  return comment.reactions.find(
    r => user && r.user && r.user.userId === user.sub && r.emoji === emoji
  )
}

export function getReactionUsers(comment, emoji) {
  return comment.reactions
    .filter(r => r.emoji === emoji)
    .map(r => r.user.displayName)
}

export function removeDuplicateReactions(reactions) {
  const unique = []
  for (const r of reactions) {
    if (!unique.find(d => d.emoji === r.emoji)) {
      unique.push(r)
    }
  }
  return unique
}
