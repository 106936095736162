import React, { useEffect } from 'react'
import { log } from 'redux/analytics/analyticsReducer'
import {
  AppleSignInBtn,
  GoogleSignInBtn,
  FacebookSignInBtn,
} from 'components/shared/Login'
import SpireIcon from 'assets/spire-bg.png'
import {
  FEATURE_FLAG_APPLE_LOGIN,
  FEATURE_FLAG_FACEBOOK_LOGIN,
} from 'utils/constants'
import './SpireLabs.scss'
import { useDispatch, useSelector } from 'react-redux'
import Heading from 'components/base/Heading'

const defaultContent = {
  title: 'Sound Even Better',
  body:
    'By signing in, Spire can securely sync and enhance your audio, and enable additional collaborative features.',
}

const SpireLabs = ({ content }) => {
  const dispatch = useDispatch()
  const mobile = useSelector(store => store.app.mobile)

  useEffect(() => {
    dispatch(log({ event: 'loginModalLoad' }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  content = content || defaultContent

  return (
    <div id="SpireLabs">
      <img src={SpireIcon} alt="SpireLabsLogo" />
      <br />
      <Heading size={mobile ? 4 : 3} wrap align="center">
        {content.title || defaultContent.title}
      </Heading>
      <br />
      <p className="iz-text-color-secondary iz-layout-margin-bottom">
        {content.body || defaultContent.body}
      </p>
      <br />
      <GoogleSignInBtn />
      {FEATURE_FLAG_FACEBOOK_LOGIN ? <FacebookSignInBtn /> : null}
      {FEATURE_FLAG_APPLE_LOGIN ? <AppleSignInBtn /> : null}
    </div>
  )
}

export { SpireLabs as default }
