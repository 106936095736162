import React from 'react'
import { useDispatch } from 'react-redux'
import Dropzone from 'components/base/Dropzone'
import { setAppState } from 'redux/app/appReducer'
import Heading from 'components/base/Heading'
import './LandingNewCollection.scss'

export default function LandingNewCollection() {
  const dispatch = useDispatch()

  function handleFiles(files) {
    dispatch(setAppState({ modal: 'BeatToSpire', modalState: { files } }))
  }

  return (
    <div id="LandingNewCollection" className="Tile">
      <Heading size={4}>Beat to Spire!</Heading>
      <p>Create a collection from a stem fast and easy</p>
      <div id="DropzoneContainer">
        <Dropzone
          handler={handleFiles}
          header="Drag and Drop your Track"
          body="or Select a File to Upload"
          accept=".wav, .mp3, .flac"
        />
      </div>
    </div>
  )
}
