import React, { useEffect } from 'react'
import AdminCollectionHeader from '../AdminCollectionHeader'
import Notifications from 'components/shared/Notifications'
import AdminTabs from '../AdminTabs'
import AdminClips from '../AdminClips'
import AdminSong from '../AdminSong'
import AdminStems from '../AdminStems'
import Heading from 'components/base/Heading'
import { useDispatch, useSelector } from 'react-redux'
import {
  init as initAudio,
  loadMixAudio,
  loadProjectAudio,
  loadStemAudio,
} from 'redux/audio/audioReducer'
import { init as initCollection } from 'redux/collection/collectionReducer'
import { useAuth } from 'providers/AuthProvider'
import { setView } from 'redux/app/appReducer'
import { useLocation } from 'react-router'

function AdminCollection({ collectionId }) {
  const spireProject = useSelector(store => store.collection.spireProject)
  const deleted = useSelector(store => store.collection.deleted)
  const mixFiles = useSelector(store => store.collection.mixFiles)
  const stemFiles = useSelector(store => store.collection.stemFiles)
  const view = useSelector(store => store.app.view)
  const dispatch = useDispatch()
  const location = useLocation()
  const { isAuthenticating } = useAuth()

  useEffect(() => {
    dispatch(initAudio())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSearchParams(location.search)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAuthenticating && collectionId) {
      dispatch(initCollection({ collectionId }))
    }
  }, [collectionId, isAuthenticating]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (spireProject) {
      dispatch(loadProjectAudio())
    }
  }, [spireProject]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mixFiles) {
      dispatch(loadMixAudio())
    }
  }, [mixFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (stemFiles) {
      dispatch(loadStemAudio())
    }
  }, [stemFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchParams = search => {
    const params = new URLSearchParams(search)
    const tab = params.get('tab')
    tab && dispatch(setView(tab))
  }

  const renderTab = () => {
    switch (view) {
      case 'song':
        return <AdminSong />
      case 'tracks':
        return <AdminStems />
      case 'clips':
        return <AdminClips />
      default:
        return null
    }
  }

  return (
    <>
      <Notifications />
      <AdminCollectionHeader />
      {deleted ? (
        <>
          <br />
          <br />
          <Heading size={4} align="center" fullWidth>
            Sorry fam you all outta luck, this project big gone :/
          </Heading>
        </>
      ) : (
        <>
          <AdminTabs />
          <div id="TabContainer">{renderTab()}</div>
        </>
      )}
    </>
  )
}

export default AdminCollection
