import React from 'react'
import './Loading.scss'
import Heading from '../Heading'
import Ellipsis from '../Ellipsis'
import Lottie from 'react-lottie'
import LoadingAnimation from 'assets/loading_instruments.json'

export default function Loading({ style = {}, text, relative, noDelay }) {
  const minDimension = Math.min(window.innerWidth, window.innerHeight)
  const animationSize = Math.min(Math.floor(minDimension * 0.5), 200)
  return (
    <div
      id="Loading"
      style={style}
      className={`${relative ? ' relative' : ''}${noDelay ? ' noDelay' : ''}`}
    >
      <div className="AnimationWrapper">
        <Lottie
          options={LoadingAnimation}
          height={animationSize}
          width={animationSize}
        />
      </div>
      {text ? (
        <Heading size={3}>
          {text}
          <Ellipsis />
        </Heading>
      ) : null}
    </div>
  )
}
