export * from './subscriptions'

export const subscribeToCollectionUpdates = `subscription subscribeToCollectionUpdates($id: ID!) {
  subscribeToCollectionUpdates(id: $id) {
    userId
    projectId
    identityId
    createdAt
    updatedAt
    suppressExpiration
    expirationEpoch
    title
    origin
    themeColor
    description
    visibility
    preventComments
    processing {
      items {
        id
        opInfo
      }
    }
    spireProject {
      id
      project_file_version
      duration
      dirty
      tempo
      modified
      time_signature{
        beats
        divisions
      }
      tracks {
        id
        gain
        mute
        pan
        stereoize
        color_index
        clips {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          instrumentID
          track_id
          pan
          gain
          flac_key
          mp3_key
        }
      }
      metronome_on
      created
      num_tracks
      name
    }
    spireFile {
      key
      name
      size
      signed_url
      last_modified
    }
    mixFiles {
      name
      key
      enhanced
      type
      size
    }
    stemFiles {
      name
      key
      type
      size
    }
  }
}
`;

export const subscribeToProjectComments = `subscription subscribeToProjectComments($projectId: ID!) {
  subscribeToProjectComments(projectId: $projectId) {
    projectId
    comments {
      commentId
      projectId
      collectionId
      user {
        userId
        displayName
        displayIcon
        bio
        city
        country
        links
      }
      updatedAt
      timestamp
      comment
      reactions {
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          links
        }
        emoji
        updatedAt
      }
    }
  }
}
`;

export const subscribeToProjectChanged = `
  subscription SubscribeToProjectChanged($userId: ID!) {
    subscribeToProjectChanged(userId: $userId) {
      projectId
      userId
    }
  }
`

export const subscribeToModifiedProject = `
  subscription SubscribeToModifiedProject($id: ID!) {
    modifiedProject(id: $id) {
      id
      clientId
      userId
      status
    }
  }
`

export const subscribeToProcessUpdates = /* GraphQL */ `
  subscription SubscribeToProcessUpdates($id: ID!) {
    subscribeToProcessUpdates(id: $id) {
      process {
        id
        opInfo
      }
    }
  }
`

export const subscribeToProcessUpdatesWithTag = /* GraphQL */ `
  subscription SubscribeToProcessUpdatesWithTag($tag: ID!) {
    subscribeToProcessUpdatesWithTag(tag: $tag) {
      process {
        id
        opInfo
      }
    }
  }
`