import { createSlice } from '@reduxjs/toolkit'
import { v4 as generateUUID } from 'uuid'

const collection = createSlice({
  name: 'collection',
  initialState: {
    syncClientId: `web_app_${generateUUID()}`,
  },
  reducers: {
    init(state, action) {
      return { ...state, ...action.payload }
    },
    fetch(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateComments(state, action) {
      return { ...state, comments: action.payload }
    },
    subscriptionUpdate(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    createCollection(state, action) {
      return { ...state, ...action.payload }
    },
    sync(state, action) {
      return { ...state, ...action.payload }
    },
    setSpireProject(state, action) {
      return { ...state, spireProject: action.payload }
    },
    toggleTrackMute(state, action) {
      return { ...state, ...action.payload }
    },
    updateCollection(state, action) {
      return { ...state, ...action.payload }
    },
    bounceProject(state, action) {
      return { ...state, ...action.payload }
    },
    reset() {
      return {}
    },
  },
})

export const {
  init,
  fetch,
  updateComments,
  updateOwner,
  subscriptionUpdate,
  createCollection,
  sync,
  toggleTrackMute,
  setSpireProject,
  updateCollection,
  bounceProject,
} = collection.actions

export default collection.reducer
