import Button from 'components/base/Button'
import ExternalLink from 'components/base/ExternalLink'
import React, { useEffect } from 'react'
import { isDeviceSupportedBySpire } from 'utils/common'
import Heading from 'components/base/Heading'
import './MissingAudio.scss'
import { useDispatch } from 'react-redux'
import { log } from 'redux/analytics/analyticsReducer'

export default function MissingAudio() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(log({ event: 'missingAudioModal' }))
  }, []) //eslint-disable-line

  const supportUrl = 'https://support.izotope.com/hc/en-us/requests/new'
  const spireAppMobileUrl = 'https://spirestudio.app.link'
  const spireAppDesktopUrl =
    'https://www.izotope.com/en/products/spire-studio/spire-ios-app.html'

  const SupportButton = ({ text = 'Need Help?' }) => (
    <ExternalLink to={supportUrl}>
      <Button
        type="primary"
        center
        onClick={() => dispatch(log({ event: 'missingAudioSupport' }))}
      >
        {text}
      </Button>
    </ExternalLink>
  )

  const OpenAppButton = ({ text = 'Open Spire App' }) => (
    <ExternalLink
      to={isDeviceSupportedBySpire() ? spireAppMobileUrl : spireAppDesktopUrl}
    >
      <Button
        type="primary"
        center
        onClick={() => dispatch(log({ event: 'missingAudioOpenApp' }))}
      >
        {text}
      </Button>
    </ExternalLink>
  )

  return (
    <div id="MissingAudio">
      <Heading size={1} fullWidth align="center">
        😢
      </Heading>
      <br />
      <Heading size={4} fullWidth align="center">
        Uh oh...
      </Heading>
      <br />
      <p>It looks like some of your audio wasn't synced from the Spire App.</p>
      <p>Open the app to finish syncing your project to the cloud.</p>
      <br />
      <div id="ButtonContainer">
        <OpenAppButton />
        <SupportButton />
      </div>
    </div>
  )
}
