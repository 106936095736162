import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { initializeSentry, setViewportHeight } from './utils/common'
import './utils/configure'
import './styles/index.scss'

initializeSentry()
setViewportHeight()
window.addEventListener('resize', setViewportHeight)
window.izo_page_load_start = performance.now()

const Routes =
  process.env.REACT_APP_ADMIN === 'true'
    ? require('./routes/routes-admin').Routes
    : require('./routes/routes').Routes

ReactDOM.render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  document.getElementById('Spire')
)
