const events = {
  // Auth Events
  authLoginFail: {
    event: 'AuthEvent',
    action: 'Login Failure',
    status: 'Unable to successfully authenticate user',
  },
  authWriteCustomIdentityId: {
    event: 'AuthEvent',
    action: 'Write Custom Attribute IdentityId',
    status: 'Updated user custom attributes to include identityId',
  },
  authIdentityIdMismatch: {
    event: 'AuthEvent',
    action: 'IdentityId Mismatch',
    status: 'User custom identity id has a detected mismatch',
  },
  authLoginSuccess: {
    event: 'AuthEvent',
    action: 'Login Success',
    status: 'Authenticated user was successfully logged in',
  },
  authLogoutSuccess: {
    event: 'AuthEvent',
    action: 'Logout Success',
    status: 'Authenticated user was successfully logged out',
  },
  createUserProfile: {
    event: 'AuthEvent',
    action: 'User Profile Created',
    status: 'User without profile logged in, triggering auto creation',
  },
  updateUserProfile: {
    event: 'AuthEvent',
    action: 'User Profile Updated',
    status: 'User used modal to update profile settings',
  },

  // Feedback Interactions
  openNewComment: {
    event: 'FeedbackInteraction',
    action: 'Open Add Comment',
    status: 'Authenticated user clicked Add New Comment',
  },
  closeNewComment: {
    event: 'FeedbackInteraction',
    action: 'Close Add Comment',
    status: 'Authenticated user closed Add New Comment',
  },
  openTimelineComment: {
    event: 'FeedbackInteraction',
    action: 'Open Timeline Comment',
    status: 'User clicked to open comment on audio timeline',
  },
  closeTimelineComment: {
    event: 'FeedbackInteraction',
    action: 'Close Timeline Comment',
    status: 'User clicked to close comment on audio timeline',
  },
  createCommentAttempt: {
    event: 'FeedbackInteraction',
    action: 'Create Comment Attempt',
    status: 'Unauthenticated user redirected to signin',
  },
  createCommentSuccess: {
    event: 'FeedbackInteraction',
    action: 'Create Comment Success',
    status: 'Authenticated user created comment',
  },
  createCommentFailure: {
    event: 'FeedbackInteraction',
    action: 'Create Comment Failure',
    status: 'Encountered error while adding comment',
  },
  deleteCommentSuccess: {
    event: 'FeedbackInteraction',
    action: 'Delete Comment Success',
    status: 'Authenticated user deleted comment',
  },
  deleteCommentFailure: {
    event: 'FeedbackInteraction',
    action: 'Add Comment Failure',
    status: 'Encountered error while adding comment',
  },
  createReactionAttempt: {
    event: 'FeedbackInteraction',
    status: 'Create Reaction Attempt',
    action: 'Unauthenticated user redirected to signin',
  },
  createReactionSuccess: {
    event: 'FeedbackInteraction',
    status: 'Create Reaction Success',
    action: 'Authenticated user created reaction emoji',
  },
  createReactionFailure: {
    event: 'FeedbackInteraction',
    status: 'Create Reaction Failure',
    action: 'Encountered error while creating reaction emoji',
  },
  deleteReactionSuccess: {
    event: 'FeedbackInteraction',
    status: 'Delete Reaction Success',
    action: 'Authenticated user deleted reaction emoji',
  },
  deleteReactionFailure: {
    event: 'FeedbackInteraction',
    status: 'Delete Reaction Failure',
    action: 'Encountered error while deleting reaction emoji',
  },

  // Beat to Spire/Create Project Interactions
  uploadFileStart: {
    event: 'CreateProjectInteraction',
    action: 'File Upload Started',
    status: 'User dragged and dropped file using Beat to Spire',
  },
  uploadFileSuccess: {
    event: 'CreateProjectInteraction',
    action: 'File Upload Success',
    status: 'User successfully upload file using Beat to Spire',
  },
  uploadFileFailure: {
    event: 'CreateProjectInteraction',
    action: 'File Upload Failure',
    status: 'Encountered error while uploading file',
  },

  // Audio Interactions
  toggleEnhance: {
    event: 'AudioInteraction',
    action: 'Enhance Button Toggled',
    status: 'User clicked the enhance button in Audio Dock',
  },
  togglePlaying: {
    event: 'AudioInteraction',
    action: 'Toggle Playback',
    status: 'User clicked play/pause button in Audio Dock',
  },
  scrub: {
    event: 'AudioInteraction',
    action: 'Playhead Scrub',
    status: 'User moved audio elapsed',
  },
  toggleSongView: {
    event: 'AudioInteraction',
    action: 'Switch to Song View',
    status: 'User switched audio views',
  },
  toggleTracksView: {
    event: 'AudioInteraction',
    action: 'Switch to Tracks View',
    status: 'User switched audio views',
  },
  toggleClipsView: {
    event: 'AudioInteraction',
    action: 'Switch to Clips View',
    status: 'User switched audio views',
  },
  toggleMuteTrack: {
    event: 'AudioInteraction',
    action: 'Toggle Mute Track',
    status: 'User clicked mute button on individual track',
  },
  toggleSoloTrack: {
    event: 'AudioInteraction',
    action: 'Toggle Solo Track',
    status: 'User clicked solo button on individual track',
  },
  toggleMuteClip: {
    event: 'AudioInteraction',
    action: 'Toggle Mute Clip',
    status: 'User clicked mute button on individual clip',
  },
  toggleSoloClip: {
    event: 'AudioInteraction',
    action: 'Toggle Solo Clip',
    status: 'User clicked solo button on individual clip',
  },

  // Auth Interactions
  landingLogin: {
    event: 'AuthInteraction',
    action: 'Landing Login Button',
    status: 'User clicked login button from Landing Page',
  },
  oAuthLoginAttempt: {
    event: 'AuthInteraction',
    action: 'OAuth Login Attempt',
    status: 'User clicked OAuth Sign In button from Login Modal',
  },
  oAuthLogoutAttempt: {
    event: 'AuthInteraction',
    action: 'OAuth Logout Attempt',
    status: 'User clicked Sign Out button',
  },

  // Menu Interactions
  sidebarMenuOpen: {
    event: 'SidebarMenuInteraction',
    action: 'Sidebar Menu Open',
    status: 'User opened sidebar menu',
  },
  sidebarMenuUpdate: {
    event: 'SidebarMenuInteraction',
    action: 'Sidebar Menu Change',
    status: 'State of the sidebar menu was updated',
  },
  sidebarMenuClick: {
    event: 'SidebarMenuInteraction',
    action: 'Sidebar Menu Item Click',
    status: 'User clicked menu item from list in sidebar',
  },
  sidebarMenuBack: {
    event: 'SidebarMenuInteraction',
    action: 'Sidebar Menu Back',
    status: 'User clicked back button to return to main sidebar menu',
  },
  sidebarMenuClose: {
    event: 'SidebarMenuInteraction',
    action: 'Sidebar Menu Close',
    status: 'User closed sidebar menu',
  },
  userMenuLogin: {
    event: 'UserMenuInteraction',
    action: 'Navigate to Login',
    status: 'Unauthenticated user click Login in sidebar menu',
  },
  userMenuShareProjects: {
    event: 'UserMenuInteraction',
    action: 'Navigate to Shared Project List',
    status: 'Authenticated user clicked Shared Projects in sidebar dropdown',
  },
  userMenuCreateProject: {
    event: 'UserMenuInteraction',
    action: 'Create New Project',
    status: 'Authenticated user clicked New Project in sidebar dropdown',
  },
  userMenuSupport: {
    event: 'UserMenuInteraction',
    action: 'Navigate to Support',
    status: 'Authenticated user clicked Support link in sidebar dropdown',
  },

  // User Navigations
  pageLoad: {
    event: 'UserNavigation',
    action: 'Visited Page',
    status: 'User visited a shared project',
  },
  projectLoad: {
    event: 'UserNavigation',
    action: 'Visited Shared Project',
    status: 'User loaded project using ProjectId URL structure',
  },
  collectionLoad: {
    event: 'UserNavigation',
    action: 'Visited Shared Collection',
    status: 'User loaded collection in the web',
  },
  audioLoad: {
    event: 'PageUpdate',
    action: 'Audio Playback Ready',
    status: 'User successfully loaded audio and can now play',
  },
  landingPageLoad: {
    event: 'UserNavigation',
    action: 'Landing Page Load',
    status: 'Unauthenticated user loaded Landing Page',
  },
  noMatchPageLoad: {
    event: 'UserNavigation',
    action: '404 Page Load',
    status: 'User arrived at 404 Page (no collection found)',
  },
  errorPageLoad: {
    event: 'UserNavigation',
    action: 'Error Page Load',
    status: 'User arrived at Error Page (browser javascript crash)',
  },
  sharedProjectsPageLoad: {
    event: 'UserNavigation',
    action: 'Shared Projects Page Load',
    status: 'Authenticated user loaded Shared Projects page',
  },
  missingAudioModal: {
    event: 'UserNavigation',
    action: 'Missing Audio Modal Load',
    status: 'User clicked missing audio button to open modal',
  },
  missingAudioSupport: {
    event: 'UserNavigation',
    action: 'Missing Audio Support Button Clicked',
    status: 'User clicked support button inside missing audio modal',
  },
  missingAudioOpenApp: {
    event: 'UserNavigation',
    action: 'Missing Audio Open App Button Clicked',
    status: 'User clicked open app button inside missing audio modal',
  },
  loginModalLoad: {
    event: 'UserNavigation',
    action: 'Login Modal Load',
    status: 'Unauthenticated user viewed Login modal',
  },
  userProfileModalLoad: {
    event: 'UserNavigation',
    action: 'User Profile Modal Load',
    status: 'Authenticated User viewed User Profile modal',
  },
  createProjectModalLoad: {
    event: 'UserNavigation',
    action: 'Create Project Modal Load',
    status: 'User viewed Create Project modal (Beat to Spire)',
  },
  modalClose: {
    event: 'UserNavigation',
    action: 'Closed Modal',
    status: 'User clicked to exit modal state',
  },
  modalUpdate: {
    event: 'UserNavigation',
    action: 'Modal Change',
    status: 'State of the Modal was updated by user action',
  },
  editSharedProject: {
    event: 'UserNavigation',
    action: 'Edit Shared Project',
    status: 'User clicked on Edit Button in sidebar menu',
  },
  viewPublicUser: {
    event: 'UserNavigation',
    action: 'View Public User',
    status: 'User viewed a public user profile',
  },

  // Action Button Interactions
  actionButtonRecord: {
    event: 'PrimaryAction',
    action: 'Record In Spire',
    status:
      'User clicked Record in Spire action button and will be redirected to Spire app',
  },
  actionButtonDownload: {
    event: 'PrimaryAction',
    action: 'Download Audio',
    status: 'User clicked Download button and opened Download sidebar menu',
  },
  actionButtonShare: {
    event: 'PrimaryAction',
    action: 'Share Project',
    status: 'User clicked Share button and opened Sharing sidebar menu',
  },
  createSpireFileSuccess: {
    event: 'ImportToApp',
    action: 'Private Project Import Success',
    status: 'User dynamically created a spire file and imported to the app',
  },
  createSpireFileError: {
    event: 'ImportToApp',
    action: 'Private Project Import Error',
    status:
      'Error while dynamically creating a spire file to import to the app',
  },
  // Other

  missingClipFiles: {
    event: 'MissingClipFiles',
    action: 'Web Project Missing Clip Audio',
    status:
      'The project was loaded in the web but could not find clip audio in s3',
  },
  generateNewBounces: {
    event: 'UserInteraction',
    action: 'Bounce Mix and Stems',
    status: 'User bounced mix and stems on web project',
  },
  bounceSuccess: {
    event: 'UserInteraction',
    action: 'Bounce Mix and Stems Succeeded',
    status: 'New bounces available while user still in active session',
  },
  bounceFail: {
    event: 'UserInteraction',
    action: 'Bounce Mix and Stems Failed',
    status: 'New bounces not available - check go-spire-canary logs!',
  },
  mixDownload: {
    event: 'UserInteraction',
    action: 'User Downloaded Mix',
    status: 'User used the collection downloads menu to download a mix file',
  },
  stemDownload: {
    event: 'UserInteraction',
    action: 'User Downloaded Stems',
    status:
      'User used the collection downloads menu to download zip of stem files',
  },
  crash: {
    event: 'ErrorOnPage',
    action: 'Javascript Error Caught by ErrorBoundary',
    status:
      'User used the collection downloads menu to download zip of stem files',
  },
  projectUnavailable: {
    event: 'ErrorOnPage',
    action: 'Project Unavailable Page Rendered',
    status: 'User navigated to a project, but was not able to load content.',
  },
}

export default events
