import React, { useState } from 'react'
import { useAuth } from 'providers/AuthProvider'
import Ellipsis from 'components/base/Ellipsis'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from 'redux/app/appReducer'
import SelectTheme from 'components/shared/SelectTheme'
import ProLabel from 'components/base/ProLabel/ProLabel'
import { FEATURE_FLAG_PRO_PAYWALL } from 'utils/constants'
import { applySuppressExpirationLogic } from 'utils/common'
import { callApi, updateCollection } from 'graphql/izo_api'
import Heading from 'components/base/Heading'
import Input from 'components/base/Input'
import './EditCollection.scss'
import Button from 'components/base/Button'

export default function EditCollection({ close }) {
  const dispatch = useDispatch()
  const [working, setWorking] = useState(false)
  const [buttonText, setButtonText] = useState('Update')
  const title = useSelector(store => store.collection.title) || ''
  const id = useSelector(store => store.collection.collectionId) || ''
  const visibility = useSelector(store => store.collection.visibility)
  const description = useSelector(store => store.collection.description) || ''
  const { profile } = useAuth()
  const suppressExpiration =
    useSelector(store => store.collection.suppressExpiration) || false
  const themeColor = useSelector(store => store.collection.themeColor)
  const [newTitle, setNewTitle] = useState(title)
  const [newDescription, setNewDescription] = useState(description)
  const [newThemeColor, setNewThemeColor] = useState(themeColor)
  const [newSuppressExpiration, setNewSuppressExpiration] = useState(
    suppressExpiration
  )

  async function handleSubmit() {
    try {
      setButtonText('Updating')
      setWorking(!working)
      const input = applySuppressExpirationLogic({
        id,
        visibility,
        title: newTitle,
        description: newDescription,
        themeColor: newThemeColor.name,
        suppressExpiration: newSuppressExpiration,
      })
      await callApi(updateCollection, { input })
      setWorking(false)
      setButtonText('✅ Updated!')
      setTimeout(() => close(), 1000)
    } catch (e) {
      console.error('Error while updating shared project:', e)
      setWorking(false)
      setButtonText('❌ Error updating!')
    }
  }

  function handleEditProfile() {
    close()
    setTimeout(() => dispatch(setModal('EditUserProfile')), 320)
  }

  function handleProRedirect() {
    close()
    setTimeout(() => dispatch(setModal('SpirePro')), 320)
  }

  return (
    <div id="EditCollection">
      <Heading size={3}>Edit Shared Project</Heading>
      <br />
      <div id="EditCollectionContent">
        <Input
          label="Project Name"
          value={newTitle}
          onChange={e => setNewTitle(e.target.value)}
          className="EditCollectionTitle"
        />
        <Input
          type="textarea"
          label="Project Description"
          value={newDescription}
          onChange={e => setNewDescription(e.target.value)}
          className="EditCollectionDescription"
        />
        <SelectTheme
          currentColor={newThemeColor}
          onSelect={setNewThemeColor}
          style={{ justifyContent: 'center', alignItems: 'center' }}
          showText
        />
        {FEATURE_FLAG_PRO_PAYWALL ? (
          <div id="Expiration">
            <input
              type="checkbox"
              checked={newSuppressExpiration}
              onChange={() =>
                profile.isProSubscriber
                  ? setNewSuppressExpiration(!newSuppressExpiration)
                  : handleProRedirect()
              }
            />
            <Heading size={6}>Prevent this project from expiring</Heading>
            <ProLabel
              style={{
                position: 'relative',
                transform: 'none',
                marginLeft: 8,
              }}
            />
          </div>
        ) : null}
        <p>
          <i className="fa fa-info-circle" />
          Change how you appear to other users by{' '}
          <span onClick={handleEditProfile}>Updating your Profile</span>
        </p>
      </div>
      <Button type="secondary" className="SubmitButton" onClick={handleSubmit}>
        {buttonText}
        {working && <Ellipsis />}
      </Button>
      <Button type="tertiary" className="CancelButton" onClick={close}>
        cancel
      </Button>
    </div>
  )
}
