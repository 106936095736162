import React from 'react'
import './Button.scss'

export default function Button({
  type = 'primary',
  children,
  icon,
  iconLeft,
  iconRight,
  fullWidth,
  className,
  preventHoverEffects,
  center,
  ...props
}) {
  return (
    <button
      className={`izo_button ${type}${fullWidth ? ' fullWidth' : ''}${
        className ? ` ${className}` : ''
      }${preventHoverEffects ? ' preventHoverEffects' : ''}${
        center ? ' center' : ''
      }`}
      {...props}
    >
      {iconLeft ? (
        iconLeft.startsWith('fa-') ? (
          <i className={`fa ${iconLeft}`} />
        ) : (
          <img src={iconLeft} className="iconLeft" alt="Button Icon Left" />
        )
      ) : null}
      {icon ? (
        icon.startsWith('fa-') ? (
          <i className={`icon fa ${icon}`} />
        ) : (
          <img src={icon} className="icon" alt="Button Icon" />
        )
      ) : (
        <span>
          {type === 'close' ? <i className="fa fa-times" /> : children}
        </span>
      )}
      {iconRight ? (
        iconRight.startsWith('fa-') ? (
          <i className={`fa ${iconRight}`} />
        ) : (
          <img src={iconRight} className="iconRight" alt="Button Icon Right" />
        )
      ) : null}
    </button>
  )
}
