import { parseProviderFromError } from '../utils/common'
import { MOBILE_THRESHOLD } from '../utils/constants'
import { setMenu } from 'redux/app/appReducer'
import store from 'redux/store'

export function checkForAuthProviderRedirect() {
  const query = new URLSearchParams(window.location.search)
  const queryError = query.get('error_description')
  const provider = parseProviderFromError(queryError)
  return provider
}

export function getCollectionRedirect() {
  const redirectURL = sessionStorage.getItem('redirectURL')
  sessionStorage.removeItem('redirectURL')
  return redirectURL
}

export function getCollectionRedirectAdmin() {
  const redirectURL = sessionStorage.getItem('adminRedirectURL')
  sessionStorage.removeItem('adminRedirectURL')
  return redirectURL
}

export function openRecordInSpireOnDesktop() {
  if (window.innerWidth > MOBILE_THRESHOLD) {
    setTimeout(() => {
      store.dispatch(setMenu('RecordInSpire'))
    }, 2400)
  }
}
