import React, { useEffect, useState } from 'react'
import Dropzone from 'components/base/Dropzone'
import { parseTitle, uploadFile, createS3Key } from './utils'
import {
  applySuppressExpirationLogic,
  createNewCollection,
  defaultDateInputValue,
  unauthedBeatToSpireModalState,
} from 'utils/common'
import Ellipsis from 'components/base/Ellipsis'
import { useAuth } from 'providers/AuthProvider'
import { useHistory } from 'react-router'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { log } from 'redux/analytics/analyticsReducer'
import { ADMIN, STORAGE_BUCKET, STORAGE_REGION } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  callApi,
  createUnpackedSpireProject,
  transcodeAudioFile,
} from 'graphql/izo_api'
import './BeatToSpire.scss'
import Heading from 'components/base/Heading'
import Input from 'components/base/Input'
import Button from 'components/base/Button'
import { setAppState } from 'redux/app/appReducer'

const UPLOAD_SIZE_LIMIT = 500
const MB_CONVERSION_CONST = 1048576
const ACCEPTED_UPLOAD_TYPES = [
  'audio/x-wav',
  'audio/wav',
  'audio/mpeg',
  'audio/flac',
]
const DEFAULT_TITLE = 'New Project'

export default function BeatToSpire({ initFiles, close }) {
  const { apiAuthMode, user } = useAuth()
  const mobile = useSelector(store => store.app.mobile)
  const [files, setFiles] = useState([])
  const [fileError, setFileError] = useState()
  const [title, setTitle] = useState(DEFAULT_TITLE)
  const [status, setStatus] = useState()
  const [progress, setProgress] = useState('0%')
  const [chain, setChain] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRepairChain = e => {
    setChain(e.target.value)
  }

  useEffect(() => {
    if (!user) {
      dispatch(
        setAppState({
          modal: 'SpireLabs',
          modalState: unauthedBeatToSpireModalState,
        })
      )
    }
  }, [user]) // eslint-disable-line

  useEffect(() => {
    dispatch(log({ event: 'createProjectModalLoad' }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initFiles && handleFiles(initFiles)
  }, [initFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleFiles(newFiles) {
    newFiles = Array.from(newFiles)
    if (title === DEFAULT_TITLE) setTitle(parseTitle(newFiles[0].name))
    const updated = files
    setFiles([...files, ...newFiles])
    for (const file of newFiles) {
      const parsed = await parseFile(file)
      parsed && updated.push(parsed)
    }
    setFiles(updated)
  }

  async function parseFile(f) {
    return new Promise((res, rej) => {
      if (!ACCEPTED_UPLOAD_TYPES.includes(f.type)) {
        Sentry.captureMessage(`Unsupported File Format Upload - ${f.type}`)
        setFileError('type')
        rej(null)
      }
      const sizeInMB = f.size / MB_CONVERSION_CONST
      if (sizeInMB > UPLOAD_SIZE_LIMIT) {
        setFileError('size')
        rej(null)
      }
      const reader = new FileReader()
      const a = new Audio()
      a.addEventListener('loadedmetadata', () => {
        f.duration = a.duration
        res(f)
      })
      reader.onload = e => {
        a.src = e.target.result
      }
      reader.readAsDataURL(f)
      dispatch(
        log({
          event: 'uploadFileStart',
          params: { fileSize: sizeInMB, fileName: f.name },
        })
      )
    })
  }

  async function handleCreateCollection() {
    let error = false
    let id = null
    let identityId = null
    let projectId = null
    try {
      if (status) return // prevent button jamming
      setStatus('⏳ Creating Your Project')
      setProgress('10%')
      const res = await createNewCollection(
        applySuppressExpirationLogic({
          title,
          description: '',
          expirationDate: defaultDateInputValue(),
          suppressExpiration: false,
          themeColor: 'purple',
          additionalParams: {
            origin: 'web-public',
            visibility: 'privatePath',
          },
        }),
        apiAuthMode
      )
      id = res.data.createCollection.id
      identityId = res.data.createCollection.identityId
      projectId = res.data.createCollection.projectId
      setProgress('30%')
      setStatus(`⏳ Uploading your audio`)
      for (const file of files) {
        const key = createS3Key(id, file.name)
        file.key = key
        await uploadFileToS3(file, 'privatePath')
      }
      setProgress('75%')
      setStatus(`⏳ Converting audio files`)
      for (const file of files) {
        await transcode(id, file, identityId)
      }
      setProgress('90%')
      setStatus(`⏳ Creating shareable link`)
      await callApi(createUnpackedSpireProject, {
        input: {
          collectionId: id,
          visibility: 'privatePath',
          title,
          projectId,
          repairChain: ADMIN && chain && chain.length ? chain : null,
        },
      })
    } catch (e) {
      error = true
      console.error('Failed to Create Spire Project:', e)
      setStatus(`❌ Failed to Create Spire Project.`)
      setProgress('0%')
      dispatch(log({ event: 'uploadFileFailure' }))
    }
    if (!error) {
      setProgress('100%')
      setStatus(`✅ Upload complete! Redirecting`)
      dispatch(log({ event: 'uploadFileSuccess' }))
      window.setTimeout(() => {
        history.push(`/p/${projectId}`)
      }, 1600)
    }
  }

  async function uploadFileToS3(file, visibility) {
    try {
      const S3Obj = await uploadFile(file.key, file, visibility)
      file.uploaded = true
      setFiles(files.map(f => (f.name === file.name ? file : f)))
      return S3Obj
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function transcode(collectionId, file, identityId) {
    try {
      const key = `private/${identityId}/${createS3Key(
        collectionId,
        file.name
      )}`
      const res = await callApi(transcodeAudioFile, {
        input: {
          id: collectionId,
          original: {
            bucket: STORAGE_BUCKET,
            name: file.name,
            key: key,
            region: STORAGE_REGION,
          },
        },
      })
      file.transcoded = true
      setFiles(files.map(f => (f.name === file.name ? file : f)))
      return res
    } catch (e) {
      console.error('Failed to transcode audio file:', e)
      throw e
    }
  }

  function removeFile(f) {
    setFiles(files.filter(file => file.name !== f.name))
  }

  const Upload = () => {
    return (
      <>
        <Heading size={3} align="left" fullWidth>
          Create a New Project
        </Heading>

        <p>
          Upload up to 8 audio files to start a new Spire Project. Projects
          automatically sync to the Spire app on iOS.
        </p>
        <div id="DropzoneContainer">
          <Dropzone
            handler={handleFiles}
            header={
              mobile
                ? 'Tap Here to Add Your Tracks'
                : 'Drag and Drop your Tracks'
            }
            body={
              mobile
                ? 'Select up to 8 audio files'
                : 'or Select Files to Upload'
            }
            accept=".wav, .mp3, .flac"
          />
        </div>
      </>
    )
  }

  const AddAnotherFile = () => (
    <label id="AddAnotherFile" htmlFor="file-upload">
      Add Another File
      <input
        id="file-upload"
        type="file"
        accept=".wav, .mp3, .flac"
        onChange={e => handleFiles(e.target.files)}
      />
    </label>
  )

  const FileList = () => {
    const durations = new Set(files.map(f => f.duration).filter(Boolean))
    return (
      <div className="FileUploads">
        <Heading size={6}>Audio Files:</Heading>
        {files.map((f, i) => (
          <div className="FileUpload" key={i}>
            <i className="fa fa-play-circle" />
            <div>{f.name} </div>
            <span>
              {f.duration && moment.utc(f.duration * 1000).format('m:ss')}
            </span>
            {status ? (
              f.uploaded ? (
                <>
                  <i className="fa fa-cloud" />
                  {!status.includes('Uploading') ? (
                    f.transcoded ? (
                      <i className="fa fa-file-audio" />
                    ) : (
                      <i className="fa fa-cog fa-spin" />
                    )
                  ) : null}
                </>
              ) : (
                <i className="fa fa-circle-notch fa-spin" />
              )
            ) : (
              <button className="RemoveFile" onClick={() => removeFile(f)}>
                <i className="fa fa-times" />
              </button>
            )}
          </div>
        ))}
        {!status && files.length < 8 && <AddAnotherFile />}
        {files.length > 8 && (
          <div id="TooManyFiles">
            Projects can have a maximum of 8 tracks, remove a file to continue.
          </div>
        )}
        {durations.size > 1 && (
          <div id="DurationMismatch">
            Heads Up: your audio files don't have the same duration.
            <br />
            Each audio file will be aligned to start at 0:00 in your project.
          </div>
        )}
      </div>
    )
  }

  const Files = () => {
    return (
      <>
        <div id="ProgressBackground" style={{ width: progress }} />
        <div id="FileModal">
          <Input
            className="ProjectTitle"
            type="text"
            value={title}
            autoFocus
            label="Project Name"
            onChange={e => setTitle(e.target.value)}
          />
          <br />
          <FileList />
          {ADMIN ? (
            <div className="repairDropdown">
              <Heading size={6}>Repair Chain:</Heading>
              <select value={chain} onChange={handleRepairChain}>
                <option value="">None</option>
                <option value="default">Default</option>
                <option value="vocals">Vocals</option>
              </select>
            </div>
          ) : null}

          <div className="FooterButtons">
            <Button type="tertiary" onClick={close}>
              cancel
            </Button>
            <Button
              type="secondary"
              onClick={handleCreateCollection}
              disabled={files.length > 8}
              preventHoverEffects={!!status}
              style={status ? { fontSize: '12px' } : null}
            >
              {status ? (
                <span>
                  {status}
                  {!status.includes('❌') && <Ellipsis />}
                </span>
              ) : (
                'Create Project'
              )}
            </Button>
          </div>
        </div>
      </>
    )
  }

  const FileError = () => {
    const body =
      fileError === 'size'
        ? `Sorry, your file is larger than the ${UPLOAD_SIZE_LIMIT}MB upload
    limit.`
        : `Sorry, uploaded file format is not supported. Please upload file of type .wav, .mp3, or .flac`
    return (
      <>
        <Heading size={1}>Whoa there...</Heading>
        <div id="FileSizeError">
          <div>{body}</div>
          <br />
          <div className="ErrorButtons">
            <button
              className="secondary"
              onClick={() => {
                setFiles([])
                setFileError(null)
              }}
            >
              Start Over
            </button>
            {fileError === 'duration' && (
              <button onClick={() => setFileError(null)}>Sounds Good</button>
            )}
          </div>
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (fileError) return FileError()
    else if (files.length) return Files()
    else return Upload()
  }

  return <div id="BeatToSpire">{renderContent()}</div>
}
