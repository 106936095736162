import {
  advancePlayhead,
  setDuration,
  loadClipFile,
  toggleEnhance,
  toggle,
} from './audioReducer'
import {
  initAudioContext,
  getCachedPlayheadPosition,
  loadClip,
  loadMixOrStemFile,
} from './audioUtils'
import store from '../store'
import { convertGainToBase10 } from '../../utils/common'
import { setView } from '../app/appReducer'
import { ADMIN } from '../../utils/constants'
import { calculateAndCacheWaveformClipPath } from 'utils/audioProcessing'

const CROSSFADE_DURATION = 100 / 48000 // 100 frames at 48kHz
let audioClock
let audioNodes = []

const audioHandler = () => next => action => {
  switch (action.type) {
    case 'audio/init':
      handleInit(next)
      break
    case 'audio/loadProjectAudio':
      handleLoadProjectAudio(next, action)
      break
    case 'audio/loadMixAudio':
      handleLoadMixAudio(next, action)
      break
    case 'audio/loadStemAudio':
      handleLoadStemAudio(next, action)
      break
    case 'audio/unload':
      handleUnload(next, action)
      break
    case 'audio/toggle':
      handleToggle(next, action)
      break
    case 'audio/toggleEnhance':
      handleToggleEnhance(next, action)
      break
    case 'audio/advancePlayhead':
      handleAdvancePlayhead(next, action)
      break
    case 'audio/updateSolo':
      handleUpdateSolo(next, action)
      break
    case 'audio/scrub':
      handleScrub(next, action)
      break
    case 'audio/reset':
      handleReset(next, action)
      break
    case 'audio/setSource':
      handleSetSource(next, action)
      break
    default:
      return next(action)
  }
}

function handleInit(next) {
  initAudioContext()
  return next({
    type: 'audio/init',
    payload: {
      elapsed: getCachedPlayheadPosition() || 0,
      pausedAt: getCachedPlayheadPosition() || 0,
    },
  })
}

async function handleLoadProjectAudio(next, action) {
  const { spireProject } = store.getState().collection
  const { files } = store.getState().audio
  if (!spireProject.tracks || !spireProject.tracks.length) return
  store.dispatch(setDuration(spireProject.duration))

  const total = spireProject.tracks.reduce((acc, track) => {
    if (!track.wet_clips || !track.wet_clips.length) {
      return acc + track.clips.length
    } else return acc + track.wet_clips.length
  }, 0)

  let handled = 0
  for (const track of spireProject.tracks) {
    const clipsToLoad =
      track.wet_clips && track.wet_clips.length ? track.wet_clips : track.clips
    for (const clip of clipsToLoad) {
      let file = files.find(b => b.file_name === clip.file_name)
      if (file) {
        handled++
      } else {
        // store.dispatch(setIsAudioLoaded(false))
        file = await loadClip(clip)
        if (file && !file.failedToLoad) {
          await calculateAndCacheWaveformClipPath({
            audioBuffer: file.audioBuffer,
            src: clip.file_name,
            startFrame: clip.start_frame_in_file,
            endFrame: clip.end_frame_in_file,
            gain: convertGainToBase10(track.gain),
            projectDuration: spireProject.duration,
          })
        }
        handled++
      }
      store.dispatch(loadClipFile({ file, progress: handled / total }))
      if (handled === total) {
        if (!ADMIN && !files.length) {
          setTimeout(() => store.dispatch(setView('song')), 800)
        }
        setTimeout(() => next(action), 1000) // delay for UI to load as smoothly as possible
      }
    }
  }
}

function handleLoadMixAudio(next, action) {
  const { mixFiles, spireProject } = store.getState().collection
  const { files } = store.getState().audio
  const total = mixFiles.length
  let handled = 0
  const newFiles = []
  let duration = 0
  mixFiles.forEach(async mix => {
    if (files.find(f => f.name === mix.name)) handled++
    else if (shouldDeferToCompressedFile(mix, 'mix')) handled++
    else if (!mix.enhanced && !ADMIN && spireProject) handled++
    else {
      const file = await loadMixOrStemFile(mix)
      newFiles.push(file)
      duration = Math.max(duration, file.audioBuffer.duration)
      await calculateAndCacheWaveformClipPath({
        audioBuffer: file.audioBuffer,
        src: file.file_name,
        projectDuration: duration,
      })
      handled++
    }
    if (handled === total && newFiles.length) {
      const { spireProject } = store.getState().collection
      const { source } = store.getState().audio
      // change the audio source to mixFiles if no spireProject is available
      // this is important for playback of collections that are not unpacked
      next({
        ...action,
        payload: {
          newFiles,
          duration,
          source: spireProject ? source : 'mixFiles',
        },
      })
    }
  })
}

function handleLoadStemAudio(next, action) {
  const stemFiles = store
    .getState()
    .collection.stemFiles.filter(file => !file.name.endsWith('.zip'))
  const { files } = store.getState().audio
  const total = stemFiles.length
  let handled = 0
  const newFiles = []
  let duration = 0
  stemFiles.forEach(async stem => {
    if (files.find(buffer => buffer.name === stem.name)) handled++
    else if (shouldDeferToCompressedFile(stem)) handled++
    else {
      const file = await loadMixOrStemFile(stem)
      newFiles.push(file)
      duration = Math.max(duration, file.audioBuffer.duration)
      await calculateAndCacheWaveformClipPath({
        audioBuffer: file.audioBuffer,
        src: file.file_name,
        projectDuration: duration,
      })
      handled++
    }
    if (handled === total && newFiles.length) {
      next({
        ...action,
        payload: {
          newFiles,
        },
      })
    }
  })
}

function shouldDeferToCompressedFile(file, type) {
  const { mixFiles, stemFiles } = store.getState().collection
  const filesToCheck = type === 'mix' ? mixFiles : stemFiles
  if (!file.name.endsWith('.mp3')) {
    const mp3FileName = file.name.slice(0, file.name.lastIndexOf('.')) + '.mp3'
    return !!filesToCheck.find(i => i.name === mp3FileName)
  } else return false
}

function handleUnload(next, action) {
  const { playing } = store.getState().audio
  if (playing) {
    deConstructAudioNodes()
  }
  return next(action)
}

function handleToggle(next, action) {
  resumeAudioContext()
  const { playing } = store.getState().audio
  if (playing) {
    deConstructAudioNodes()
    const res = next(action)
    audioClock && clearInterval(audioClock)
    return res
  } else {
    constructAudioNodes()
    startAudioNodes()
    const res = next(action)
    store.dispatch(advancePlayhead())
    audioClock = setInterval(() => {
      store.dispatch(advancePlayhead())
    }, 500)
    return res
  }
}

function generateActiveAudio() {
  const { files, source, enhance, solo } = store.getState().audio
  const activeAudio = []
  if (source === 'spireProject') {
    const { spireProject } = store.getState().collection
    for (const track of spireProject.tracks) {
      if (!track.mute) {
        if (solo === null || solo === track.id) {
          const clips =
            track.wet_clips && track.wet_clips.length
              ? track.wet_clips
              : track.clips
          for (const clip of clips) {
            const buffer = files.find(b => b.file_name === clip.file_name)
            activeAudio.push({
              ...buffer,
              ...clip,
              gain: convertGainToBase10(track.gain),
              pan: track.pan,
            })
          }
        }
      }
    }
  } else if (source === 'mixFiles') {
    let file = files.find(b => b.type === 'mix' && b.enhanced === enhance)
    if (!file) file = files.find(b => b.type === 'mix')
    if (!file) {
      store.dispatch(toggle())
      return []
    }
    activeAudio.push(file)
  } else if (source === 'stemFiles') {
    activeAudio.push(...files.filter(b => b.type === 'stem'))
  }
  return activeAudio
}

function handleToggleEnhance(next, action) {
  const { playing, enhance, source } = store.getState().audio
  const { spireProject } = store.getState().collection
  const { view } = store.getState().app
  if (!enhance && view !== 'song') {
    store.dispatch(setView('song'))
  }
  if (playing) {
    deConstructAudioNodes()
    const payload = {
      enhance: !enhance,
      source: !enhance
        ? 'mixFiles'
        : spireProject && !ADMIN
        ? 'spireProject'
        : source,
    }
    const res = next({ ...action, payload })
    constructAudioNodes()
    startAudioNodes()
    return res
  } else {
    const payload = {
      enhance: !enhance,
      source: !enhance
        ? 'mixFiles'
        : spireProject && !ADMIN
        ? 'spireProject'
        : source,
    }
    return next({ ...action, payload })
  }
}

function handleSetSource(next, action) {
  const { playing, enhance } = store.getState().audio
  const { view } = store.getState().app
  if (action.payload === 'mixFiles' && view !== 'song') {
    store.dispatch(setView('song'))
  }
  if (action.payload === 'spireProject' && enhance) {
    store.dispatch(toggleEnhance())
  }
  if (playing) {
    deConstructAudioNodes()
    const res = next(action)
    constructAudioNodes()
    startAudioNodes()
    return res
  } else return next(action)
}

function handleAdvancePlayhead(next, action) {
  const { elapsed, duration } = store.getState().audio
  if (elapsed + 0.01 > duration) {
    // handle audio finished: reset to beginning
    deConstructAudioNodes()
    audioClock && clearInterval(audioClock)
    return next({
      type: 'audio/advancePlayhead',
      payload: { elapsed: 0, pausedAt: 0, playing: false },
    })
  } else {
    return next({
      type: 'audio/advancePlayhead',
      payload: {
        elapsed: parseFloat(elapsed) + 0.5,
        scrubbed: false,
      },
    })
  }
}

function handleScrub(next, action) {
  const { playing } = store.getState().audio
  if (playing) {
    deConstructAudioNodes()
    const res = next(action)
    constructAudioNodes()
    startAudioNodes()
    return res
  } else return next(action)
}

function handleReset(next, action) {
  if (window.izo_audio_context && window.izo_audio_context.state !== 'closed') {
    window.izo_audio_context.suspend()
  }
  return next(action)
}

function resumeAudioContext() {
  if (
    !window.izo_audio_context ||
    window.izo_audio_context.state === 'closed'
  ) {
    initAudioContext()
  } else if (window.izo_audio_context.state !== 'running') {
    window.izo_audio_context.resume()
  }
}

function createPanNode(initValue = 0) {
  if (window.izo_audio_context.createStereoPanner) {
    const panNode = window.izo_audio_context.createStereoPanner()
    panNode.pan.setValueAtTime(initValue, window.izo_audio_context.currentTime)
    return panNode
  } else {
    const panNode = window.izo_audio_context.createPanner()
    panNode.setPosition(initValue, 0, 1 - Math.abs(initValue))
    return panNode
  }
}

function constructAudioNodes() {
  const nodes = []
  const activeAudio = generateActiveAudio()
  for (const a of activeAudio) {
    const node = { ...a }
    node.sourceNode = window.izo_audio_context.createBufferSource()
    node.sourceNode.buffer = a.audioBuffer
    node.gainNode = window.izo_audio_context.createGain()
    node.gainNode.gain.setValueAtTime(
      a.gain || 1,
      window.izo_audio_context.currentTime
    )
    node.panNode = createPanNode(a.pan)
    node.sourceNode.connect(node.gainNode)
    node.gainNode.connect(node.panNode)
    node.panNode.connect(window.izo_audio_context.destination)
    nodes.push(node)
  }
  audioNodes = nodes
}

function deConstructAudioNodes() {
  for (const a of audioNodes) {
    try {
      a.sourceNode && a.sourceNode.stop()
      a.sourceNode && a.sourceNode.disconnect()
      a.gainNode && a.gainNode.disconnect()
      a.panNode && a.panNode.disconnect()
    } catch {}
  }
  audioNodes = []
}

function startAudioNodes() {
  const { elapsed } = store.getState().audio
  for (const a of audioNodes) {
    try {
      if (a.type === 'clip') {
        const {
          startTime,
          startPosition,
          durationToPlay,
          endTime,
        } = calculateStartParameters(a, elapsed)
        // apply crossfades to start and end of clip playback
        a.gainNode.gain.setValueAtTime(0, window.izo_audio_context.currentTime)
        a.gainNode.gain.setTargetAtTime(
          a.gain || 1,
          startTime,
          CROSSFADE_DURATION
        )
        a.gainNode.gain.setTargetAtTime(
          0,
          Math.max(0, endTime - CROSSFADE_DURATION * 3), // hardcoded 3x based on sine wave testing, should be revisited
          CROSSFADE_DURATION
        )
        if (durationToPlay) {
          // start source
          a.sourceNode.start(startTime, startPosition, durationToPlay)
        }
      } else a.sourceNode.start(0, elapsed)
    } catch (e) {
      console.error(
        `Could not start audio node:\nClip ID: ${a.id}`,
        JSON.stringify(e),
        e
      )
    }
  }
}

function calculateStartParameters(clip, elapsed) {
  const timelineOffset = clip.start_frame_in_timeline / clip.sample_rate
  const fileBoundaryOffset = clip.start_frame_in_file / clip.sample_rate
  const durationWithBoundaries =
    (clip.end_frame_in_file - clip.start_frame_in_file) / clip.sample_rate
  const startTime =
    window.izo_audio_context.currentTime + Math.max(0, timelineOffset - elapsed)
  const startPosition =
    fileBoundaryOffset + Math.max(0, elapsed - timelineOffset)
  const durationToPlay = Math.max(
    0,
    durationWithBoundaries - Math.max(0, elapsed - timelineOffset)
  )
  const endTime = startTime + durationToPlay
  return { startTime, startPosition, durationToPlay, endTime }
}

export function refreshAudioPlayback() {
  const { playing } = store.getState().audio
  if (playing) {
    deConstructAudioNodes()
    constructAudioNodes()
    startAudioNodes()
  }
}

export function handleUpdateSolo(next, action) {
  const res = next(action)
  refreshAudioPlayback()
  return res
}

export default audioHandler
