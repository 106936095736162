import React, { memo } from 'react'
import moment from 'moment'
import { getReactionUsers, removeDuplicateReactions } from '../utils'
import Reaction, { AddReaction } from '../../Reaction'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from 'redux/app/appReducer'
import { scrub } from 'redux/audio/audioReducer'
import ProfileIcon from 'components/base/ProfileIcon'
import Heading from 'components/base/Heading'
import { useAuth } from 'providers/AuthProvider'
import Button from 'components/base/Button'
import './MenuComment.scss'

function MenuComment({ comment, handleReaction, handleDeleteComment }) {
  const dispatch = useDispatch()
  const isCollectionOwner = useSelector(
    store => store.collection.isCollectionOwner
  )
  const { user } = useAuth()
  const isCommentOwner = user && comment.user.userId === user.sub

  const parseTime = time => moment.utc(time * 1000).format('m:ss')

  const handleOpenOwner = owner => {
    dispatch(
      setAppState({
        modal: 'PublicUserProfile',
        modalState: { user: owner },
      })
    )
  }

  return (
    <div className="MenuComment" id={`MenuComment-${comment.commentId}`}>
      <section id="CommentUser">
        <ProfileIcon size={32} profile={comment.user} />
        <aside>
          <Heading size={5} onClick={() => handleOpenOwner(comment.user)}>
            {comment.user.displayName}
          </Heading>
          <p onClick={() => dispatch(scrub(comment.timestamp))}>
            @{parseTime(comment.timestamp)}
          </p>
        </aside>
      </section>
      <p id="CommentContent">{comment.comment}</p>
      <div id="CommentReactions">
        {removeDuplicateReactions(comment.reactions).map((reaction, i) => (
          <Reaction
            key={i}
            emoji={reaction.emoji}
            users={getReactionUsers(comment, reaction.emoji)}
            onClick={e => handleReaction(e, comment, reaction.emoji)}
          />
        ))}
        <AddReaction
          handleReaction={emoji => handleReaction(null, comment, emoji)}
        />
        {(isCollectionOwner || isCommentOwner) && (
          <Button
            type="simple"
            className="Delete"
            preventHoverEffects
            onClick={e => handleDeleteComment(e, comment.commentId)}
          >
            <i className="fa fa-trash" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default memo(MenuComment)
