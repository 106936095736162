import React from 'react'
import Button from 'components/base/Button'
import Input from 'components/base/Input'
import './Search.scss'

export default function Search({
  handleSearch,
  handleClear,
  searchValue,
  searchType,
  setSearchValue,
  setSearchType,
  currentFilter,
}) {
  const searchTypes = ['title', 'userId', 'collectionId']
  const SearchButton = () => (
    <Button type="secondary" icon="fa-search" onClick={handleSearch} />
  )

  const ClearButton = () => (
    <Button type="secondary" icon="fa-times" onClick={handleClear} />
  )

  function handlePressEnter(event) {
    if (event.keyCode === 13) {
      handleSearch()
    }
  }

  const isSearchCurrent = () =>
    searchValue &&
    currentFilter.type === searchType &&
    currentFilter.value === searchValue

  function handleChange(e) {
    setSearchValue(e.target.value)
  }

  return (
    <div id="Search">
      <select
        value={searchType}
        onChange={event => setSearchType(event.target.value)}
      >
        {searchTypes.map(t => (
          <option value={t} key={t}>
            Search by {t}
          </option>
        ))}
      </select>
      <Input
        type="text"
        value={searchValue}
        label={`Enter ${searchType} to search...`}
        onChange={handleChange}
        onKeyDown={handlePressEnter}
      />
      {isSearchCurrent() ? <ClearButton /> : <SearchButton />}
    </div>
  )
}
