export const DistinctCollectionsById = (collections = []) => {
  const unique = {}
  const distinct = []
  if (collections && collections.length > 0) {
    collections.forEach(i => {
      if (!unique[i.id]) {
        distinct.push(i)
        unique[i.id] = true
      }
    })
  }
  return distinct
}
export const getSearchParam = () => {
  const search = window.location.search.substring(1)
  if (search) {
    const obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function(key, value) {
        return key === '' ? value : decodeURIComponent(value)
      }
    )
    return { type: Object.keys(obj)[0], value: Object.values(obj)[0] }
  } else return { type: null, value: null }
}

export const clearSearchParam = () => {
  window.history.replaceState({}, document.title, '/')
}
