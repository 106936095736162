import React, { memo, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'utils/customHooks'
import TimelineComment from './TimelineComment/TimelineComment'
import MenuComment from './MenuComment/MenuComment'
import { useAuth } from 'providers/AuthProvider'
import Heading from 'components/base/Heading'
import {
  checkForExistingReaction,
  shouldOpenComment,
  sortByTimestamp,
} from './utils'
import { addSignedUrlsToComments, labsCommentModalState } from 'utils/common'
import { useSelector, useDispatch } from 'react-redux'
import { setAppState } from 'redux/app/appReducer'
import { updateComments } from 'redux/collection/collectionReducer'
import { log } from 'redux/analytics/analyticsReducer'
import { ADMIN } from 'utils/constants'
import {
  callApi,
  createReaction,
  deleteComment,
  deleteReaction,
} from 'graphql/izo_api'

const DEBOUNCE_DURATION = 3200
let debounceTimer

function Comments({ component }) {
  const menu = useSelector(store => store.app.menu)
  const mobile = useSelector(store => store.app.mobile)
  const elapsed = useSelector(store => store.audio.elapsed)
  const prevPlayhead = usePrevious(elapsed)
  const dispatch = useDispatch()
  const { user } = useAuth()
  const comments = useSelector(store => store.collection.comments)
  const collectionId = useSelector(store => store.collection.collectionID)
  const projectId = useSelector(store => store.collection.projectId)
  const preventComments = useSelector(store => store.collection.preventComments)
  const [active, setActive] = useState()

  useEffect(() => {
    if (menu) setActive(null)
  }, [menu])

  useEffect(() => {
    if (comments && comments.length) {
      for (const c of comments) {
        if (!active && !mobile && shouldOpenComment(elapsed, prevPlayhead, c)) {
          setActive(c.commentId)
          clearDebounce()
          debounceTimer = setTimeout(() => setActive(null), DEBOUNCE_DURATION)
        }
      }
    }
  }, [elapsed]) // eslint-disable-line react-hooks/exhaustive-deps

  const clearDebounce = useCallback(
    () => debounceTimer && clearTimeout(debounceTimer),
    []
  )

  const isActive = id => active === id

  const handleReaction = useCallback(
    async (event, comment, emoji) => {
      async function addReaction(emoji, comment) {
        try {
          const input = {
            emoji,
            projectId,
            collectionId,
            userId: user.sub,
            commentId: comment.commentId,
          }
          const res = await callApi(createReaction, { input })
          const updated = await addSignedUrlsToComments(
            res.data.createReaction.comments
          )
          dispatch(updateComments(updated))
          dispatch(log({ event: 'createReactionSuccess', params: { emoji } }))
        } catch (e) {
          console.error('Error while adding reaction:', e)
          dispatch(log({ event: 'createReactionFailure' }))
        }
      }

      async function removeReaction(emoji, comment) {
        try {
          const input = {
            emoji,
            projectId,
            userId: user.sub,
            commentId: comment.commentId,
          }
          const res = await callApi(deleteReaction, { input })
          const updated = await addSignedUrlsToComments(
            res.data.deleteReaction.comments
          )
          dispatch(updateComments(updated))
          dispatch(log({ event: 'deleteReactionSuccess', params: { emoji } }))
        } catch (e) {
          console.error('Error while removing reaction:', e)
          dispatch(log({ event: 'deleteReactionFailure' }))
        }
      }

      event && event.stopPropagation()
      if (!user) {
        dispatch(
          setAppState({ modal: 'SpireLabs', modalState: labsCommentModalState })
        )
        dispatch(log({ event: 'createReactionAttempt' }))
        return
      }
      const existing = checkForExistingReaction(emoji, user, comment)
      if (!existing) addReaction(emoji, comment)
      else removeReaction(emoji, comment)
    },
    [dispatch, user, collectionId, projectId]
  )

  const handleDeleteComment = useCallback(
    async (event, commentId) => {
      try {
        event.stopPropagation()
        const input = {
          projectId,
          userId: user.sub,
          commentId: commentId,
        }
        await callApi(deleteComment, { input })
        dispatch(
          updateComments(comments.filter(c => c.commentId !== commentId))
        )
        dispatch(log({ event: 'deleteCommentSuccess' }))
      } catch (e) {
        console.error('Error while deleting comment:', e)
        dispatch(log({ event: 'deleteCommentFailure' }))
      }
    },
    [comments, projectId, user, dispatch]
  )

  if (!projectId || preventComments || !comments) return null
  switch (component) {
    case 'Timeline':
      return ADMIN
        ? null
        : sortByTimestamp(comments).map(n => (
            <TimelineComment
              comment={n}
              key={n.commentId}
              active={isActive(n.commentId)}
              setActive={setActive}
              clearDebounce={clearDebounce}
              handleReaction={handleReaction}
              handleDeleteComment={handleDeleteComment}
            />
          ))
    case 'Menu':
      return (
        <div id="MenuComments">
          <Heading size={2} className="InnerMenu" align="center">
            Comments
          </Heading>
          <div className="CommentsList">
            {comments.length ? (
              sortByTimestamp(comments).map(n => (
                <MenuComment
                  comment={n}
                  key={n.commentId}
                  active={isActive(n.commentId)}
                  handleReaction={handleReaction}
                  handleDeleteComment={handleDeleteComment}
                />
              ))
            ) : (
              <div className="NoComments">This project has no comments.</div>
            )}
          </div>
        </div>
      )
    default:
      return null
  }
}

export default memo(Comments)
