import { ADMIN } from '../../utils/constants'
import events from './customEvents'

const analyticsHandler = storeAPI => next => action => {
  switch (action.type) {
    case 'analytics/log':
      handleLog(storeAPI, next, action)
      break
    default:
      return next(action)
  }
}

function handleLog(storeAPI, next, action) {
  if (events[action.payload.event]) {
    if (!ADMIN) {
      const payload = logEvent(
        storeAPI.getState(),
        events[action.payload.event],
        action.payload.params
      )
      return next({ ...action, payload })
    }
  } else console.error('Received unknown event log:', action.payload)
  return next(action)
}

function logEvent(state, data = {}, additionalParams = {}) {
  const payload = {
    event: data.event || 'No Event Name Provided',
    action: data.action || 'No Action Name Provided',
    status: data.status || 'No Status Info Provided',
    state: generateState(state, additionalParams),
    autoTrack: true,
  }
  window.dataLayer.push(payload)
  return payload
}

function generateState(state, additionalParams) {
  // REMINDER: remove any and all personally identifying user information
  try {
    const web_state = {
      web_timestamp: new Date().toString(),
      web_url: window.location.href,
      web_env: process.env.REACT_APP_ENV,
      web_isAdmin: ADMIN,
      web_releaseVersion: window.izo_release_version,

      // web app state
      web_menuStatus: state.app.menu,
      web_isMobile: state.app.mobile,
      web_audioView: state.app.view,
      web_modalStatus: state.app.modal,
      web_screenWidth: window.innerWidth,
      web_screenHeight: window.innerHeight,

      // auth state
      web_authIsAuthenticated: state.auth.userId ? true : false,
      web_authClientUserId: state.auth.userId,
      web_authClientDisplayName: state.auth.profile
        ? state.auth.profile.displayName
        : null,

      // collection state
      web_collectionId: state.collection.collectionId,
      web_collectionTitle: state.collection.title,
      web_collectionDescription: state.collection.description,
      web_collectionOrigin: state.collection.origin,
      web_collectionThemeColor: state.collection.themeColor
        ? state.collection.themeColor.name
        : null,
      web_collectionOwnerUserId: state.collection.userId,
      web_collectionOwnerIdentityId: state.collection.identityId,
      web_collectionPublicAccess: state.collection.publicAccess,
      web_collectionVisibility:
        state.collection.visibility === 'privatePath' ? 'private' : 'public',
      web_collectionExpiresAt: state.collection.expirationEpoch
        ? new Date(state.collection.expirationEpoch).toString()
        : null,
      web_collectionCreatedAt: state.collection.createdAt
        ? new Date(state.collection.createdAt).toString()
        : null,
      web_collectionAudioMixFiles: state.collection.mixFiles
        ? state.collection.mixFiles.length
        : null,
      web_collectionAudioStemFiles: state.collection.stemFiles
        ? state.collection.stemFiles.length
        : null,
      web_collectionAudioClipFiles: state.collection.clipFiles
        ? state.collection.clipFiles.length
        : null,
      web_collectionProcessesInFlight: state.collection.processing
        ? state.collection.processing.length
        : 0,

      // project state
      web_projectId: state.collection.projectId,
      web_projectJSON: JSON.stringify(state.collection.spireProject),
      web_projectComments: state.collection.comments
        ? state.collection.comments.length
        : null,

      // audio state
      web_audioFilesLoaded: state.audio.loadedAudio
        ? state.audio.loadedAudio.length
        : null,
      web_audioFilesActive: state.audio.activeAudio
        ? state.audio.activeAudio.length
        : null,
      web_audioLoadProgress: state.audio.loadProgress,
      web_audioPlayerElapsed: state.audio.elapsed,
      web_audioPlayerDuration: state.audio.duration,
      web_audioPlayerIsPlaying: state.audio.playing,
      web_audioPlayerIsUsingEnhanced: state.audio.enhance,
      web_audioPlayerFailedToLoad: state.audio.failedToLoad,
    }

    for (const param in additionalParams) {
      web_state[`web_${param}`] = additionalParams[param]
    }

    return web_state
  } catch (e) {
    console.error('Error generating analytics event state:', e)
    return {}
  }
}

export default analyticsHandler
