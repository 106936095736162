import { API, graphqlOperation } from 'aws-amplify'
import { DEFAULT_AUTH_MODE } from '../utils/constants'
import store from 'redux/store.js'

export async function callApi(query, variables) {
  const authMode = store.getState().auth.apiAuthMode || DEFAULT_AUTH_MODE
  return await API.graphql({ query, variables, authMode })
}

export async function subscribe(subscription, input, callback) {
  return await API.graphql(graphqlOperation(subscription, input)).subscribe({
    next: (res, error) => callback(res, error),
    error: error => callback(null, error),
  })
}

export * from './izo_queries'
export * from './izo_mutations'
export * from './izo_subscriptions'
