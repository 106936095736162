import React, { useState, useEffect } from 'react'
import { callApi, createUnpackedEnhancedMix } from 'graphql/izo_api'
import { STORAGE_BUCKET } from 'utils/constants'
import { getProjectJsonKey } from './utils'
import Ellipsis from 'components/base/Ellipsis'
import { useDispatch, useSelector } from 'react-redux'
import { setSource } from 'redux/audio/audioReducer'
import AdminAudioViews from 'components/admin/AdminAudioViews'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import './AdminSong.scss'

const AdminSong = () => {
  const collectionId = useSelector(store => store.collection.collectionId)
  const mixFiles = useSelector(store => store.collection.mixFiles)
  const clipFiles = useSelector(store => store.collection.clipFiles)
  const [button, setButton] = useState('Create Enhanced Mix')
  const enhancedMixAvailable = mixFiles && mixFiles.find(f => f.enhanced)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSource('mixFiles'))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function enhanceProject() {
    try {
      setButton('⏳ Creating Enhanced Mix')
      const keyList = [
        ...clipFiles.map(c => c.flac_key),
        getProjectJsonKey(collectionId),
      ]
      await callApi(createUnpackedEnhancedMix, {
        input: {
          bucket: STORAGE_BUCKET,
          collectionId,
          keyList,
        },
      })
    } catch (e) {
      setButton('❌ Failed to create enhanced mix.')
      console.error(e)
    }
  }

  return mixFiles && !mixFiles.length ? (
    <div id="AdminSong">
      <Heading size={4}>Project has no mix files.</Heading>
    </div>
  ) : (
    <div id="AdminSong">
      {!enhancedMixAvailable && mixFiles && (
        <Button
          onClick={enhanceProject}
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 20,
          }}
        >
          {button}
          {button.includes('ing') && <Ellipsis />}
        </Button>
      )}
      <AdminAudioViews style={{ height: `calc(100% - 96px)` }} />
    </div>
  )
}

export default AdminSong
