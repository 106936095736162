import React, { useState, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { log } from 'redux/analytics/analyticsReducer'
import { scrub, toggle } from 'redux/audio/audioReducer'
import Draggable from 'react-draggable'
import { formatTime } from 'utils/common'
import Comments from '../Comments'
import moment from 'moment'
import './Playhead.scss'
import { useWidth } from 'utils/customHooks'
import Heading from 'components/base/Heading'
let debounce_timer

const Playhead = ({ style, hideComments, preventClick }) => {
  const view = useSelector(store => store.app.view)
  const elapsed = useSelector(store => store.audio.elapsed)
  const duration = useSelector(store => store.audio.duration)
  const playing = useSelector(store => store.audio.playing)
  const scrubbed = useSelector(store => store.audio.scrubbed)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const mobile = useSelector(store => store.app.mobile)
  const [shouldResume, setShouldResume] = useState(false)
  const screenWidth = useWidth()
  const dispatch = useDispatch()

  const handleDrag = (e, data) => {
    debounce_timer && clearTimeout(debounce_timer)
    debounce_timer = setTimeout(() => {
      seek(e, data)
    }, 100)
  }

  const handleClick = e => {
    if (e.target.id === 'Playhead') {
      const offset = mobile ? 16 : 64
      const time = ((e.pageX - offset) / e.target.offsetWidth) * duration
      dispatch(scrub(time))
      dispatch(
        log({
          event: 'scrub',
          params: {
            newPlayheadPosition: moment.utc(time * 1000).format('m:ss'),
          },
        })
      )
    }
  }

  const handleStart = () => {
    if (playing) {
      dispatch(toggle())
      setShouldResume(true)
    }
  }

  const handleStop = () => {
    if (shouldResume) {
      dispatch(toggle())
      setShouldResume(false)
    }
  }

  const seek = (e, data) => {
    const parent = document.getElementById('Playhead')
    const parentWidth = parent ? parent.offsetWidth : 0
    const time = (data.lastX / parentWidth) * duration
    dispatch(scrub(time))
    dispatch(
      log({
        event: 'scrub',
        params: {
          newPlayheadPosition: moment.utc(time * 1000).format('m:ss'),
        },
      })
    )
  }

  const padding = mobile ? 32 : 128
  const parent = document.getElementById('Playhead')
  const parentWidth = parent ? parent.offsetWidth : screenWidth - padding

  return isAudioLoaded ? (
    <div
      id="Playhead"
      className={view}
      onClick={preventClick ? null : handleClick}
      style={style}
    >
      <Heading size={5} id="Elapsed" aria-label="Audio Elapsed">
        {formatTime(elapsed)}
      </Heading>
      {!hideComments && <Comments component="Timeline" />}
      <div
        id="PlayheadElapsed"
        className={`${view}${playing ? ' playing' : ''}${
          scrubbed ? ' scrubbed' : ''
        }`}
        style={{
          width: `${Math.min(
            (elapsed * parentWidth) / duration,
            parentWidth
          )}px`,
        }}
      />
      <Draggable
        axis="x"
        bounds="parent"
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
        position={{
          x: Math.min((elapsed * parentWidth) / duration, parentWidth),
          y: 0,
        }}
      >
        <div
          id="PlayheadHandle"
          className={`${view}${playing ? ' playing' : ''}${
            scrubbed ? ' scrubbed' : ''
          }`}
        ></div>
      </Draggable>
      <Heading size={5} id="Duration" aria-label="Audio Elapsed">
        {formatTime(duration)}
      </Heading>
    </div>
  ) : null
}

export default memo(Playhead)
