import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_AUTH_MODE } from '../../utils/constants'

const auth = createSlice({
  name: 'auth',
  initialState: {
    apiAuthMode: DEFAULT_AUTH_MODE,
  },
  reducers: {
    updateApiAuthMode(state, action) {
      return { ...state, apiAuthMode: action.payload }
    },
    updateUserId(state, action) {
      return { ...state, userId: action.payload }
    },
    updateUserProfile(state, action) {
      return { ...state, profile: action.payload }
    },
  },
})
export const {
  updateApiAuthMode,
  updateUserId,
  updateUserProfile,
} = auth.actions

export default auth.reducer
