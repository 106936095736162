import React, { memo, useEffect, useMemo, useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import { generatePositionStyles, calculateElapsedWidth } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { useWidth } from 'utils/customHooks'
import { calculateAndCacheWaveformClipPath } from 'utils/audioProcessing'
import themeColors from 'styles/theme-colors.json'
import CannotPlay from 'assets/cannot_play.svg'
import './Waveform.scss'
import { setModal } from 'redux/app/appReducer'
const resizeTimers = {}
const grayscale = themeColors[8]

function Waveform({
  id,
  src,
  startFrameInTimeline,
  startFrameInFile,
  endFrameInFile,
  gain = 1,
  mute = false,
  colorIndex,
  style,
  isMiniMap,
}) {
  const duration = useSelector(store => store.audio.duration)
  const elapsed = useSelector(store => store.audio.elapsed)
  const mobile = useSelector(store => store.app.mobile)
  const view = useSelector(store => store.app.view)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const playing = useSelector(store => store.audio.playing)
  const scrubbed = useSelector(store => store.audio.scrubbed)
  const files = useSelector(store => store.audio.files)
  const [clipPath, setClipPath] = useState()
  const [working, setWorking] = useState(false)
  const screenWidth = useWidth()
  // use approx to only recalculate waveform clip paths on large changes in size
  const [approximateWidth, setApproximateWidth] = useState(screenWidth)
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const color =
    useMemo(() => {
      return view === 'song' || colorIndex == null
        ? grayscale
        : themeColors[colorIndex]
    }, [view, colorIndex]) || {}
  // make sure color MUST always resolves to an object
  // to prevent errors calling color.dark/color.primary below

  useEffect(() => {
    if (Math.abs(screenWidth - approximateWidth) > 100) {
      setApproximateWidth(screenWidth)
    }
  }, [screenWidth, approximateWidth])

  useEffect(() => {
    if (!clipPath && src) {
      // handle init case
      // do not use debounce timer on initial render
      calculateWaveformParameters()
    } else if (src && !isMiniMap && !mobile && isAudioLoaded) {
      // handle recalculating on resize case
      // use resizeTimer as a debounce to prevent queueing up
      // multiple re-sampling events simultaneously
      resizeTimers[id] && clearTimeout(resizeTimers[id])
      resizeTimers[id] = setTimeout(calculateWaveformParameters, 250)
    }
  }, [src, gain, mute, approximateWidth, isAudioLoaded, files]) // eslint-disable-line react-hooks/exhaustive-deps

  async function calculateWaveformParameters() {
    if (working) return
    setWorking(true)
    const path = await calculateAndCacheWaveformClipPath({
      src,
      startFrame: startFrameInFile,
      endFrame: endFrameInFile,
      gain,
      projectDuration: duration,
    })
    if (isMounted()) {
      setClipPath(path)
      setWorking(false)
    }
  }
  return (
    <div
      className={`Waveform${mute ? ' muted' : ''}${
        !clipPath ? (isAudioLoaded ? ' failed' : ' loading') : ''
      }`}
      id={id}
      style={{
        ...generatePositionStyles({
          startFrameInFile,
          endFrameInFile,
          startFrameInTimeline,
          duration,
        }),
        ...style,
      }}
    >
      <div className="Background" style={{ background: color.dark }} />
      {clipPath ? (
        <div
          id={id}
          className="Wave"
          style={{
            background: color.primary,
            clipPath,
          }}
        >
          <div
            className={`Elapsed${playing ? ' playing' : ''}${
              scrubbed ? ' scrubbed' : ''
            }`}
            style={{
              background: color.light,
              width: calculateElapsedWidth({
                startFrameInFile,
                endFrameInFile,
                startFrameInTimeline,
                elapsed,
                duration,
              }),
            }}
          />
        </div>
      ) : isMiniMap ? null : isAudioLoaded ? (
        <div
          className="FailedContainer"
          onClick={() => dispatch(setModal('MissingAudio'))}
        >
          <img
            src={CannotPlay}
            className="ErrorIcon"
            alt="Failed to Load Audio"
          />
        </div>
      ) : null}
    </div>
  )
}

export default memo(Waveform)
