import React from 'react'
import Tooltip from 'components/base/Tooltip'
import './VisibilityToggle.scss'

export default function VisibilityToggle({ visibility, toggleVisibility }) {
  return (
    <div
      id="VisibilityToggle"
      onClick={toggleVisibility}
      className={visibility ? 'on' : 'off'}
    >
      <div className="Pretext">PUB</div>
      <div id="ToggleNode" />
      <Tooltip
        id="VisibilityToggle"
        position="bottom"
        text="Show Private Collections"
      />
      <div className="Posttext">PRIV</div>
    </div>
  )
}
