import React, { Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuth } from '../providers/AuthProvider/AuthProvider'
import AuthDataProvider from '../providers/AuthProvider'
import NoMatch from 'components/shared/NoMatch'
import AdminCollections from 'components/admin/AdminCollections'
import AdminCollection from 'components/admin/AdminCollection'
import AdminLogin from 'components/admin/AdminLogin'
import AdminNoPermission from 'components/admin/AdminNoPermission'
import Loading from 'components/base/Loading'
import { getCollectionRedirectAdmin } from './utils'
import { parseCollectionFromLocation } from '../utils/common'
import Notifications from 'components/shared/Notifications'
import MetaTags from 'components/public/MetaTags'
import Modal from 'components/base/Modal'
import AdminLanding from 'components/admin/AdminLanding'
import { ErrorBoundary } from '@sentry/react'
import Crash from 'components/shared/Crash'
import { Provider } from 'react-redux'
import store from 'redux/store'
import { useWidth } from 'utils/customHooks'
import { resize } from 'redux/app/appReducer'
import Header from 'components/shared/Header'
import Heading from 'components/base/Heading'
import AdminProject from 'components/admin/AdminProject'

function PrivateRoute({ component: Component, props, ...options }) {
  const { isAuthenticated, isAuthenticating, user } = useAuth()
  const [permissionGranted, setPermissionGranted] = useState(false)

  useEffect(evaluatePermissions, [user, isAuthenticated])

  function evaluatePermissions() {
    if (isAuthenticated && user && user['cognito:groups']) {
      const allowed = user['cognito:groups'].filter(g => g.includes('admin'))
      setPermissionGranted(allowed.length)
    }
  }

  if (!isAuthenticated && !isAuthenticating) {
    if (options.redirectIfUnauthenticated) {
      sessionStorage.setItem('adminRedirectURL', window.location.pathname)
    }
    return <Redirect to="/login" />
  } else if (!isAuthenticated) {
    return <Loading />
  } else if (!permissionGranted) {
    return <AdminNoPermission />
  }

  const collectionRedirect = getCollectionRedirectAdmin()
  if (collectionRedirect) {
    return <Redirect to={collectionRedirect} />
  }

  return (
    <Route {...options}>
      <Modal />
      <Header>
        <Heading size={2}>Spire Admin</Heading>
      </Header>
      <MetaTags />
      <Notifications />
      <Component {...props} />
    </Route>
  )
}

function LegacyRouteRedirect() {
  const redirect = window.location.pathname
    .toLowerCase()
    .replace('collections', 'c')
  return <Redirect to={redirect} />
}

const Routes = () => {
  const location = useLocation()
  const collectionId = parseCollectionFromLocation(location.pathname)
  const [initialLoad, setInitialLoad] = useState(true)
  const width = useWidth()

  useEffect(() => {
    store.dispatch(resize({ width }))
  }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialLoad) {
      // reset all defaults and stop audio on route change
      store.dispatch({ type: 'reset' })
    } else setInitialLoad(false)
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorBoundary fallback={<Crash />}>
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <AuthDataProvider>
            <Helmet>
              <title>Spire Admin | iZotope</title>
              <meta
                name="description"
                content="Sharing is caring - but only if you're an iZotope employee."
              />
            </Helmet>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={AdminLanding}
                redirectIfUnauthenticated={true}
              />
              <Route exact path="/login">
                <AdminLogin />
              </Route>
              <PrivateRoute
                exact
                path="/p/:projectId"
                component={AdminProject}
              />
              <PrivateRoute
                exact
                path="/private/:projectId"
                component={AdminProject}
                props={{ visibility: 'privatePath' }}
              />
              <PrivateRoute
                exact
                path="/public/:projectId"
                component={AdminProject}
                props={{ visibility: 'publicPath' }}
              />
              <PrivateRoute
                exact
                path={['/collections', '/c']}
                component={AdminCollections}
                redirectIfUnauthenticated={false}
              />
              <Route path="/collections/:collectionId">
                <LegacyRouteRedirect />
              </Route>
              <PrivateRoute
                path="/c/:collectionId"
                component={AdminCollection}
                props={{ collectionId }}
                redirectIfUnauthenticated={true}
              />
              <Route>
                <NoMatch origin={window.location.href} />
              </Route>
            </Switch>
          </AuthDataProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  )
}

export { Routes }
