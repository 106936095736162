import { callApi, getCollectionByProjectId } from 'graphql/izo_api'

export async function getCollectionIdFromProjectId(projectId, visibility) {
  try {
    const res = await callApi(getCollectionByProjectId, {
      projectId,
      visibility,
    })
    return res.data.getCollectionByProjectId.id
  } catch (e) {
    console.error('Failed to get collection from projectId:', e)
    return null
  }
}
