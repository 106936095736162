import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { convertGainToBase10 } from 'utils/common'
import Waveform from 'components/base/Waveform'
import Heading from 'components/base/Heading'
import Solo from '../Solo'
import Mute from '../Mute'
import './Track.scss'

function Track({ track, index }) {
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const spireProject = useSelector(store => store.collection.spireProject)
  const solo = useSelector(store => store.audio.solo)
  const view = useSelector(store => store.app.view)
  const clips = useMemo(() => {
    return track.wet_clips && track.wet_clips.length
      ? track.wet_clips
      : track.clips
  }, [track])

  // useMemo here ensures that the color object passed to waveform
  // is consistent between renders, see "Referential Equality"
  // https://kentcdodds.com/blog/usememo-and-usecallback#referential-equality

  return (
    <div
      key={index}
      className={`Track ${
        track.mute || (solo && solo !== track.id) ? 'inactive' : 'active'
      }`}
      id={`Track${index}`}
      style={
        !isAudioLoaded && view === 'clips'
          ? {
              maxHeight: `calc(${Math.floor(
                100 / spireProject.tracks.length
              )}% - 12px)`,
            }
          : null
      }
    >
      {isAudioLoaded ? (
        <div className="TrackHeader">
          <Heading size={4} className="TrackLabel">
            Track {index + 1}
          </Heading>
          <Mute trackId={track.id} on={track.mute} />
          <Solo trackId={track.id} on={solo === track.id} />
        </div>
      ) : null}
      {clips.map(clip => (
        <Waveform
          key={clip.id}
          id={clip.id}
          src={clip.file_name}
          endFrameInFile={clip.end_frame_in_file}
          startFrameInFile={clip.start_frame_in_file}
          startFrameInTimeline={clip.start_frame_in_timeline}
          gain={convertGainToBase10(track.gain)}
          mute={track.mute}
          colorIndex={index}
        />
      ))}
    </div>
  )
}
export default memo(Track, (prevProps, nextProps) => {
  // Because React uses Object.is() equality and will never agree that
  // prevProps.track === nextProps.track, and will thus render too often,
  // we use a custom equality check to make sure we don't re-render the
  // Track component if no changes have been made
  return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
})
