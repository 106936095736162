import Amplify, { Auth } from 'aws-amplify'
import {
  ADMIN,
  APPSYNC_AUTHENTICATION_TYPE,
  APPSYNC_GRAPHQL_ENDPOINT,
  APPSYNC_REGION,
  AUTHENTICATION_FLOW_TYPE,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  OAUTH_DOMAIN,
  OAUTH_REDIRECT_SIGN_IN,
  OAUTH_REDIRECT_SIGN_OUT,
  PROJECT_REGION,
  STORAGE_BUCKET,
  STORAGE_REGION,
  USER_POOLS_ID,
  USER_POOLS_WEB_CLIENT_ID,
} from './constants'

const amplifyConfiguration = {
  aws_appsync_authenticationType: APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_graphqlEndpoint: APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: APPSYNC_REGION,
  COGNITO_IDENTITY_POOL_ID: COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION: COGNITO_REGION,
  aws_project_region: PROJECT_REGION,
  Analytics: { disabled: true },
  API: {
    graphql_headers: async () => {
      try {
        const sess = await Auth.currentSession()
        return { Authorization: sess.getIdToken().getJwtToken() }
      } catch (e) {
        return {}
      }
    },
  },
  Auth: {
    authenticationFlowType: AUTHENTICATION_FLOW_TYPE,
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    identityPoolRegion: COGNITO_REGION,
    mandatorySignIn: false,
    region: PROJECT_REGION,
    userPoolId: USER_POOLS_ID,
    userPoolWebClientId: USER_POOLS_WEB_CLIENT_ID,
  },
  Storage: {
    bucket: STORAGE_BUCKET,
    region: STORAGE_REGION,
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
  },
}

const authConfiguration = {
  mandatorySignIn: ADMIN === 'true' ? true : false,
  oauth: {
    domain: OAUTH_DOMAIN,
    scope: ['email', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: OAUTH_REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
}

try {
  if (ADMIN === true) {
    Amplify.configure({
      ...amplifyConfiguration,
      API: {
        graphql_headers: async () => {
          try {
            const sess = await Auth.currentSession()
            return {
              Authorization: sess.getIdToken().getJwtToken(),
              'x-izo-cloud-client': 'admin-webapp',
            }
          } catch (e) {
            return { 'x-izo-cloud-client': 'admin-webapp' }
          }
        },
      },
    })
  } else {
    Amplify.configure(amplifyConfiguration)
  }
} catch (e) {
  console.error(e)
} finally {
  Auth.configure(authConfiguration)
}
