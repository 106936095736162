import React from 'react'
import Button from 'components/base/Button'
import EnhanceIcon from 'assets/enhance.svg'
import { useSelector, useDispatch } from 'react-redux'
import { toggleEnhance } from 'redux/audio/audioReducer'
import './EnhanceButton.scss'

function EnhanceButton() {
  const files = useSelector(store => store.audio.files)
  const enhance = useSelector(store => store.audio.enhance)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const mobile = useSelector(store => store.app.mobile)
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(toggleEnhance())
  }

  // Display component only if enhanced mix is present
  return files.find(a => a.enhanced) && isAudioLoaded ? (
    <Button
      id={enhance ? 'EnhanceOff' : 'EnhanceOn'}
      type="simple"
      iconLeft={EnhanceIcon}
      onClick={handleClick}
      aria-label={
        enhance ? 'Turn Off Enhanced Audio' : 'Turn On Enhanced Audio'
      }
    >
      {!mobile && 'ENHANCE'}
    </Button>
  ) : null
}

export default EnhanceButton
