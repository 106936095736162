import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import MuteIcon from 'assets/mute.svg'
import { toggleTrackMute } from 'redux/collection/collectionReducer'
import './Mute.scss'

function Mute({ trackId, on }) {
  const dispatch = useDispatch()
  return (
    <button
      className={`MuteButton ${on ? 'on' : 'off'}`}
      id={`Mute-${trackId}`}
      onClick={() => dispatch(toggleTrackMute({ id: trackId }))}
    >
      <img src={MuteIcon} alt="Toggle Mute" />
    </button>
  )
}

export default memo(Mute)
