import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import ProIcon from 'assets/pro.png'
import VocalIcon from 'assets/pro_vocal.png'
import PopFilterIcon from 'assets/pro_pop_filter.png'
import SoundProofingIcon from 'assets/pro_sound_proofing.png'
import SharingIcon from 'assets/sharing.png'
import MicIcon from 'assets/pro_mic.png'
import MoreIcon from 'assets/more.png'
import InspireIcon from 'assets/inspire.png'
import Heading from 'components/base/Heading'
import './SpirePro.scss'

export default function SpirePro() {
  return (
    <div id="SpirePro">
      <Carousel>
        <div>
          <img src={ProIcon} alt="" />
          <Heading size={2}>
            Unlock New Features with <strong>Spire Pro</strong>
          </Heading>
          <p>
            Swipe to see exclusive features unlocked when you subscribe to{' '}
            <strong>Spire Pro</strong> in the iOS app
          </p>
        </div>
        <div>
          <img src={SharingIcon} alt="" />
          <Heading size={2}>Limitless Sharing</Heading>
          <p>Keep your shared cloud projects from expiring</p>
          <aside>Change expiration at share.izotope.com</aside>
        </div>
        <div>
          <img src={SoundProofingIcon} alt="" />
          <Heading size={2}>Noise Removal</Heading>
          <p>Removes unwanted background noise and reverb</p>
          <aside>powered by iZotope RX</aside>
        </div>
        <div>
          <img src={PopFilterIcon} alt="" />
          <Heading size={2}>Pop Filter</Heading>
          <p>Removes unwanted pops and plosives from vocal tracks</p>
          <aside>powered by iZotope RX</aside>
        </div>
        <div>
          <img src={VocalIcon} alt="" />
          <Heading size={2}>Vocal Tuning</Heading>
          <p>Automatically tune your vocals from natural to robotic</p>
          <aside>powered by iZotope Nectar</aside>
        </div>
        <div>
          <img src={MicIcon} alt="" />
          <Heading size={2}>Pro Mic Clarity</Heading>
          <p>Personalizes your Soundcheck for crisp, consistent vocals</p>
          <aside>powered by iZotope Ozone</aside>
        </div>
        <div>
          <img src={InspireIcon} alt="" />
          <Heading size={2}>Inspire Me</Heading>
          <p>Add unique, randomized effects to any of your tracks</p>
        </div>
        <div>
          <img src={MoreIcon} alt="" />
          <Heading size={2}>More to Come</Heading>
          <p>
            Get new, exclusive content and features as soon as they come out
          </p>
          <aside>Keep your app up-to-date for new features</aside>
        </div>
      </Carousel>
      <a
        href="https://spirestudio.app.link/YOqHMeBsgdb"
        target="blank"
        rel="noopener noreferrer"
      >
        <button id="Subscribe">Subscribe in the iOS App</button>
      </a>
    </div>
  )
}
