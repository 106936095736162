import React from 'react'
import './Heading.scss'

export default function Heading({
  size = 1,
  children,
  align = 'left',
  fullWidth = false,
  wrap = false,
  className,
  ...props
}) {
  const HeadingTag = `h${size}`
  return (
    <HeadingTag
      className={`izo_heading${align ? ` ${align}` : ''}${
        fullWidth ? ' fullWidth' : ''
      }${wrap ? ' wrap' : ''}${className ? ` ${className}` : ''}`}
      {...props}
    >
      {children}
    </HeadingTag>
  )
}
