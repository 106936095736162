import { Storage, API } from 'aws-amplify'
import { getSignedUrlFromS3Key } from 'utils/common'
import { STORAGE_BUCKET, STORAGE_REGION } from 'utils/constants'
import { callApi, createStem, createFile } from 'graphql/izo_api'
import { generateVisibilityPrefix } from 'utils/common'

export async function openInS3(
  collectionId,
  identityId,
  visibility = 'publicPath'
) {
  const prefix = await generateVisibilityPrefix(visibility, identityId)
  const url = `https://s3.console.aws.amazon.com/s3/buckets/${STORAGE_BUCKET}/${prefix}/collections/${collectionId}/?region=us-east-1`
  window.open(url, '_blank')
}

export const createS3Key = (collectionId, file) => {
  const ext = getFileExtension(file)
  switch (ext) {
    case 'spire':
      return `collections/${collectionId}/original/${file.name}`
    case 'flac':
      return `collections/${collectionId}/stems/${file.name}`
    default:
      throw new Error('Unsupported File Type:', ext)
  }
}

export const getFileExtension = file => file.name.split('.').pop()

export async function downloadFile(file) {
  try {
    if (file.src) window.location = file.src
    else {
      const link = await getSignedUrlFromS3Key(file.key)
      window.location = link
    }
  } catch (e) {
    console.error(e)
  }
}

export async function createNewStemRecord(collectionId, file, key) {
  try {
    await callApi(createStem, {
      input: {
        id: collectionId,
        original: {
          bucket: STORAGE_BUCKET,
          region: STORAGE_REGION,
          key,
          name: file.name,
        },
        visibility: 'publicPath',
      },
    })
    return
  } catch (e) {
    console.error(e)
  }
}

export async function createNewSpireFile(collectionId, file, key) {
  try {
    await API.graphql({
      query: createFile,
      variables: {
        input: {
          id: collectionId,
          original: {
            bucket: STORAGE_BUCKET,
            region: STORAGE_REGION,
            key,
            name: file.name,
          },
          visibility: 'publicPath',
        },
      },
      authMode: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    })
    return
  } catch (e) {
    console.error(e)
  }
}

export async function uploadFile(key, file, progressCallback) {
  return await Storage.put(key, file, {
    level: 'public',
    contentType: file.type,
    progressCallback,
  })
}
