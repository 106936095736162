import React from 'react'
import './ProLabel.scss'

export default function ProLabel({ style }) {
  return (
    <div className="ProLabel" style={style}>
      PRO
    </div>
  )
}
