import React from 'react'
import UserIcon from 'assets/user.svg'
import Heading from 'components/base/Heading'
import './ProfileIcon.scss'

export default function ProfileIcon({ profile = {}, size, border }) {
  return (
    <div
      className={`ProfileIcon${border ? ' withBorder' : ''}${
        profile.displayIcon ? ' withIcon' : ''
      }`}
      style={size ? { height: size, width: size } : null}
    >
      {profile && profile.displayIcon ? (
        <img src={profile.displayIcon} alt="" />
      ) : profile && profile.displayName ? (
        <Heading size={4} style={{ fontSize: size * 0.6 }}>
          {profile.displayName.substring(0, 1)}
        </Heading>
      ) : (
        <img src={UserIcon} alt="" />
      )}
    </div>
  )
}
