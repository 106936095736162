import React, { useEffect, useState } from 'react'
import { log } from 'redux/analytics/analyticsReducer'
import { callApi, updateUserProfile } from 'graphql/izo_api'
import { getKeyFromUrl, isSignedUrl, generateS3Key, uploadFile } from './utils'
import { useAuth } from 'providers/AuthProvider'
import { Storage } from 'aws-amplify'
import Dropzone from 'components/base/Dropzone'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/base/Input'
import Heading from 'components/base/Heading'
import './EditUserProfile.scss'
import Button from 'components/base/Button'

const UPLOAD_SIZE_LIMIT = 500
const MB_CONVERSION_CONST = 1048576
const ACCEPTED_UPLOAD_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/svg',
]

export default function EditUserProfile({ close }) {
  const mobile = useSelector(store => store.app.mobile)
  const { user, profile, updateProfile } = useAuth()
  const [displayName, setDisplayName] = useState('')
  const [bio, setBio] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [links, setLinks] = useState([])
  const [displayIcon, setDisplayIcon] = useState(null)
  const [displayIconSrc, setDisplayIconSrc] = useState()
  const [working, setWorking] = useState(false)
  const [buttonText, setButtonText] = useState(profile ? 'Update' : 'Submit')
  const [fileError, setFileError] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(log({ event: 'userProfileModalLoad' }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (profile && profile.displayName) {
      setDisplayName(profile.displayName)
    } else if (user && user.name) {
      setDisplayName(user.name)
    }
    if (profile && profile.displayIcon) {
      setDisplayIcon(getKeyFromUrl(profile.displayIcon))
    }
    if (profile && profile.bio) {
      setBio(profile.bio)
    }
    if (profile && profile.city) {
      setCity(profile.city)
    }
    if (profile && profile.country) {
      setCountry(profile.country)
    }
    if (profile && profile.links) {
      setLinks(JSON.parse(profile.links))
    }
  }, [user, profile])

  useEffect(() => {
    if (displayIcon) {
      if (!isSignedUrl(displayIcon)) {
        Storage.get(displayIcon, { expires: 60 * 60 }).then(url => {
          setDisplayIconSrc(url)
        })
      } else setDisplayIconSrc(displayIcon)
    }
  }, [displayIcon])

  async function handleSubmit() {
    if (displayName.length < 2) {
      setFileError('Whoops! Display name is too short.')
    } else if (!isValid(displayName)) {
      setFileError("Whoops! Display name can't contain special characters.")
    } else {
      let linksString = null
      if (links.length) {
        const validated = []
        links.forEach(l => {
          if (l.name.length && l.url.length) {
            validated.push({
              name: l.name,
              url: l.url.indexOf('://') === -1 ? 'http://' + l.url : l.url,
            })
          }
        })
        linksString = JSON.stringify(validated)
      }
      const input = {
        userId: user.sub,
        displayName,
        displayIcon,
        bio,
        city,
        country,
        links: linksString,
      }
      if (!working) {
        setWorking(true)
        setButtonText('⌛ Updating...')
        handleUpdate(input)
      }
    }
  }

  function isValid(str) {
    return !/[~!#$%^&*+=[\]\\;,/{}|\\":<>?]/g.test(str)
  }

  async function handleUpdate(input) {
    try {
      const res = await callApi(updateUserProfile, { input })
      updateProfile(res.data.updateUserProfile)
      dispatch(log({ event: 'updateUserProfile' }))
      setWorking(false)
      setButtonText('✅ Updated!')
      setTimeout(() => close(), 1000)
    } catch (e) {
      setButtonText('❌ Error updating!')
      console.error('Error updating user profile:', e)
    }
  }

  function handleFile(fileArr) {
    const file = fileArr[0]
    setFileError(false)
    const sizeInMB = file.size / MB_CONVERSION_CONST
    if (!ACCEPTED_UPLOAD_TYPES.includes(file.type)) {
      setFileError("Whoops! That's an unsupported file type.")
    } else if (sizeInMB > UPLOAD_SIZE_LIMIT) {
      setFileError("Whoops! Try a file that's less than 500MB.")
    } else handleFileUpload(file)
  }

  async function handleFileUpload(file) {
    try {
      const key = generateS3Key(file, user.sub)
      await uploadFile(key, file)
      setDisplayIcon(key)
    } catch (e) {
      console.error(e)
      setFileError('Whoops! Error uploading your file, please try again.')
    }
  }

  function handleChangeDisplayName(e) {
    if (fileError && fileError.includes('Display name')) setFileError(null)
    setDisplayName(e.target.value)
  }

  function addLink() {
    setLinks([...links, { name: '', url: '' }])
  }

  function updateLink(index, field, value) {
    const updated = JSON.parse(JSON.stringify(links))
    updated[index][field] = value
    setLinks(updated)
  }

  function deleteLink(index) {
    const updated = JSON.parse(JSON.stringify(links))
    updated.splice(index, 1)
    setLinks(updated)
  }

  return (
    <div id="EditUserProfile">
      <Heading size={3}>Edit Profile</Heading>
      <p>
        Control how you will appear to other Spire users. Your personal
        information is always kept private.
      </p>
      <div className="ModalBody">
        {displayIconSrc ? (
          <div className="HiddenDropzone">
            <img id="DisplayIcon" src={displayIconSrc} alt="" />
            <Dropzone
              handler={handleFile}
              header="Drag & Drop Your Photo"
              accept=".png, .svg, .jpg, .jpeg"
              body="Update Photo"
            />
          </div>
        ) : (
          <div id="DropzoneContainer">
            <Dropzone
              handler={handleFile}
              header={
                mobile ? 'Tap Here to Select a Photo' : 'Drag & Drop Your Photo'
              }
              accept=".png, .svg, .jpg, .jpeg"
              body={mobile ? '' : 'or Select a File to Upload'}
            />
          </div>
        )}
        <div className="UserInputs">
          <Input
            value={displayName}
            onChange={handleChangeDisplayName}
            label="Display Name..."
          />
          <Input
            type="textarea"
            value={bio}
            onChange={e => setBio(e.target.value)}
            label="Bio..."
          />
          <div className="Location">
            <Input
              value={city}
              onChange={e => setCity(e.target.value)}
              label="City"
            />
            <Input
              value={country}
              onChange={e => setCountry(e.target.value)}
              label="Country"
            />
          </div>
          {links.length ? (
            <>
              <Heading size={6}>Website Links</Heading>
              {links.map((l, i) => (
                <div className="Link" key={i}>
                  <Input
                    value={l.name}
                    onChange={e => updateLink(i, 'name', e.target.value)}
                    label="Name"
                  />
                  <Input
                    value={l.url}
                    onChange={e => updateLink(i, 'url', e.target.value)}
                    label="Address"
                  />
                  <i className="fa fa-trash" onClick={() => deleteLink(i)} />
                </div>
              ))}
            </>
          ) : null}
          <button className="AddLinkButton" onClick={addLink}>
            Add a Website Link
          </button>

          {fileError && <div className="Error">{fileError}</div>}
        </div>
      </div>
      <div className="FooterButtons">
        <Button type="tertiary" onClick={close}>
          cancel
        </Button>
        <Button type="secondary" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
