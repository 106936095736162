import { useEffect, useState, useRef } from 'react'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function useWidth() {
  const [width, setWidth] = useState(window.innerWidth)
  let debounce_timer

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  })

  function updateWidth() {
    debounce_timer && clearTimeout(debounce_timer)
    debounce_timer = setTimeout(() => {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth)
      }
    }, 100)
  }

  return width
}

export { useWidth, usePrevious }
