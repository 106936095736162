import { createSlice } from '@reduxjs/toolkit'

const app = createSlice({
  name: 'analytics',
  initialState: { events: [] },
  reducers: {
    log(state, action) {
      return {
        ...state,
        events: [...state.events, action.payload],
      }
    },
  },
})

export const { log } = app.actions

export default app.reducer
