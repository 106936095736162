import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import moment from 'moment'
import { addSignedUrlsToComments, labsCommentModalState } from 'utils/common'
import { updateComments } from 'redux/collection/collectionReducer'
import { setAppState, setModal } from 'redux/app/appReducer'
import { log } from 'redux/analytics/analyticsReducer'
import { callApi, createComment } from 'graphql/izo_api'
import { useAuth } from 'providers/AuthProvider'
import EmojiIcon from 'assets/emoji.png'
import Note from 'assets/note.svg'
import ProfileIcon from 'components/base/ProfileIcon'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import Input from 'components/base/Input'
import './AddComment.scss'
import { getPlayheadPosition } from './utils'

function AddComment() {
  const collectionId = useSelector(store => store.collection.collectionId)
  const projectId = useSelector(store => store.collection.projectId)
  const preventComments = useSelector(store => store.collection.preventComments)
  const mobile = useSelector(store => store.app.mobile)
  const scrubbed = useSelector(store => store.audio.scrubbed)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const dispatch = useDispatch()
  const { user, profile } = useAuth()
  const [active, setActive] = useState(false)
  const [comment, setComment] = useState('')
  const [timestamp, setTimestamp] = useState()
  const [emojis, setEmojis] = useState(false)

  useEffect(() => setEmojis(false), [active])

  useEffect(() => {
    if (scrubbed) {
      setTimestamp(getPlayheadPosition())
    }
  }, [scrubbed])

  function handleClick() {
    if (!user) {
      dispatch(
        setAppState({ modal: 'SpireLabs', modalState: labsCommentModalState })
      )
      dispatch(log({ event: 'createCommentAttempt' }))
      return
    } else {
      dispatch(log({ event: active ? 'closeNewComment' : 'openNewComment' }))
      setActive(!active)
      setTimestamp(getPlayheadPosition())
    }
  }

  async function handleSubmit() {
    try {
      setActive(false)
      if (comment.length) {
        const input = {
          comment,
          collectionId,
          projectId,
          userId: user.sub,
          timestamp,
        }
        const res = await callApi(createComment, { input })
        const updated = await addSignedUrlsToComments(
          res.data.createComment.comments
        )
        dispatch(updateComments(updated))
        const newComment = res.data.createComment.comments.find(
          c => c.comment === comment && c.timestamp === timestamp
        )
        if (newComment) {
          dispatch(
            log({
              event: 'createCommentSuccess',
              params: { commentId: newComment.commentId },
            })
          )
        }
        setComment('')
      }
    } catch (e) {
      console.error('Error adding comment:', e)
      dispatch(log({ event: 'createCommentFailure' }))
    }
  }

  function handleKeyDown(e) {
    if (e.target.id === 'CommentInput' && e.keyCode === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }

  function handleEmojiClick(event) {
    setComment(comment + event.native)
    setEmojis(false)
  }

  if (!projectId) return null

  const commentingAt = moment
    .utc(moment.duration(timestamp, 'seconds').asMilliseconds())
    .format('m:ss')

  if (preventComments || !isAudioLoaded) return null

  return active ? (
    <section id="NewComment">
      <Heading size={4}>Add a Comment</Heading>
      <Button type="close" onClick={handleClick} />
      <p>Commenting at {commentingAt}</p>
      <div id="ProfileWrapper">
        <ProfileIcon profile={profile} size={24} />
        <Heading size={5}>{profile.displayName}</Heading>
        <Button
          type="tertiary"
          onClick={() => dispatch(setModal('EditUserProfile'))}
        >
          Edit
        </Button>
      </div>
      <div id="InputWrapper">
        <Input
          id="CommentInput"
          autoFocus
          rows={3}
          autoComplete="off"
          type="textarea"
          value={comment}
          onChange={e => setComment(e.target.value)}
          onKeyDown={handleKeyDown}
          label="Your comment..."
          maxLength={140}
        />
        {!mobile && (
          <Button
            type="simple"
            icon={EmojiIcon}
            onClick={() => setEmojis(!emojis)}
          />
        )}
      </div>
      {emojis && (
        <div id="EmojiWrapper" className={emojis ? 'active' : 'inactive'}>
          <Picker
            set="apple"
            onSelect={handleEmojiClick}
            perLine={8}
            theme="dark"
            emojiSize={18}
            showPreview={false}
          />
        </div>
      )}
      <Button type="primary" onClick={handleSubmit} fullWidth>
        Add Comment
      </Button>
    </section>
  ) : (
    <Button id="AddComment" type="simple" iconLeft={Note} onClick={handleClick}>
      {mobile ? null : 'COMMENT'}
    </Button>
  )
}

export default AddComment
