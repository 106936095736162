import React, { memo } from 'react'
import Reaction, { AddReaction } from '../../Reaction'
import ProfileIcon from 'components/base/ProfileIcon'
import Button from 'components/base/Button'
import Heading from 'components/base/Heading'
import moment from 'moment'
import { getReactionUsers, removeDuplicateReactions } from '../utils'
import { useSelector, useDispatch } from 'react-redux'
import { setAppState, setMenu } from 'redux/app/appReducer'
import { scrub } from 'redux/audio/audioReducer'
import { log } from 'redux/analytics/analyticsReducer'
import { useAuth } from 'providers/AuthProvider'
import './TimelineComment.scss'
import { useWidth } from 'utils/customHooks'

function TimelineComment({
  comment,
  active,
  setActive,
  clearDebounce,
  handleReaction,
  handleDeleteComment,
}) {
  const { user } = useAuth()
  const duration = useSelector(store => store.audio.duration)
  const mobile = useSelector(store => store.app.mobile)
  const menu = useSelector(store => store.app.menu)
  const dispatch = useDispatch()
  const width = useWidth()
  const isCollectionOwner = useSelector(
    store => store.collection.isCollectionOwner
  )
  const isCommentOwner = user && comment.user.userId === user.sub

  const getCommentPosition = () => {
    const padding = mobile ? 32 : 128
    const anchorWidth = 34
    const commentWidth = 240
    const parentWidth = width - padding
    const maxLeftActive = 1 - commentWidth / parentWidth
    const maxLeftNotActive = 1 - anchorWidth / parentWidth
    const nonShifted =
      parentWidth * Math.min(maxLeftNotActive, comment.timestamp / duration)
    const shifted =
      parentWidth * Math.min(maxLeftActive, comment.timestamp / duration)
    return active ? shifted : nonShifted
  }

  const handleClick = () => {
    clearDebounce()
    if (!active) {
      setActive(comment.commentId)
      if (menu) dispatch(setMenu(false))
    }
    dispatch(
      log({
        event: 'openTimelineComment',
        params: { commentId: comment.commentId },
      })
    )
  }

  const handleClose = (e, id) => {
    e.stopPropagation()
    setActive(null)
    dispatch(log({ event: 'closeTimelineComment', params: { commentId: id } }))
  }

  const handleOpenOwner = owner => {
    dispatch(
      setAppState({
        modal: 'PublicUserProfile',
        modalState: { user: owner },
      })
    )
  }

  const parseTime = time => moment.utc(time * 1000).format('m:ss')

  function Anchor() {
    return (
      <div className="Anchor" onClick={() => setActive(!active)}>
        <ProfileIcon size={36} profile={comment.user} />
      </div>
    )
  }

  function Body() {
    return (
      <div className={`Body ${active ? 'active' : 'inactive'}`}>
        <section id="CommentUser">
          <ProfileIcon size={32} profile={comment.user} />
          <aside>
            <Heading size={5} onClick={() => handleOpenOwner(comment.user)}>
              {comment.user.displayName}
            </Heading>
            <p onClick={() => dispatch(scrub(comment.timestamp))}>
              @{parseTime(comment.timestamp)}
            </p>
          </aside>
          <Button type="close" onClick={handleClose} />
        </section>
        <p id="CommentContent">{comment.comment}</p>
        <div id="CommentReactions">
          {removeDuplicateReactions(comment.reactions).map((reaction, i) => (
            <Reaction
              key={i}
              emoji={reaction.emoji}
              users={getReactionUsers(comment, reaction.emoji)}
              onClick={e => handleReaction(e, comment, reaction.emoji)}
            />
          ))}
          <AddReaction
            handleReaction={emoji => handleReaction(null, comment, emoji)}
            parentActive={active}
          />
          {(isCollectionOwner || isCommentOwner) && (
            <Button
              type="simple"
              className="Delete"
              preventHoverEffects
              onClick={e => handleDeleteComment(e, comment.commentId)}
            >
              <i className="fa fa-trash" />
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className="TimelineComment"
      style={{ left: getCommentPosition() }}
      onClick={handleClick}
    >
      {Anchor()}
      {active ? Body() : null}
    </div>
  )
}

export default memo(TimelineComment, (prevProps, nextProps) => {
  return (
    // React by default uses Object.is() shallow equality checks
    // Because the `comment` prop is an object, it will never equal itself,
    // and this component will think that each render has new props, making memo() useless.
    // we only care if `active` or the `comment` prop have changed, so we create our own
    // custom comparison function using JSON.stringify to see if the new `comment` prop
    // is actually different, or really equal, preventing re-rendering the comment constantly
    JSON.stringify(prevProps.comment) === JSON.stringify(nextProps.comment) &&
    prevProps.active === nextProps.active
  )
})
