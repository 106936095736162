import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { log } from 'redux/analytics/analyticsReducer'
import Oops from 'assets/oops.svg'
import Header from '../Header'
import Heading from 'components/base/Heading'
import './NoMatch.scss'
import { ADMIN } from 'utils/constants'
import Button from 'components/base/Button'
import ExternalLink from 'components/base/ExternalLink'

const supportUrl = 'https://support.izotope.com/hc/en-us/requests/new'

export default function NoMatch({ origin }) {
  const dispatch = useDispatch()

  useEffect(() => {
    Sentry.captureMessage(`404: ${origin}`)
    dispatch(log({ event: 'noMatchPageLoad' }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const SupportButton = ({ text = 'Need Help?' }) => (
    <ExternalLink to={supportUrl}>
      <Button type="primary">{text}</Button>
    </ExternalLink>
  )

  const HomeButton = ({ text = 'View your shared projects' }) => (
    <Link to="/">
      <Button type="primary">{text}</Button>
    </Link>
  )

  return (
    <div id="NoMatch">
      {!ADMIN && <Header />}
      <img src={Oops} alt="No projects found" />
      <Heading size={3}>😭 404 😭</Heading>
      <p>The page you're looking for has moved, or does not exist.</p>
      <div id="ErrorButtons">
        <HomeButton />
        <SupportButton />
      </div>
    </div>
  )
}
