import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSource } from 'redux/audio/audioReducer'
import { fetch } from 'redux/collection/collectionReducer'
import { callApi, unpackSpireProject } from 'graphql/izo_api'
import { setAppState } from 'redux/app/appReducer'
import AdminAudioViews from 'components/admin/AdminAudioViews'
import Ellipsis from 'components/base/Ellipsis'
import Loading from 'components/base/Loading'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import './AdminClips.scss'

export default function AdminClips() {
  const [unpacking, setUnpacking] = useState('Unpack Project')
  const spireFile = useSelector(store => store.collection.spireFile)
  const spireProject = useSelector(store => store.collection.spireProject)
  const collectionId = useSelector(store => store.collection.collectionId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSource('spireProject'))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleUnpack() {
    setUnpacking('⏳ Unpacking Project')
    try {
      await callApi(unpackSpireProject, {
        input: {
          collectionId,
          key: spireFile.key.replace('public/', ''),
        },
      })
      dispatch(fetch())
    } catch (e) {
      setUnpacking('❌ Failed to unpack project.')
      console.error(e)
    }
  }

  function handleSelectClip(e, clip) {
    dispatch(setAppState({ modal: 'RepairCompare', modalState: { clip } }))
  }

  return (
    <div id="AdminClips">
      {spireProject ? (
        <AdminAudioViews
          style={{ height: `calc(100% - 96px)` }}
          waveformClickHandler={handleSelectClip}
        />
      ) : (
        <div className="FlexCenter FlexColumn" style={{ height: '100%' }}>
          {spireFile ? (
            <>
              <Heading size={4} align="center">
                Project is Not Unpacked!
              </Heading>
              <br />
              <Button type="secondary" onClick={handleUnpack}>
                {unpacking}
                {unpacking.includes('ing') && <Ellipsis />}
              </Button>
            </>
          ) : spireProject === null ? (
            <Heading size={4}>Project has no clip files.</Heading>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  )
}
