import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app/appReducer'
import appHandler from './app/appHandler'
import authReducer from './auth/authReducer'
import authHandler from './auth/authHandler'
import audioReducer from './audio/audioReducer'
import audioHandler from './audio/audioHandler'
import collectionReducer from './collection/collectionReducer'
import collectionHandler from './collection/collectionHandler'
import analyticsHandler from './analytics/analyticsHandler'
import analyticsReducer from './analytics/analyticsReducer'

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    audio: audioReducer,
    analytics: analyticsReducer,
    collection: collectionReducer,
  },
  middleware: [
    appHandler,
    authHandler,
    audioHandler,
    analyticsHandler,
    collectionHandler,
  ],
})

export default store
