import React from 'react'
import { publicLinkToCollection } from 'utils/common'
import LoadingAnimation from 'assets/loading.json'
import Oops from 'assets/oops.svg'
import Lottie from 'react-lottie'
import Ellipsis from 'components/base/Ellipsis'
import { useHistory } from 'react-router'
import Heading from 'components/base/Heading'
import './CollectionsTable.scss'
import Button from 'components/base/Button'

export const PublicLink = collection_id => {
  const publicUrl = publicLinkToCollection(collection_id)
  if (publicUrl !== '') {
    return (
      <div onClick={e => e.stopPropagation()}>
        <a href={publicUrl} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-external-link-alt" />
        </a>
      </div>
    )
  } else {
    return <div>n/a</div>
  }
}

const CollectionsTable = ({
  collections,
  status,
  handleClear,
  searchValue,
}) => {
  const history = useHistory()

  function parseEpochDate(epoch) {
    const date = new Date(0)
    date.setUTCSeconds(epoch)
    return date.toString().substring(0, 24)
  }

  function parseDate(dt) {
    const date = new Date(dt)
    return date.toString().substring(0, 24)
  }

  function calcHeight(id) {
    const el = document.getElementById(id)
    return el
      ? window.innerHeight -
          (window.pageYOffset + el.getBoundingClientRect().top)
      : null
  }

  if (status === 'loading') {
    return (
      <div id="CollectionsTable">
        <Lottie options={LoadingAnimation} height={200} width={264} />
        <Heading size={4}>
          Loading Collections
          <Ellipsis />
        </Heading>
      </div>
    )
  } else if (status === 'error') {
    return (
      <div id="CollectionsTable">
        <Lottie options={LoadingAnimation} height={200} width={264} />
        <Heading size={4}>Transfer+ encountered an error.</Heading>
        <br />
        <Button onClick={() => window.location.reload()}>Reload Page</Button>
      </div>
    )
  } else if (!collections || !collections.length) {
    return (
      <div id="CollectionsTable">
        <img src={Oops} alt="Oops!" />
        <Heading size={2}>No Collections Found</Heading>
        <p>
          No collections match your search <strong>"{searchValue}"</strong>
        </p>
        <p>
          <strong>
            REMEMBER: search only supports exact match and is case sensitive!
          </strong>
        </p>
        <br />
        <Button onClick={handleClear}>Clear Search</Button>
      </div>
    )
  } else
    return (
      <div
        id="CollectionsTable"
        style={{ height: `${calcHeight('CollectionsTable')}px` }}
      >
        <table width="100%">
          <thead>
            <tr>
              <th>Title</th>
              <th>Visibility</th>
              <th>Created</th>
              <th>Expires</th>
              <th>Public Link</th>
            </tr>
          </thead>
          <tbody>
            {collections.map(c => (
              <tr key={c.id} onClick={() => history.push(`/c/${c.id}`)}>
                <td>
                  <Heading size={5}>{c.title}</Heading>
                </td>
                <td>{c.visibility || 'publicPath'}</td>
                <td>{parseDate(c.createdAt)}</td>
                <td>{parseEpochDate(c.expirationEpoch)}</td>
                <td>{PublicLink(c.id)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
}

export default CollectionsTable
