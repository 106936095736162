export const OAUTH_REDIRECT_SIGN_IN =
  process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN
export const OAUTH_REDIRECT_SIGN_OUT =
  process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT
export const OAUTH_DOMAIN = process.env.REACT_APP_OAUTH_DOMAIN
export const AUTHENTICATION_FLOW_TYPE =
  process.env.REACT_APP_AUTH_AUTHENTICATION_FLOW_TYPE
export const USER_POOLS_ID = process.env.REACT_APP_USER_POOLS_ID
export const USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID
export const APPSYNC_REGION = process.env.REACT_APP_AWS_APPSYNC_REGION
export const APPSYNC_GRAPHQL_ENDPOINT =
  process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT
export const APPSYNC_AUTHENTICATION_TYPE =
  process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
export const PROJECT_REGION = process.env.REACT_APP_PROJECT_REGION
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
export const COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION
export const STORAGE_BUCKET =
  process.env.REACT_APP_STORAGE_BUCKET || 'transferplus-storage-dev'
export const STORAGE_REGION =
  process.env.REACT_APP_STORAGE_REGION || 'us-east-1'
export const BASE_URL =
  process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN || 'https://share.izotope.com'
export const ADMIN = process.env.REACT_APP_ADMIN === 'true' ? true : false
export const FEATURE_FLAG_PUBLIC_LOGIN =
  process.env.REACT_APP_FEATURE_FLAG_PUBLIC_LOGIN === 'true' ? true : false
export const FEATURE_FLAG_APPLE_LOGIN =
  process.env.REACT_APP_FEATURE_FLAG_APPLE_LOGIN === 'true' ? true : false
export const FEATURE_FLAG_FACEBOOK_LOGIN =
  process.env.REACT_APP_FEATURE_FLAG_FACEBOOK_LOGIN === 'true' ? true : false
export const FEATURE_FLAG_PRO_PAYWALL =
  process.env.REACT_APP_FEATURE_FLAG_PRO_PAYWALL === 'true' ? true : false
export const FEATURE_FLAG_PRIVATE_PROJECT_LIST =
  process.env.REACT_APP_FEATURE_FLAG_PRIVATE_PROJECT_LIST === 'true'
    ? true
    : false
export const FEATURE_FLAG_STEREOIZE =
  process.env.REACT_APP_FEATURE_FLAG_STEREOIZE === 'true' ? true : false
export const DEFAULT_AUTH_MODE =
  process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
export const LABS_USER_GROUP =
  process.env.REACT_APP_LABS_USER_GROUP || 'transferplus-public-spire-labs'
export const SUPPORTED_ENHANCE_FORMAT_EXTENSIONS = ['.spire']
export const SUPPORTED_FILE_UPLOAD_EXTENSIONS = ['.spire']
export const SUPPORTED_PREVIEW_FORMAT_EXTENSIONS = [
  '.wav',
  '.mp3',
  '.spire',
  '.flac',
]
export const SUPPORTED_STEM_FORMAT_EXTENSIONS = [
  '.wav',
  '.mp3',
  '.zip',
  '.flac',
]
export const SUPPORTED_STEM_PREVIEW_FORMAT_EXTENSIONS = ['.mp3']
export const SUPPORTED_STEM_UPLOAD_EXTENSIONS = ['.flac']
export const DEFAULT_PAN = 0
export const DEFAULT_GAIN = 1
export const DEFAULT_STEREOIZE = false
export const DEFAULT_STEREOIZE_PAN = 0.35
export const DEFAULT_STEREOIZE_DELAY = 0.005

export const TIME_SIGNATURE_LOOKUP = {
  '4/4': { beats: 4, divisions: 4 },
  '3/4': { beats: 3, divisions: 4 },
  '6/8': { beats: 6, divisions: 8 },
  '1/4': { beats: 1, divisions: 4 },
}

export const MOBILE_THRESHOLD = 600
export const SAMPLE_RATE = 48000
export const WAVEFORM_FIDELITY = 0.12
export const NATIVE_APP_BASE_URL = 'https://spirestudio.app.link/fCUm8MFDtW'
