import { setSource } from 'redux/audio/audioReducer'
import { log } from '../analytics/analyticsReducer'

const appHandler = storeAPI => next => action => {
  switch (action.type) {
    case 'app/setMenu':
      handleMenu(storeAPI, next, action)
      break
    case 'app/setModal':
      handleModal(storeAPI, next, action)
      break
    case 'app/setView':
      handleView(storeAPI, next, action)
      break
    case 'reset':
      handleReset(storeAPI, next, action)
      break
    default:
      return next(action)
  }
}

function handleModal(storeAPI, next, action) {
  const res = next(action)
  storeAPI.dispatch(log({ event: 'modalUpdate' }))
  return res
}

function handleMenu(storeAPI, next, action) {
  const res = next(action)
  storeAPI.dispatch(log({ event: 'sidebarMenuUpdate' }))
  return res
}

function handleView(storeAPI, next, action) {
  if (action.payload === 'clips') {
    const { enhance } = storeAPI.getState().audio
    if (enhance) {
      storeAPI.dispatch(setSource('spireProject'))
    }
  }
  return next(action)
}

function handleReset(storeAPI, next, action) {
  storeAPI.dispatch({ type: 'audio/reset' })
  storeAPI.dispatch({ type: 'collection/reset' })
  return next({ type: 'app/reset' })
}

export default appHandler
