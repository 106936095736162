/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkDspStatus = /* GraphQL */ `
  query CheckDspStatus($id: ID!) {
    checkDSPStatus(id: $id) {
      status
      keyList
      metadata
    }
  }
`;
export const getSyncedProject = /* GraphQL */ `
  query GetSyncedProject($id: ID!) {
    getSyncedProject(id: $id) {
      id
      clientId
      userId
      status
      resolvedState
      serverState
    }
  }
`;
export const getCollectionById = /* GraphQL */ `
  query GetCollectionById($id: ID!) {
    getCollectionById(id: $id) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const getCollectionByProjectId = /* GraphQL */ `
  query GetCollectionByProjectId($projectId: String!, $visibility: String) {
    getCollectionByProjectId(projectId: $projectId, visibility: $visibility) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const listCollectionsByProjectId = /* GraphQL */ `
  query ListCollectionsByProjectId($projectId: String!) {
    listCollectionsByProjectId(projectId: $projectId) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listCollectionsBySearchParameter = /* GraphQL */ `
  query ListCollectionsBySearchParameter($input: SearchParameterInput!) {
    listCollectionsBySearchParameter(input: $input) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listCollectionsForCurrentUser = /* GraphQL */ `
  query ListCollectionsForCurrentUser(
    $visibility: Visibility!
    $limit: Int
    $nextToken: String
  ) {
    listCollectionsForCurrentUser(
      visibility: $visibility
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectId
        identityId
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        visibility
        title
        createdAt
        updatedAt
        deleted
        suppressExpiration
        expirationEpoch
      }
      nextToken
    }
  }
`;
export const getPublicUserProfileById = /* GraphQL */ `
  query GetPublicUserProfileById($userId: ID!) {
    getPublicUserProfileById(userId: $userId) {
      userId
      displayName
      displayIcon
      bio
      city
      country
      updatedAt
      createdAt
      links
      isProSubscriber
    }
  }
`;
export const getNewUsersByDateRange = /* GraphQL */ `
  query GetNewUsersByDateRange($startDate: String, $endDate: String) {
    getNewUsersByDateRange(startDate: $startDate, endDate: $endDate) {
      userId
      displayName
      displayIcon
      bio
      city
      country
      updatedAt
      createdAt
      links
      isProSubscriber
    }
  }
`;
export const getOfferCodes = /* GraphQL */ `
  query GetOfferCodes {
    getOfferCodes {
      items {
        offerCode
        createdAt
        softExpiration
        hardExpiration
        useBy
        redeemed
      }
      nextToken
    }
  }
`;
export const getUserEmailOptIn = /* GraphQL */ `
  query GetUserEmailOptIn {
    getUserEmailOptIn
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const getMixdown = /* GraphQL */ `
  query GetMixdown($id: ID!, $sortKey: String!) {
    getMixdown(id: $id, sortKey: $sortKey) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const getAllProcessById = /* GraphQL */ `
  query GetAllProcessById($id: String!, $tag: ID) {
    getAllProcessById(id: $id, tag: $tag) {
      id
      items {
        id
        opInfo
      }
    }
  }
`;
export const listClipsInCollection = /* GraphQL */ `
  query ListClipsInCollection($collectionId: ID!, $s3Key: String) {
    listClipsInCollection(collectionId: $collectionId, s3Key: $s3Key) {
      id
      file_name
      file_duration_frames
      end_frame_in_file
      sample_rate
      start_frame_in_file
      start_frame_in_timeline
      flac_file {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      mp3_file {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      track_id
      pan
      gain
      mute
      stereoize
      color_index
      flac_key
      mp3_key
      instrumentID
    }
  }
`;
export const getSpireProjectForCollection = /* GraphQL */ `
  query GetSpireProjectForCollection($collectionId: ID!, $s3Key: String) {
    getSpireProjectForCollection(collectionId: $collectionId, s3Key: $s3Key) {
      id
      project_file_version
      duration
      dirty
      tempo
      modified
      time_signature {
        beats
        divisions
      }
      tracks {
        id
        gain
        mute
        pan
        stereoize
        steroize
        color_index
        clips {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        wet_clips {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        effects {
          name
          params
        }
      }
      metronome_on
      created
      num_tracks
      name
      syncState
    }
  }
`;
export const listFilesInCollection = /* GraphQL */ `
  query ListFilesInCollection($id: ID!, $limit: Int, $nextToken: String) {
    listFilesInCollection(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listStemsInCollection = /* GraphQL */ `
  query ListStemsInCollection($id: ID!, $limit: Int, $nextToken: String) {
    listStemsInCollection(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listMixdownsInCollection = /* GraphQL */ `
  query ListMixdownsInCollection($id: ID!, $limit: Int, $nextToken: String) {
    listMixdownsInCollection(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listCollectionsByUser = /* GraphQL */ `
  query ListCollectionsByUser($limit: Int, $nextToken: String) {
    listCollectionsByUser(limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listCollectionsByTitle = /* GraphQL */ `
  query ListCollectionsByTitle(
    $title: String!
    $includeExpired: Boolean
    $nextToken: String
  ) {
    listCollectionsByTitle(
      title: $title
      includeExpired: $includeExpired
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listCollectionsByUpdatedAt = /* GraphQL */ `
  query ListCollectionsByUpdatedAt($limit: Int, $nextToken: String) {
    listCollectionsByUpdatedAt(limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listNonExpiredCollectionsByUser = /* GraphQL */ `
  query ListNonExpiredCollectionsByUser($limit: Int, $nextToken: String) {
    listNonExpiredCollectionsByUser(limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const listNonExpiredCollectionsByUpdatedAt = /* GraphQL */ `
  query ListNonExpiredCollectionsByUpdatedAt($limit: Int, $nextToken: String) {
    listNonExpiredCollectionsByUpdatedAt(limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
export const getETag = /* GraphQL */ `
  query GetETag($input: CreateFileInput) {
    getETag(input: $input)
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!, $sortKey: String!) {
    getFile(id: $id, sortKey: $sortKey) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const getLatestFileVersion = /* GraphQL */ `
  query GetLatestFileVersion($input: GetLatestFileVersionInput) {
    getLatestFileVersion(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const getLatestStemVersion = /* GraphQL */ `
  query GetLatestStemVersion($input: GetLatestFileVersionInput) {
    getLatestStemVersion(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const listFileVersions = /* GraphQL */ `
  query ListFileVersions(
    $id: ID!
    $s3Key: String!
    $limit: Int
    $nextToken: String
  ) {
    listFileVersions(
      id: $id
      s3Key: $s3Key
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectId
        identityId
        createdAt
        updatedAt
        suppressExpiration
        expirationEpoch
        title
        origin
        themeColor
        description
        visibility
        publicAccess
        processing {
          id
          items {
            id
            opInfo
          }
        }
        comment
        comments {
          commentId
          projectId
          collectionId
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          updatedAt
          timestamp
          comment
          reactions {
            commentId
            projectID
            collectionID
            user {
              userId
              displayName
              displayIcon
              bio
              city
              country
              updatedAt
              createdAt
              links
              isProSubscriber
            }
            emoji
            updatedAt
          }
          audioUrl
        }
        preventComments
        owner {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        deleted
        s3_prefix
        spireFile {
          key
          name
          size
          signed_url
          last_modified
          createdAt
          updatedAt
        }
        mixFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        stemFiles {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        clipFiles {
          id
          file_name
          file_duration_frames
          end_frame_in_file
          sample_rate
          start_frame_in_file
          start_frame_in_timeline
          flac_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          mp3_file {
            key
            name
            enhanced
            type
            signed_url
            last_modified
            size
          }
          track_id
          pan
          gain
          mute
          stereoize
          color_index
          flac_key
          mp3_key
          instrumentID
        }
        spireProject {
          id
          project_file_version
          duration
          dirty
          tempo
          modified
          time_signature {
            beats
            divisions
          }
          tracks {
            id
            gain
            mute
            pan
            stereoize
            steroize
            color_index
            clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            wet_clips {
              id
              file_name
              file_duration_frames
              end_frame_in_file
              sample_rate
              start_frame_in_file
              start_frame_in_timeline
              track_id
              pan
              gain
              mute
              stereoize
              color_index
              flac_key
              mp3_key
              instrumentID
            }
            effects {
              name
              params
            }
          }
          metronome_on
          created
          num_tracks
          name
          syncState
        }
        sortKey
        fileVersion
        metadata
        original {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        preview {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        startTime
        endTime
        type
        waveform {
          bucket
          key
          metadata
          name
          region
          localUri
          etag
          mimeType
        }
        userFiles {
          items {
            id
            sortKey
            original {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            createdAt
            type
            visibility
            preview {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
            waveform {
              bucket
              key
              metadata
              name
              region
              localUri
              etag
              mimeType
            }
          }
          nextToken
        }
        isCollectionOwner
      }
      nextToken
    }
  }
`;
