import React, { memo, useEffect, useState } from 'react'
import { Emoji, Picker } from 'emoji-mart'
import { useAuth } from 'providers/AuthProvider'
import { useDispatch } from 'react-redux'
import { setAppState } from 'redux/app/appReducer'
import { labsCommentModalState } from 'utils/common'
import { log } from 'redux/analytics/analyticsReducer'
import './Reaction.scss'

function AddReaction({ handleReaction, parentActive }) {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [emojis, setEmojis] = useState(false)

  useEffect(() => setEmojis(false), [parentActive])

  const handleClick = e => {
    e.stopPropagation()
    if (!user) {
      dispatch(
        setAppState({ modal: 'SpireLabs', modalState: labsCommentModalState })
      )
      dispatch(log({ event: 'createReactionAttempt' }))
      return
    }
    setEmojis(!emojis)
  }

  const handleSelect = emoji => {
    setEmojis(false)
    handleReaction(emoji.id)
  }

  return (
    <>
      <div className="Reaction AddReaction" onClick={handleClick}>
        <Emoji set="apple" emoji="heavy_plus_sign" size={16} />
      </div>
      <div
        id="EmojiWrapper"
        className={emojis ? 'active' : 'inactive'}
        onClick={e => e.stopPropagation()}
      >
        {emojis && (
          <Picker
            set="apple"
            onSelect={handleSelect}
            perLine={7}
            theme="dark"
            emojiSize={16}
            showPreview={false}
          />
        )}
      </div>
    </>
  )
}

export default memo(AddReaction)
