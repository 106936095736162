import React, { useEffect } from 'react'
import { useAuth } from 'providers/AuthProvider'
import SpireLogo from 'assets/spire-no-bg.png'
import { useHistory } from 'react-router'
import Heading from 'components/base/Heading'
import './AdminLogin.scss'
import Button from 'components/base/Button'

function AdminLogin() {
  const { signIn, isAuthenticated } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="AdminLogin">
      <img src={SpireLogo} alt="Spire" />
      <Heading size={1}>Spire Admin</Heading>
      <br />
      <Button type="primary" onClick={() => signIn('Google')}>
        Sign In
      </Button>
    </div>
  )
}

export default AdminLogin
