import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import Oops from 'assets/oops.svg'
import SpireLogo from 'assets/spire-no-bg.png'
import { log } from 'redux/analytics/analyticsReducer'
import store from 'redux/store'
import Heading from 'components/base/Heading'
import ExternalLink from 'components/base/ExternalLink'
import Button from 'components/base/Button'
import './Crash.scss'

export default function Crash() {
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    if (
      !location.search.includes('reloaded') &&
      process.env.NODE_ENV !== 'development'
    ) {
      // try reloading the page first, if it crashes a second time,
      // show our crash error page
      const url = `${location.pathname}${
        location.search && location.search.length ? location.search + '&' : '?'
      }reloaded=true`
      history.push(url)
      window.location.reload()
      return
    }
    try {
      store.dispatch(log({ event: 'errorPageLoad' }))
    } catch (e) {
      console.error('Unable to log crash event:', e)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function goToHomepage() {
    history.push('/')
    window.location.reload()
  }

  return (
    <div id="Crash">
      <img
        src={SpireLogo}
        alt="Spire"
        className="Logo"
        onClick={goToHomepage}
      />
      <img src={Oops} alt="Oops" className="Primary" />
      <Heading size={3}>
        Uh oh... looks like something went wrong loading this page 😢
      </Heading>
      <p>
        Our engineers have been notified and we're working on a fix now.{' '}
        {window.innerWidth > 600 && <br />}
        We appreciate your help in tracking down bugs!
      </p>

      <div id="ErrorButtons">
        <Button type="primary" onClick={() => window.location.reload()}>
          Reload the Page
        </Button>
        <ExternalLink
          to="
          https://support.izotope.com/hc/en-us/requests/new"
        >
          <Button type="primary">Contact iZotope Support</Button>
        </ExternalLink>
      </div>
    </div>
  )
}
