import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getIconSignedUrl } from 'utils/common'
import { getKeyFromUrl } from 'components/shared/EditUserProfile/utils'
import { setAppState } from 'redux/app/appReducer'
import UserIcon from 'assets/user.svg'
import Heading from 'components/base/Heading'
import Input from 'components/base/Input'
import {
  callApi,
  getPublicUserProfileById,
  listCollectionsBySearchParameter,
} from 'graphql/izo_api'
import './LandingUserLookup.scss'

export default function LandingUserLookup() {
  const init = {
    user: {},
    collections: [],
  }
  const [foundUser, setFoundUser] = useState(init)
  const [lookupId, setLookupId] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  async function handleSearch() {
    setError(false)
    const user = await getUserProfile()
    const collections = await getUserCollections()
    if (user && collections) {
      setFoundUser({ collections, user })
    } else setError(true)
  }

  async function getUserCollections() {
    try {
      const input = {
        searchType: 'userId',
        searchValue: lookupId,
      }
      const res = await callApi(listCollectionsBySearchParameter, { input })
      const { items } = res.data.listCollectionsBySearchParameter
      return items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    } catch (e) {
      console.error('Error while fetching user collections:', e)
    }
  }

  async function getUserProfile() {
    try {
      const res = await callApi(getPublicUserProfileById, { userId: lookupId })
      const user = res.data.getPublicUserProfileById
      user.displayIcon = await getIconSignedUrl(user.displayIcon)
      return user
    } catch (e) {
      console.error('Error while fetching user profile:', e)
    }
  }

  function handleKeyDown(e) {
    if (
      e.keyCode === 13 &&
      ['TEXTAREA', 'INPUT'].includes(e.target.tagName.toUpperCase())
    ) {
      e.preventDefault()
      handleSearch()
    }
  }

  function handleClear() {
    setFoundUser(init)
    setLookupId('')
  }

  function handleEdit() {
    const profile = {
      ...foundUser.user,
      displayIcon: getKeyFromUrl(foundUser.user.displayIcon),
    }
    dispatch(
      setAppState({
        modal: 'AdminEditUser',
        modalState: { profile },
      })
    )
  }

  const didFindUser = foundUser.user.userId

  return (
    <div id="LandingUserLookup" className="Tile">
      <div className="Header">
        <Heading size={4}>Lookup a User Profile</Heading>
        <p>Find and update a user's display name and icon</p>
        <div id="UserSearch">
          <Input
            value={lookupId}
            onChange={e => setLookupId(e.target.value)}
            onKeyDown={handleKeyDown}
            label="Search by User ID..."
          />
          <i
            className={didFindUser ? 'fa fa-times' : 'fa fa-search'}
            onClick={didFindUser ? handleClear : handleSearch}
          />
        </div>
      </div>
      <div className="User">
        <div className="Info">
          <img
            src={foundUser.user.displayIcon || UserIcon}
            alt={foundUser.user.displayName}
          />
          <div>
            <Heading size={4}>{foundUser.user.displayName}</Heading>
            <Heading size={6}>{foundUser.user.userId}</Heading>
            {didFindUser && (
              <button onClick={handleEdit}>Edit User Profile</button>
            )}
          </div>
        </div>
        {error ? <div className="Error">Could not find user!</div> : null}
        {foundUser.collections.length ? (
          <div className="Collections">
            <Heading size={4}>Recent Projects</Heading>
            <ol>
              {foundUser.collections.slice(0, 3).map(c => (
                <li key={c.id}>
                  <Link to={`/c/${c.id}`}>
                    <Heading size={5}>{c.title}</Heading>
                    <span>created {moment(c.createdAt).fromNow()}</span>
                  </Link>
                </li>
              ))}
            </ol>
            <Link to={`/collections?userId=${lookupId}`}>
              <button>View All Projects</button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}
