import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import UnavailableProject from '../../shared/UnavailableProject'
import Waveform from 'components/base/Waveform'
import Playhead from '../../shared/Playhead'
import Track from '../../shared/Track'
import Heading from 'components/base/Heading'
import AudioControls from '../../shared/AudioControls'
import './AdminAudioViews.scss'

function AdminAudioViews({ style }) {
  const view = useSelector(store => store.app.view)
  const source = useSelector(store => store.audio.source)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const enhance = useSelector(store => store.audio.enhance)
  const files = useSelector(store => store.audio.files)
  const spireProject = useSelector(store => store.collection.spireProject)

  function Audio() {
    switch (source) {
      case 'spireProject':
        return spireProject.tracks.map((t, i) => (
          <Track key={i} track={t} index={i} />
        ))
      case 'mixFiles':
        const mix = files.find(b => b.type === 'mix' && b.enhanced === enhance)
        if (!mix) return null
        return (
          <div className="Track">
            <Waveform id={mix.file_name} src={mix.file_name} />
          </div>
        )
      case 'stemFiles':
        const stems = files.filter(b => b.type === 'stem')
        return stems.map((stem, i) => (
          <div className="Track" key={i}>
            <div className="TrackHeader">
              <Heading size={4} className="TrackLabel">
                Track {i + 1}
              </Heading>
            </div>
            <Waveform id={stem.file_name} src={stem.file_name} colorIndex={i} />
          </div>
        ))
      default:
        return null
    }
  }

  return (
    <>
      <div id="AdminAudioViews" className={view} style={style}>
        {isAudioLoaded ? (
          <>
            <div id="Tracks">{Audio()}</div>
            <Playhead />
          </>
        ) : (
          <UnavailableProject status="loading" />
        )}
      </div>
      <AudioControls />
    </>
  )
}

export default memo(AdminAudioViews)
