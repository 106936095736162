import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useAuth } from 'providers/AuthProvider'
import { log } from 'redux/analytics/analyticsReducer'
import { setMenu } from 'redux/app/appReducer'
import UserMenu from '../UserMenu/UserMenu'
import MenuIcon from 'assets/menu.png'
import ProfileIcon from 'components/base/ProfileIcon'
import { ADMIN } from 'utils/constants'
import './Header.scss'

export default function Header({ children, background = false }) {
  const { collectionId, projectId } = useParams()
  const { profile } = useAuth()
  const dispatch = useDispatch()
  const location = useLocation()

  const handleOpenMenu = () => {
    dispatch(setMenu(true))
    dispatch(log({ event: 'sidebarMenuOpen' }))
  }

  const home_icon =
    location.pathname === '/' ? '/spire-no-bg-192.png' : '/spire-bg-rd-192.png'

  function MenuButton() {
    return (collectionId || projectId) && !ADMIN ? (
      <button
        id="MenuButton"
        onClick={handleOpenMenu}
        aria-label="Open Project Menu"
      >
        {profile ? (
          <ProfileIcon profile={profile} />
        ) : (
          <img src={MenuIcon} alt="Menu" />
        )}
      </button>
    ) : (
      <UserMenu isInHeader />
    )
  }
  return (
    <header id="Header" className={background ? 'withBackground' : null}>
      <Link to="/">
        <img id="HomeButton" src={home_icon} alt="Home" />
      </Link>
      <div id="HeaderChildren">{children}</div>
      <MenuButton />
    </header>
  )
}
