import store from 'redux/store'
import { generateSessionStorageClipPathId } from './common'
import { MOBILE_THRESHOLD, SAMPLE_RATE, WAVEFORM_FIDELITY } from './constants'

export async function calculateAndCacheWaveformClipPath({
  audioBuffer,
  src,
  startFrame,
  endFrame,
  gain,
  projectDuration,
}) {
  if (src && !audioBuffer) {
    const file = store.getState().audio.files.find(f => f.file_name === src)
    if (!file || file.failedToLoad) return
    audioBuffer = file.audioBuffer
  }
  const clipPathId = generateSessionStorageClipPathId(
    src,
    startFrame,
    endFrame,
    gain
  )
  const cachedPath = sessionStorage.getItem(clipPathId)
  if (cachedPath) {
    return cachedPath
  }
  const containerWidth =
    window.innerWidth < MOBILE_THRESHOLD
      ? window.innerWidth - 32
      : window.innerWidth - 128
  const projectNumFrames = projectDuration * SAMPLE_RATE
  if (!endFrame) endFrame = projectNumFrames
  if (!startFrame) startFrame = 0
  const audioLength = endFrame - startFrame
  const numSections = parseInt(
    containerWidth * WAVEFORM_FIDELITY * (audioLength / projectNumFrames)
  ) // width of each "bucket" in pixels
  const numSamplesPerSection = Math.ceil(audioLength / numSections)
  const downSampledData = new Array(numSections)
  const l = audioBuffer.getChannelData(0)
  let r
  if (audioBuffer.numberOfChannels > 1) r = audioBuffer.getChannelData(1)
  for (
    let i = startFrame,
      j = 0,
      sectionIndex = 0,
      sectionMax = 0,
      step = Math.floor(numSamplesPerSection / 100);
    i < endFrame;
    i += step
  ) {
    j += step
    sectionMax = Math.max(r ? (l[i] + r[i]) * 0.5 : l[i], sectionMax)
    if (j >= numSamplesPerSection || i >= endFrame - (1 + step)) {
      downSampledData[sectionIndex] = sectionMax * gain
      sectionIndex++
      sectionMax = 0
      j = 0
    }
  }
  const clipPath = amplitudeArrayToCSSClipPath(
    downSampledData.filter(value => !Number.isNaN(value))
  )
  sessionStorage.setItem(clipPathId, clipPath)
  return clipPath
}

function amplitudeArrayToCSSClipPath(data) {
  let path = '0% 50%,'
  for (let i = 0; i < data.length; i++) {
    const x = ((i * 100) / data.length).toFixed(2)
    const y_pos = x === 0 ? 50 : (50 - data[i] * 50).toFixed(2)
    const y_neg = x === 0 ? 50 : (50 + data[i] * 50).toFixed(2)
    path = `${x}% ${y_neg}%,` + path + `${x}% ${y_pos}%,`
  }
  return path.length < 8 ? null : `polygon(100% 50%,${path}100% 50%)`
}
