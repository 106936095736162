import React, { memo } from 'react'
import PlayButton from '../PlayButton'
import EnhanceButton from '../EnhanceButton'
import AddComment from 'components/public/AddComment'
import { ADMIN } from 'utils/constants'
import './AudioControls.scss'

const AudioControls = ({ style }) => {
  return (
    <div id="AudioControls" style={style} aria-label="Audio Playback Controls">
      <div className="center">
        {!ADMIN && <AddComment />}
        <PlayButton />
        <EnhanceButton />
      </div>
    </div>
  )
}

export default memo(AudioControls)
