import { isExpiredCollection, publicLinkToCollection } from '../../utils/common'
import { callApi, updateCollection } from 'graphql/izo_api'
import {
  addSignedUrlsToComments,
  getIconSignedUrl,
  getThemeColorObject,
} from 'utils/common'
import themeColors from 'styles/theme-colors.json'

export async function parseCollectionInfo(response, storeAPI) {
  const { userId } = storeAPI.getState().auth
  // TODO: Get theme color from response
  const { themeColor } = storeAPI.getState().collection
  const payload =
    response.data.getCollectionById || response.data.getCollectionByProjectId
  if (!payload) return
  payload.collectionId = payload.id
  payload.processing = payload.processing
    ? payload.processing.items.map(i => ({
        id: i.id,
        ...JSON.parse(i.opInfo),
      }))
    : []
  payload.isCollectionOwner = payload.userId === userId
  payload.isExpired = isExpiredCollection(payload)
  payload.isPrivate = payload.visibility === 'privatePath'
  payload.themeColor =
    getThemeColorObject(payload.themeColor) || themeColor || themeColors[0]
  payload.publicUrl = publicLinkToCollection(payload.projectId)
  if (payload.owner && payload.owner.displayIcon) {
    payload.owner.displayIcon = await getIconSignedUrl(
      payload.owner.displayIcon
    )
  }
  return payload
}

export function parseCollectionSubscription(res) {
  try {
    const body = res.value.data.subscribeToCollectionUpdates
    // remove null values so as not to eliminate existing state
    const payload = {}
    for (const key in body) {
      if (body[key]) payload[key] = body[key]
    }
    if (payload.themeColor) {
      payload.themeColor = getThemeColorObject(payload.themeColor)
    }
    if (payload.processing) {
      payload.processing = payload.processing
        ? payload.processing.items.map(i => ({
            id: i.id,
            ...JSON.parse(i.opInfo),
          }))
        : []
    }
    return payload
  } catch (e) {
    console.warn('Error while parsing collection subscription response:', e)
  }
}

export function parseProcessSubscription(res, storeAPI) {
  try {
    const process =
      res.value.data.subscribeToProcessUpdates ||
      res.value.data.subscribeToProcessUpdatesWithTag
    let newProcess = process.process
    newProcess = { id: newProcess.id, ...JSON.parse(newProcess.opInfo) }
    const { processing = [] } = storeAPI.getState().collection
    return {
      processing: [
        ...processing.filter(p => p.operation !== newProcess.operation),
        newProcess,
      ],
    }
  } catch (e) {
    console.warn('Error while parsing process subscription response:', e)
  }
}

export function parseProjectChangedSubscription(res, storeAPI) {
  try {
    const payload = res.value.data.subscribeToProjectChanged
    return payload
  } catch (e) {
    console.warn(
      'Error while parsing project changed subscription response:',
      e
    )
  }
}

export async function parseCommentsSubscription(res, storeAPI) {
  try {
    const comments = await addSignedUrlsToComments(
      res.value.data.subscribeToProjectComments.comments
    )
    return comments
  } catch (e) {
    console.warn('Error while parsing comments subscription response:', e)
  }
}

export async function parseProjectComments(response) {
  try {
    const payload =
      response.data.getCollectionById || response.data.getCollectionByProjectId
    if (payload) {
      const comments = await addSignedUrlsToComments(payload.comments)
      return comments
    } else throw new Error('No collection info payload received')
  } catch (e) {
    console.error('Error handling comments payload:', JSON.stringify(e))
  }
}

export async function handleProjectIdBackfill(storeAPI) {
  try {
    const state = storeAPI.getState().collection
    if (
      state.spireProject &&
      state.spireProject.id &&
      !state.projectId &&
      state.title
    ) {
      const input = {
        id: state.collectionId,
        projectId: state.spireProject.id,
      }
      callApi(updateCollection, { input })
    }
  } catch (e) {
    console.error('Could not backfill:', e)
  }
}
