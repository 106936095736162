import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  files: [],
  source: null,
  enhance: false,
  duration: 0,
  elapsed: 0,
  playing: false,
  loadProgress: 0,
  isAudioLoaded: false,
  clock: null,
  failedToLoad: false,
  failCount: 0,
  playedAt: 0,
  pausedAt: 0,
  scrubbed: false,
  solo: null,
}

const audio = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    init(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    loadClipFile(state, action) {
      return {
        ...state,
        files: [...state.files, action.payload.file],
        loadProgress: action.payload.progress,
      }
    },
    loadProjectAudio(state, action) {
      return {
        ...state,
        isAudioLoaded:
          state.source === 'spireProject' ? true : state.isAudioLoaded,
      }
    },
    loadMixAudio(state, action) {
      return {
        ...state,
        files: [...state.files, ...action.payload.newFiles],
        isAudioLoaded: state.source === 'mixFiles' ? true : state.isAudioLoaded,
        duration: action.payload.duration,
        source: action.payload.source,
      }
    },
    loadStemAudio(state, action) {
      return {
        ...state,
        files: [...state.files, ...action.payload.newFiles],
        isAudioLoaded:
          state.source === 'stemFiles' ? true : state.isAudioLoaded,
      }
    },
    toggle(state) {
      return state.playing
        ? {
            ...state,
            playing: false,
            pausedAt: window.izo_audio_context.currentTime - state.playedAt,
            elapsed: window.izo_audio_context.currentTime - state.playedAt,
          }
        : {
            ...state,
            playing: true,
            playedAt: window.izo_audio_context.currentTime - state.pausedAt,
          }
    },
    toggleEnhance(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateSolo(state, action) {
      return {
        ...state,
        solo: action.payload,
      }
    },
    advancePlayhead(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    scrub(state, action) {
      return state.playing
        ? {
            ...state,
            scrubbed: true,
            pausedAt: action.payload,
            elapsed: action.payload,
            playedAt: window.izo_audio_context.currentTime - action.payload,
          }
        : {
            ...state,
            scrubbed: true,
            pausedAt: action.payload,
            elapsed: action.payload,
          }
    },
    setSource(state, action) {
      return { ...state, source: action.payload }
    },
    setDuration(state, action) {
      return { ...state, duration: action.payload }
    },
    setIsAudioLoaded(state, action) {
      return { ...state, isAudioLoaded: action.payload }
    },
    unload(state) {
      return {
        ...state,
        files: [],
        playing: false,
        loadProgress: 0,
        isAudioLoaded: false,
        enhance: false,
        failedToLoad: false,
      }
    },
    reset() {
      return initialState
    },
  },
})

export const {
  init,
  load,
  loadProjectAudio,
  loadClipFile,
  loadMixAudio,
  loadStemAudio,
  unload,
  toggle,
  toggleEnhance,
  advancePlayhead,
  setSource,
  setDuration,
  setIsAudioLoaded,
  scrub,
  reset,
  updateSolo,
} = audio.actions

export default audio.reducer
