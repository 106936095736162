import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getS3ObjectAmplify } from 'utils/common'
import PlayIcon from 'assets/play.svg'
import PauseIcon from 'assets/pause.svg'
import Loading from 'components/base/Loading'
import Heading from 'components/base/Heading'
import './RepairCompare.scss'

export default function RepairCompare({ clip }) {
  const [repaired, setRepaired] = useState()
  const [original, setOriginal] = useState()
  const [repairChain, setRepairChain] = useState()

  useEffect(() => {
    async function fetchOriginalClip(clip) {
      const s3Key = clip.flac_key
      if (!s3Key) {
        console.error('no s3 key found')
      } else {
        const signedUrl = await getS3ObjectAmplify(s3Key, { download: false })
        const res = await getS3ObjectAmplify(s3Key)
        const inputS3Key = res.Metadata.input
        if (inputS3Key) {
          const origFile = await getS3ObjectAmplify(inputS3Key, {
            download: false,
          })
          setOriginal(origFile)
          setRepaired(signedUrl)
          setRepairChain(res.Metadata.instrument)
        } else {
          setOriginal(signedUrl)
        }
      }
    }
    if (clip) fetchOriginalClip(clip)
  }, [clip])

  function Playhead({ id, src }) {
    const [playing, setPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [elapsed, setElapsed] = useState(0)

    function togglePlayback() {
      const audio = document.getElementById(id)
      audio.paused ? audio.play() : audio.pause()
      setPlaying(!playing)
    }

    function handleBackward() {
      const audio = document.getElementById(id)
      audio.currentTime -= 10
    }

    function handleForward() {
      const audio = document.getElementById(id)
      audio.currentTime += 10
    }

    const RADIUS = 52
    const progress = elapsed === 0 ? 0 : elapsed / duration

    return (
      <div className="Playhead">
        <audio
          id={id}
          src={src}
          hidden
          onEnded={() => {
            setElapsed(0)
            setPlaying(false)
          }}
          onDurationChange={e => setDuration(e.target.duration)}
          onTimeUpdate={e => setElapsed(e.target.currentTime || 0)}
        />
        <button
          onClick={togglePlayback}
          className={playing ? 'playing' : 'paused'}
        >
          <img
            src={playing ? PauseIcon : PlayIcon}
            alt={playing ? 'Pause' : 'Play'}
          />
          <svg height={RADIUS * 2} width={RADIUS * 2}>
            <circle
              style={{
                strokeDashoffset: 327 - progress * 327,
              }}
              r={RADIUS - 2}
              cx={RADIUS}
              cy={RADIUS}
            />
          </svg>
        </button>
        <div>
          <button className="rw" onClick={handleBackward}>
            <i className="fa fa-fast-backward" />
          </button>
          {moment.utc(elapsed * 1000).format('m:ss')}
          {' / '}
          {moment.utc(duration * 1000).format('m:ss')}

          <button className="ff" onClick={handleForward}>
            <i className="fa fa-fast-forward" />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div id="RepairCompare">
      <Heading size={1}>Compare Clip Audio</Heading>
      <div className="Playheads">
        {original ? (
          <div id="Original">
            <Heading size={3}>Original</Heading>
            <Playhead src={original} id="elapsed-original" />
          </div>
        ) : null}
        {repaired ? (
          <div id="Repaired">
            <Heading size={3}>{'Repaired (' + repairChain + ')'}</Heading>
            <Playhead src={repaired} id="elapsed-repaired" />
          </div>
        ) : null}
        {!original && !repaired ? <Loading /> : null}
      </div>
    </div>
  )
}
