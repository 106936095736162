import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import SoloIcon from 'assets/solo.svg'
import { updateSolo } from 'redux/audio/audioReducer'
import './Solo.scss'

function Solo({ trackId, on }) {
  const dispatch = useDispatch()

  return (
    <button
      className={`SoloButton ${on ? 'on' : 'off'}`}
      id={`Solo-${trackId}`}
      onClick={() => dispatch(updateSolo(on ? null : trackId))}
    >
      <img src={SoloIcon} alt="Toggle Solo" />
    </button>
  )
}

export default memo(Solo)
