import {
  callApi,
  createSpireProjectFromStems,
  deleteFile,
} from 'graphql/izo_api'
import acoustic_guitar from 'assets/guitar.svg'
import drums from 'assets/drums.svg'
import bass from 'assets/bass.svg'
import electric_guitar from 'assets/electric_guitar.svg'
import piano from 'assets/keys.svg'
import other from 'assets/other.svg'
import microphone from 'assets/voice.svg'
import { checkSupportedFormats } from 'utils/common'
import { Storage } from 'aws-amplify'
import {
  STORAGE_BUCKET,
  STORAGE_REGION,
  TIME_SIGNATURE_LOOKUP,
  SUPPORTED_STEM_UPLOAD_EXTENSIONS,
} from 'utils/constants'

export const icons = {
  acoustic_guitar,
  drums,
  bass,
  electric_guitar,
  piano,
  other,
  microphone,
}

export const DEFAULT_PROJECT_ATTRS = {
  name: 'Untitled',
  timeSignature: '4/4',
  tempo: 110,
}

export async function downloadStem(key) {
  try {
    const link = await Storage.get(key)
    window.location = link
  } catch (e) {
    console.error(e)
  }
}

export async function createSpireProject(collectionId, title, stemFiles) {
  const originalStems = [...stemFiles]
  try {
    const { name, tempo, timeSignature } = DEFAULT_PROJECT_ATTRS
    await callApi(createSpireProjectFromStems, {
      input: {
        id: collectionId,
        projectAttrs: {
          name: title || name,
          tempo,
          timeSignature: TIME_SIGNATURE_LOOKUP[timeSignature],
        },
        stemFiles: stemFiles
          .filter(f =>
            checkSupportedFormats(f.name, SUPPORTED_STEM_UPLOAD_EXTENSIONS)
          )
          .map(i => ({
            location: {
              bucket: STORAGE_BUCKET,
              region: STORAGE_REGION,
              key: i.key,
              name: i.key.split('/').pop(),
            },
          })),
      },
    })
    originalStems.forEach(stem => {
      callApi(deleteFile, {
        input: {
          id: collectionId,
          sortKey: stem.sortKey,
        },
      })
    })
  } catch (e) {
    console.error('Error while creating spire project:', e)
  }
}
