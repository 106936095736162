/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const invokeDsp = /* GraphQL */ `
  mutation InvokeDsp($input: InvokeDSPInput!) {
    invokeDSP(input: $input) {
      id
    }
  }
`;
export const writeProjectJsonToS3 = /* GraphQL */ `
  mutation WriteProjectJsonToS3($id: ID!, $s3_prefix: String!) {
    writeProjectJsonToS3(id: $id, s3_prefix: $s3_prefix)
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($projectId: ID!, $clientId: String!) {
    deleteProject(projectId: $projectId, clientId: $clientId) {
      id
      clientId
      userId
      status
      resolvedState
      serverState
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection($input: DeleteCollectionInput!) {
    deleteCollection(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const updateProjectAccess = /* GraphQL */ `
  mutation UpdateProjectAccess($projectId: ID!, $publicAccess: Boolean!) {
    updateProjectAccess(projectId: $projectId, publicAccess: $publicAccess) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createFileVersion = /* GraphQL */ `
  mutation CreateFileVersion($input: CreateFileInput!) {
    createFileVersion(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const upsertFileVersionZero = /* GraphQL */ `
  mutation UpsertFileVersionZero($input: CreateFileInput) {
    upsertFileVersionZero(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createStem = /* GraphQL */ `
  mutation CreateStem($input: CreateFileInput!) {
    createStem(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createStemVersion = /* GraphQL */ `
  mutation CreateStemVersion($input: CreateFileInput!) {
    createStemVersion(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const upsertStemVersionZero = /* GraphQL */ `
  mutation UpsertStemVersionZero($input: CreateFileInput) {
    upsertStemVersionZero(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createSpireProjectFromStems = /* GraphQL */ `
  mutation CreateSpireProjectFromStems($input: CreateSpireProjectInput) {
    createSpireProjectFromStems(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createMixdown = /* GraphQL */ `
  mutation CreateMixdown($input: CreateFileInput!) {
    createMixdown(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const enhanceFileVersion = /* GraphQL */ `
  mutation EnhanceFileVersion($input: CreateFileInput!) {
    enhanceFileVersion(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createProcess = /* GraphQL */ `
  mutation CreateProcess($input: CreateProcessInput!) {
    createProcess(input: $input) {
      id
      process {
        id
        opInfo
      }
      tag
    }
  }
`;
export const upsertDetectedClipIid = /* GraphQL */ `
  mutation UpsertDetectedClipIid($input: CreateClipIIDInput!) {
    upsertDetectedClipIID(input: $input) {
      id
      clipInstrumentsDetected {
        clipFilename
        detectedInstrument
      }
      clipInstrumentsUserOverride {
        clipFilename
        detectedInstrument
      }
      createdAt
      sortKey
      updatedAt
      type
    }
  }
`;
export const upsertUserOverrideClipIid = /* GraphQL */ `
  mutation UpsertUserOverrideClipIid($input: CreateClipIIDInput!) {
    upsertUserOverrideClipIID(input: $input) {
      id
      clipInstrumentsDetected {
        clipFilename
        detectedInstrument
      }
      clipInstrumentsUserOverride {
        clipFilename
        detectedInstrument
      }
      createdAt
      sortKey
      updatedAt
      type
    }
  }
`;
export const unpackSpireProject = /* GraphQL */ `
  mutation UnpackSpireProject($input: UnpackProjectInput!) {
    unpackSpireProject(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const transcodeAudioFile = /* GraphQL */ `
  mutation TranscodeAudioFile($input: TranscodeInput!) {
    transcodeAudioFile(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createUnpackedMix = /* GraphQL */ `
  mutation CreateUnpackedMix($input: BackendOperationInput!) {
    createUnpackedMix(input: $input)
  }
`;
export const createSpireFile = /* GraphQL */ `
  mutation CreateSpireFile($id: ID!, $s3_prefix: String!) {
    createSpireFile(id: $id, s3_prefix: $s3_prefix)
  }
`;
export const createUnpackedEnhancedMix = /* GraphQL */ `
  mutation CreateUnpackedEnhancedMix($input: BackendOperationInput!) {
    createUnpackedEnhancedMix(input: $input)
  }
`;
export const createUnpackedStems = /* GraphQL */ `
  mutation CreateUnpackedStems($input: BackendOperationInput!) {
    createUnpackedStems(input: $input)
  }
`;
export const createUnpackedSpireProject = /* GraphQL */ `
  mutation CreateUnpackedSpireProject($input: CreateUnpackedProjectInput!) {
    createUnpackedSpireProject(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const syncProject = /* GraphQL */ `
  mutation SyncProject($input: SyncProjectInput!) {
    syncProject(input: $input) {
      id
      clientId
      userId
      status
      resolvedState
      serverState
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CommentCreateInput!) {
    createComment(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: CommentUpdateInput!) {
    updateComment(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createReaction = /* GraphQL */ `
  mutation CreateReaction($input: ReactionCreateInput!) {
    createReaction(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: CommentDeleteInput!) {
    deleteComment(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const deleteReaction = /* GraphQL */ `
  mutation DeleteReaction($input: ReactionDeleteInput!) {
    deleteReaction(input: $input) {
      id
      userId
      projectId
      identityId
      createdAt
      updatedAt
      suppressExpiration
      expirationEpoch
      title
      origin
      themeColor
      description
      visibility
      publicAccess
      processing {
        id
        items {
          id
          opInfo
        }
      }
      comment
      comments {
        commentId
        projectId
        collectionId
        user {
          userId
          displayName
          displayIcon
          bio
          city
          country
          updatedAt
          createdAt
          links
          isProSubscriber
        }
        updatedAt
        timestamp
        comment
        reactions {
          commentId
          projectID
          collectionID
          user {
            userId
            displayName
            displayIcon
            bio
            city
            country
            updatedAt
            createdAt
            links
            isProSubscriber
          }
          emoji
          updatedAt
        }
        audioUrl
      }
      preventComments
      owner {
        userId
        displayName
        displayIcon
        bio
        city
        country
        updatedAt
        createdAt
        links
        isProSubscriber
      }
      deleted
      s3_prefix
      spireFile {
        key
        name
        size
        signed_url
        last_modified
        createdAt
        updatedAt
      }
      mixFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      stemFiles {
        key
        name
        enhanced
        type
        signed_url
        last_modified
        size
      }
      clipFiles {
        id
        file_name
        file_duration_frames
        end_frame_in_file
        sample_rate
        start_frame_in_file
        start_frame_in_timeline
        flac_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        mp3_file {
          key
          name
          enhanced
          type
          signed_url
          last_modified
          size
        }
        track_id
        pan
        gain
        mute
        stereoize
        color_index
        flac_key
        mp3_key
        instrumentID
      }
      spireProject {
        id
        project_file_version
        duration
        dirty
        tempo
        modified
        time_signature {
          beats
          divisions
        }
        tracks {
          id
          gain
          mute
          pan
          stereoize
          steroize
          color_index
          clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          wet_clips {
            id
            file_name
            file_duration_frames
            end_frame_in_file
            sample_rate
            start_frame_in_file
            start_frame_in_timeline
            flac_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            mp3_file {
              key
              name
              enhanced
              type
              signed_url
              last_modified
              size
            }
            track_id
            pan
            gain
            mute
            stereoize
            color_index
            flac_key
            mp3_key
            instrumentID
          }
          effects {
            name
            params
          }
        }
        metronome_on
        created
        num_tracks
        name
        syncState
      }
      sortKey
      fileVersion
      metadata
      original {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      preview {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      startTime
      endTime
      type
      waveform {
        bucket
        key
        metadata
        name
        region
        localUri
        etag
        mimeType
      }
      userFiles {
        items {
          id
          sortKey
          original {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          createdAt
          type
          visibility
          preview {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
          waveform {
            bucket
            key
            metadata
            name
            region
            localUri
            etag
            mimeType
          }
        }
        nextToken
      }
      isCollectionOwner
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile($input: UserProfileInput!) {
    createUserProfile(input: $input) {
      userId
      displayName
      displayIcon
      bio
      city
      country
      updatedAt
      createdAt
      links
      isProSubscriber
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      userId
      displayName
      displayIcon
      bio
      city
      country
      updatedAt
      createdAt
      links
      isProSubscriber
    }
  }
`;
export const projectChanged = /* GraphQL */ `
  mutation ProjectChanged($input: ProjectChangedInput!) {
    projectChanged(input: $input) {
      projectId
      userId
    }
  }
`;
export const doMinorEdit = /* GraphQL */ `
  mutation DoMinorEdit($input: ProjectChangedInput!) {
    doMinorEdit(input: $input) {
      id
      clientId
      userId
      status
      resolvedState
      serverState
    }
  }
`;
export const uploadOfferCodes = /* GraphQL */ `
  mutation UploadOfferCodes(
    $codes: String!
    $expiration: AWSDateTime!
    $platform: String!
  ) {
    uploadOfferCodes(
      codes: $codes
      expiration: $expiration
      platform: $platform
    )
  }
`;
export const registerHardware = /* GraphQL */ `
  mutation RegisterHardware(
    $serialNumber: String!
    $details: AWSJSON!
    $optIn: Boolean
  ) {
    registerHardware(
      serialNumber: $serialNumber
      details: $details
      optIn: $optIn
    ) {
      userId
      details
      serialNumber
      createdAt
      updatedAt
    }
  }
`;
export const grantUserOffer = /* GraphQL */ `
  mutation GrantUserOffer(
    $userId: String!
    $serialNumber: String
    $offerDetails: OfferDetailsInput!
  ) {
    grantUserOffer(
      userId: $userId
      serialNumber: $serialNumber
      offerDetails: $offerDetails
    )
  }
`;
export const updateEmailOptIn = /* GraphQL */ `
  mutation UpdateEmailOptIn($details: AWSJSON!, $optIn: Boolean!) {
    updateEmailOptIn(details: $details, optIn: $optIn)
  }
`;
export const updateOfferCode = /* GraphQL */ `
  mutation UpdateOfferCode($offerCode: String!, $redeemed: Boolean) {
    updateOfferCode(offerCode: $offerCode, redeemed: $redeemed) {
      offerCode
      createdAt
      softExpiration
      hardExpiration
      useBy
      redeemed
    }
  }
`;
