import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { log } from 'redux/analytics/analyticsReducer'
import FailedIcon from 'assets/cannot_play.svg'
import { overRideIosMuteSwitch } from './utils'
import PauseIcon from 'assets/pause.svg'
import PlayIcon from 'assets/play.svg'
import Tooltip from 'components/base/Tooltip'
import { toggle } from 'redux/audio/audioReducer'
import './PlayButton.scss'

const ANIMATION_TIMEOUT = 960
const RADIUS = 34

function PlayButton() {
  const playing = useSelector(store => store.audio.playing)
  const isAudioLoaded = useSelector(store => store.audio.isAudioLoaded)
  const loadProgress = useSelector(store => store.audio.loadProgress)
  const failedToLoad = useSelector(store => store.audio.failedToLoad)
  const [canPlay, setCanPlay] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAudioLoaded) {
      setTimeout(() => {
        setCanPlay(true)
        const load_time =
          (performance.now() - window.izo_page_load_start) / 1000
        dispatch(log({ event: 'audioLoad', params: { load_time } }))
      }, ANIMATION_TIMEOUT)
    } else setCanPlay(false)
  }, [isAudioLoaded]) // eslint-disable-line

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleToggle() {
    overRideIosMuteSwitch()
    dispatch(toggle())
    dispatch(
      log({
        event: `togglePlaying`,
        params: {
          playerAction: playing ? 'pause' : 'play',
        },
      })
    )
  }

  function handleKeydown(e) {
    if (
      e.keyCode === 32 &&
      !['TEXTAREA', 'INPUT'].includes(e.target.tagName.toUpperCase())
    ) {
      e.preventDefault()
      handleToggle()
    }
  }

  const strokeDashoffset = Math.min(260 - loadProgress * 260, 230)
  return (
    <button
      id="PlayButton"
      aria-label={playing ? 'Pause Audio' : 'Play Audio'}
      className={`${playing ? 'playing' : 'paused'} ${
        canPlay && !failedToLoad ? 'loaded' : 'loading'
      }`}
      onClick={canPlay && !failedToLoad ? handleToggle : null}
    >
      {failedToLoad ? (
        <Tooltip
          id="PlayButton"
          position="top"
          text="Error while loading audio, refresh the page"
        />
      ) : null}
      <img
        src={failedToLoad ? FailedIcon : playing ? PauseIcon : PlayIcon}
        alt={failedToLoad ? 'Cannot Play' : playing ? 'Pause' : 'Play'}
      />
      <svg
        className={isAudioLoaded ? 'loaded' : ''}
        height={RADIUS * 2}
        width={RADIUS * 2}
      >
        <circle
          style={{ strokeDashoffset }}
          r={RADIUS - 2}
          cx={RADIUS}
          cy={RADIUS}
        />
      </svg>
    </button>
  )
}

export default memo(PlayButton)
