import { SAMPLE_RATE } from 'utils/constants'

export function generatePositionStyles({
  startFrameInFile = 0,
  endFrameInFile,
  startFrameInTimeline = 0,
  duration,
}) {
  endFrameInFile = endFrameInFile || duration * SAMPLE_RATE
  return {
    left: `${(100 * startFrameInTimeline) / (SAMPLE_RATE * duration)}%`,
    width: `${(100 * (endFrameInFile - startFrameInFile)) /
      (SAMPLE_RATE * duration)}%`,
  }
}

export function calculateElapsedWidth({
  startFrameInFile = 0,
  endFrameInFile,
  startFrameInTimeline = 0,
  elapsed,
  duration,
}) {
  endFrameInFile = endFrameInFile || duration * SAMPLE_RATE
  const offset = startFrameInTimeline / (SAMPLE_RATE * duration)
  const width = (endFrameInFile - startFrameInFile) / (SAMPLE_RATE * duration)
  return `${Math.max(0, (100 * (elapsed / duration - offset)) / width)}%`
}
