import React from 'react'
import Header from 'components/shared/Header'
import Heading from 'components/base/Heading'

const AdminNoPermission = () => (
  <div
    id="AdminNoPermission"
    className="FlexCenter FlexColumn"
    style={{ height: '100%' }}
  >
    <Header />
    <Heading size={3}>Almost There!</Heading>
    <p>
      You've sucessfully logged into TransferPlus Admin UI, but don't have
      permissions to see anything here.
    </p>
    <p>
      Contact the Cloud Team via Slack at <em>#transfer-plus</em> to request
      access.
    </p>
  </div>
)

export default AdminNoPermission
