import React, { useEffect, useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import { DistinctCollectionsById } from '../../AdminCollections/utils'
import moment from 'moment'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import VisibilityToggle from '../../VisibilityToggle'
import Ellipsis from 'components/base/Ellipsis'
import { openInS3 } from '../../AdminFiles/utils'
import { publicLinkToCollection } from 'utils/common'
import { callApi, listCollectionsBySearchParameter } from 'graphql/izo_api'
import Heading from 'components/base/Heading'
import Input from 'components/base/Input'
import './LandingCollections.scss'

let fetchTimestamp

export default function LandingCollections() {
  const isMounted = useIsMounted()
  const history = useHistory()
  const [collections, setCollections] = useState([])
  const [working, setWorking] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [visibility, setVisibility] = useState('privatePath')
  const [error, setError] = useState(false)

  useEffect(() => handleSearchParam(), [])

  useEffect(() => {
    fetchCollections()
  }, [visibility]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleSearchParam() {
    const search = window.location.search.substring(1).toLowerCase()
    if (search && search === 'visibility=privatepath') {
      setVisibility('privatePath')
    }
  }

  async function fetchCollections() {
    try {
      const now = performance.now()
      fetchTimestamp = now
      setError(false)
      setWorking(true)
      const input = {
        searchType: 'title',
        searchValue,
        visibility,
      }
      const res = await callApi(listCollectionsBySearchParameter, { input })
      const { items } = res.data.listCollectionsBySearchParameter
      const updatedCollections = DistinctCollectionsById(items).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      if (isMounted() && fetchTimestamp === now) {
        setCollections(updatedCollections)
        setWorking(false)
      }
    } catch (e) {
      setError(true)
      setWorking(false)
      console.error('Error while fetching collections:', e.errors[0].message)
    }
  }

  function handleSearch() {
    history.push(`/collections?title=${searchValue}`)
  }

  function handleKeyDown(e) {
    if (
      e.keyCode === 13 &&
      ['TEXTAREA', 'INPUT'].includes(e.target.tagName.toUpperCase())
    ) {
      e.preventDefault()
      handleSearch()
    }
  }

  const toggleVisibility = () => {
    setCollections([])
    setVisibility(visibility ? null : 'privatePath')
  }

  function handleS3Click(e, collection) {
    e.stopPropagation()
    openInS3(collection.id, collection.identityId, collection.visibility)
  }

  function filterBySearch(collections) {
    return collections.filter(c =>
      c.title.toLowerCase().includes(searchValue.toLowerCase())
    )
  }

  return (
    <div id="LandingCollections" className="Tile">
      <div className="Header">
        <Heading size={4}>Projects</Heading>
        <VisibilityToggle
          toggleVisibility={toggleVisibility}
          visibility={visibility}
        />
      </div>
      <div id="CollectionSearch">
        <Input
          value={searchValue}
          onKeyDown={handleKeyDown}
          onChange={e => setSearchValue(e.target.value)}
          label="Search by title..."
        />
        <i className="fa fa-search" onClick={handleSearch} />
      </div>
      <ol>
        {collections.length ? (
          filterBySearch(collections)
            .slice(0, 8)
            .map(c => (
              <li key={c.id}>
                <Link to={`/c/${c.id}`}>
                  <Heading
                    size={5}
                    className={c.visibility === 'privatePath' ? 'Private' : ''}
                  >
                    {c.spireProject && c.spireProject.name
                      ? c.spireProject.name
                      : c.title}
                  </Heading>
                </Link>
                <div className="CollectionInfo">
                  <span>created {moment(c.createdAt).fromNow()}</span>
                  <a
                    href={publicLinkToCollection(c.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>Public</div>
                  </a>
                  {' | '}
                  <div onClick={e => handleS3Click(e, c)}>View in S3</div>
                </div>
              </li>
            ))
        ) : error ? (
          <div className="Error">Error loading collections!</div>
        ) : !working ? (
          <div className="Loading">No collections found!</div>
        ) : (
          <div className="Loading">
            Loading collections
            <Ellipsis />
          </div>
        )}
      </ol>
      <Link to="/collections">
        <button className="ViewCollections">View all recent collections</button>
      </Link>
    </div>
  )
}
