import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useOAuthProvider } from '../Login'
import { useAuth } from 'providers/AuthProvider'
import { log } from 'redux/analytics/analyticsReducer'
import Button from 'components/base/Button'
import ProLabel from 'components/base/ProLabel'
import { setModal } from 'redux/app/appReducer'
import { CSSTransition } from 'react-transition-group'
import ProfileIcon from 'components/base/ProfileIcon'
import Heading from 'components/base/Heading'
import './UserMenu.scss'

export default function UserMenu({ style, isInMenu, isInHeader }) {
  const { user, profile } = useAuth()
  const { onSignOut } = useOAuthProvider()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()

  const logProjectsEvent = () =>
    dispatch(log({ event: 'userMenuShareProjects' }))
  const logCreateEvent = () => dispatch(log({ event: 'userMenuCreateProject' }))

  function toggleOpen() {
    setOpen(current => !current)
  }

  function getUserDisplayName() {
    if (profile && profile.displayName) {
      return profile.displayName
    } else if (user && user.name) {
      return user.name
    } else return 'Spire User'
  }

  function LoginButton() {
    const openLabs = () => {
      dispatch(log({ event: 'userMenuLogin' }))
      dispatch(setModal('SpireLabs'))
    }

    return (
      <Button type="primary" onClick={openLabs} fullWidth>
        Login
      </Button>
    )
  }

  function UserButton() {
    return (
      <div id="UserButton">
        <ProfileIcon profile={profile} size={32} />
        <Heading size={5} onClick={toggleOpen} wrap={open}>
          {getUserDisplayName()}
          <ProSubscriberIndicator />
        </Heading>
      </div>
    )
  }

  function ProSubscriberIndicator() {
    return profile && profile.isProSubscriber ? <ProLabel /> : null
  }

  function UserDropdown() {
    return (
      <ul id="UserDropdown">
        {location.pathname !== '/' && (
          <li>
            <Link to="/" onClick={logProjectsEvent}>
              <i className="fa fa-home" />
              Spire Home
            </Link>
          </li>
        )}
        <li>
          <Link to="/?action=create" onClick={logCreateEvent}>
            <i className="fa fa-plus" />
            Create Project
          </Link>
        </li>
        <li onClick={() => dispatch(setModal('EditUserProfile'))}>
          <i className="fa fa-user" />
          Update Profile
        </li>
        <li onClick={onSignOut}>
          <i className="fa fa-sign-out-alt" />
          Sign Out
        </li>
      </ul>
    )
  }
  return (
    <div
      id="UserMenu"
      style={style}
      className={`${open ? 'open' : 'closed'}${isInMenu ? ' in-menu' : ''}${
        isInHeader ? ' in-header' : ''
      }`}
    >
      {user ? (
        <>
          <UserButton />
          <CSSTransition
            in={open}
            timeout={200}
            classNames="drop"
            unmountOnExit
          >
            <UserDropdown />
          </CSSTransition>
        </>
      ) : null}
    </div>
  )
}
