import {
  callApi,
  subscribe,
  getCollectionAudio,
  getCollectionAudioAsAdmin,
  getCollectionInfo,
  getCollectionMixes,
  getProjectComments,
  subscribeToCollectionUpdates,
  subscribeToProcessUpdates,
  subscribeToProcessUpdatesWithTag,
  subscribeToModifiedProject,
  subscribeToProjectComments,
  createCollection,
  syncProject,
  invokeDSP,
  writeProjectJsonToS3,
  getProjectInfo,
  getProjectMixes,
  getProjectAudioAsAdmin,
  getProjectAudio,
} from 'graphql/izo_api'
import {
  parseCommentsSubscription,
  parseProcessSubscription,
  parseCollectionSubscription,
  parseCollectionInfo,
  parseProjectComments,
  handleProjectIdBackfill,
} from './collectionUtils'
import { fetch, updateComments, subscriptionUpdate } from './collectionReducer'
import { createNewProjectId, generateNativeAppImportLink } from 'utils/common'
import { handleError } from '../app/appReducer'
import { syncStateToProjectJson, getUpdatedSyncState } from '../../utils/common'
import { refreshAudioPlayback } from '../audio/audioHandler'
import { sync } from './collectionReducer'
import store from '../store'
import { ADMIN } from '../../utils/constants'
import { setSource } from 'redux/audio/audioReducer'

window.izo_subscriptions = {}

const collectionHandler = () => next => action => {
  switch (action.type) {
    case 'collection/init':
      handleInit(next, action)
      break
    case 'collection/fetch':
      handleFetch(next, action)
      break
    case 'collection/createCollection':
      handleCreate(next, action)
      break
    case 'collection/reset':
      handleReset(next, action)
      break
    case 'collection/sync':
      handleSyncProject(next, action)
      break
    case 'collection/toggleTrackMute':
      handleToggleTrackMute(next, action)
      break
    case 'collection/bounceProject':
      handleBounceProject(next, action)
      break
    default:
      return next(action)
  }
}

function handleInit(next, action) {
  const res = next(action)
  store.dispatch(fetch())
  return res
}

async function handleFetch(next, action) {
  const { collectionId, projectId } = store.getState().collection
  if (collectionId) {
    fetchCollectionInfo(collectionId, store, next, action)
    fetchCollectionAudio(collectionId, store, next, action)
  } else if (projectId) {
    fetchProjectInfo(projectId, store, next, action)
    fetchProjectAudio(projectId, store, next, action)
  }
}

async function fetchProjectInfo(projectId, store, next, action) {
  try {
    // fetch data from GraphQL API
    const res = await callApi(getProjectInfo, { id: projectId })
    const payload = await parseCollectionInfo(res, store)
    // Update Redux State by calling next()
    next({ ...action, payload })
    // Initialize all Web Socket Subscriptions after state is updated
    initializeSubscriptions(next, action)
    fetchComments(store)
  } catch (e) {
    console.error('Error fetching collection:', e)
    store.dispatch(
      handleError({
        type: 'collection/info',
        description: 'Encountered error while fetching collection info',
        fatal: true,
      })
    )
  }
}

async function fetchProjectAudio(projectId, store, next, action) {
  try {
    const query = isEmbeddedURL()
      ? getProjectMixes
      : ADMIN
      ? getProjectAudioAsAdmin
      : getProjectAudio
    const res = await callApi(query, { id: projectId })
    const payload = res.data.getCollectionByProjectId
    if (payload && payload.spireProject && payload.spireProject.tracks) {
      store.dispatch(setSource('spireProject'))
    } else {
      store.dispatch(setSource('mixFiles'))
    }
    if (payload && payload.spireProject && payload.spireProject.syncState) {
      payload.syncState = JSON.parse(payload.spireProject.syncState)
      // Sometimes need to run parse a second time due to AppSync "over-stringifying" type AWSJSON
      // see: https://github.com/aws-amplify/amplify-js/issues/5767
      if (typeof payload.syncState === 'string') {
        payload.syncState = JSON.parse(payload.syncState)
      }
    }
    payload.appImportLink = payload.spireFile
      ? await generateNativeAppImportLink(
          payload.spireFile.signed_url,
          payload.spireProject ? payload.spireProject.name : 'Spire Project'
        )
      : null
    next({ ...action, payload })
  } catch (e) {
    console.error('Error fetching audio:', e)
    store.dispatch(
      handleError({
        type: 'collection/audio',
        description: 'Encountered error while fetching collection audio',
        fatal: true,
      })
    )
  }
}

async function fetchCollectionInfo(collectionId, store, next, action) {
  try {
    // fetch data from GraphQL API
    const res = await callApi(getCollectionInfo, { id: collectionId })
    const payload = await parseCollectionInfo(res, store)
    // Update Redux State by calling next()
    next({ ...action, payload })
    // Initialize all Web Socket Subscriptions after state is updated
    initializeSubscriptions(next, action)
    // Get projectId from Updated Redux State
    const { projectId } = store.getState().collection
    projectId ? fetchComments(store) : handleProjectIdBackfill(store)
  } catch (e) {
    console.error('Error fetching collection:', e)
    store.dispatch(
      handleError({
        type: 'collection/info',
        description: 'Encountered error while fetching collection info',
        fatal: true,
      })
    )
  }
}

async function fetchCollectionAudio(collectionId, store, next, action) {
  try {
    const query = isEmbeddedURL()
      ? getCollectionMixes
      : ADMIN
      ? getCollectionAudioAsAdmin
      : getCollectionAudio
    const res = await callApi(query, { id: collectionId })
    const payload = res.data.getCollectionById
    if (payload && payload.spireProject && payload.spireProject.tracks) {
      store.dispatch(setSource('spireProject'))
    } else {
      store.dispatch(setSource('mixFiles'))
    }
    if (payload && payload.spireProject && payload.spireProject.syncState) {
      payload.syncState = JSON.parse(payload.spireProject.syncState)
      // Sometimes need to run parse a second time due to AppSync "over-stringifying" type AWSJSON
      // see: https://github.com/aws-amplify/amplify-js/issues/5767
      if (typeof payload.syncState === 'string') {
        payload.syncState = JSON.parse(payload.syncState)
      }
    }
    const { title } = store.getState().collection
    payload.appImportLink = payload.spireFile
      ? await generateNativeAppImportLink(
          payload.spireFile.signed_url,
          payload.spireProject ? payload.spireProject.name : title
        )
      : null
    next({ ...action, payload })
  } catch (e) {
    console.error('Error fetching audio:', e)
    store.dispatch(
      handleError({
        type: 'collection/audio',
        description: 'Encountered error while fetching collection audio',
        fatal: true,
      })
    )
  }
}

function isEmbeddedURL() {
  return window.location.pathname.includes('embed')
}

async function fetchComments(store) {
  try {
    const { collectionId } = store.getState().collection
    const res = await callApi(getProjectComments, { id: collectionId })
    const payload = await parseProjectComments(res)
    store.dispatch(updateComments(payload))
  } catch (e) {
    console.error('Error fetching comments:', e)
    store.dispatch(
      handleError({
        type: 'collection/comments',
        description: 'Encountered error fetching comments',
        message: 'Oops - There was a problem loading comments on this project.',
        fatal: false,
        notify: false,
        error: e,
      })
    )
  }
}

async function handleCreate(next, action) {
  try {
    const input = {
      ...action.payload,
      projectId: createNewProjectId(),
    }
    const res = await callApi(createCollection, { input })
    next(action)
    window.location = `/collections/${res.data.createCollection.id}`
  } catch (e) {
    console.error('Error creating collection:', e)
    store.dispatch(
      handleError({
        type: 'collection/create',
        description:
          'Encountered error while attempting to create new collection',
        displayMessage:
          'Whoops! Failed to create new collection. Try reloading the page.',
        fatal: false,
        notify: true,
        error: e,
      })
    )
  }
}

function handleReset(next, action) {
  if (window.location.pathname === '/') {
    // reset if redirecting to root url
    closeSubscriptions()
    return next(action)
  }
}

async function handleSyncProject(next, action) {
  const { syncClientId, projectId, syncState } = store.getState().collection
  const res = await callApi(syncProject, {
    input: {
      id: projectId,
      syncData: JSON.stringify(syncState),
      clientId: syncClientId,
    },
  })
  const resolvedState = JSON.parse(res.data.syncProject.resolvedState)
  next({
    ...action,
    payload: {
      syncState: resolvedState,
      spireProject: syncStateToProjectJson(resolvedState),
    },
  })
}

async function handleToggleTrackMute(next, action) {
  const { spireProject, visibility } = store.getState().collection

  const payload = {}

  payload.spireProject = JSON.parse(JSON.stringify(spireProject))
  const track = payload.spireProject.tracks.find(
    t => t.id === action.payload.id
  )
  track.mute = !track.mute

  if (visibility === 'privatePath') {
    const { syncState } = store.getState().collection
    payload.syncState = JSON.parse(JSON.stringify(syncState))
    const mix = payload.syncState.entities.mix
    const mixNode = mix.value[action.payload.id]
    mixNode.mute = !mixNode.mute
    payload.syncState = getUpdatedSyncState({ mix })
  }

  const res = next({
    ...action,
    payload,
  })

  if (visibility === 'privatePath') {
    store.dispatch(sync())
  }
  refreshAudioPlayback()
  return res
}

// helpers and parsers

async function initializeSubscriptions(next, action) {
  try {
    const { collectionId, projectId, isPrivate } = store.getState().collection
    if (!window.izo_subscriptions.collection) {
      window.izo_subscriptions.collection = await subscribe(
        subscribeToCollectionUpdates,
        { id: collectionId },
        res => {
          store.dispatch(subscriptionUpdate(parseCollectionSubscription(res)))
        }
      )
    }
    if (!window.izo_subscriptions.processing) {
      window.izo_subscriptions.processing = await subscribe(
        subscribeToProcessUpdates,
        { id: collectionId },
        res => {
          store.dispatch(
            subscriptionUpdate(parseProcessSubscription(res, store))
          )
          store.dispatch(fetch())
        }
      )
    }
    //TODO: this sub will work for all processing from go-spire-canary
    //change this sub name (and the logic in CollectionDownload) when
    //canary is used for more than just bouncing!
    if (!window.izo_subscriptions.bounceProject) {
      window.izo_subscriptions.bounceProject = await subscribe(
        subscribeToProcessUpdatesWithTag,
        { tag: projectId },
        res => {
          store.dispatch(
            subscriptionUpdate(parseProcessSubscription(res, store))
          )
          store.dispatch(fetch())
        }
      )
    }
    if (isPrivate && !window.izo_subscriptions.modifiedProject) {
      window.izo_subscriptions.modifiedProject = await subscribe(
        subscribeToModifiedProject,
        { id: projectId },
        () => store.dispatch(fetch())
      )
    }
    if (projectId && !window.izo_subscriptions.comments) {
      window.izo_subscriptions.comments = await subscribe(
        subscribeToProjectComments,
        { projectId },
        async res => {
          const payload = await parseCommentsSubscription(res)
          store.dispatch(updateComments(payload))
        }
      )
    }
  } catch (e) {
    console.error('Error initializing subscriptions:', e)
    store.dispatch(
      handleError({
        type: 'collection/subscriptions',
        description: 'Error while initializing subscriptions',
        fatal: false,
        notify: false,
        error: e,
      })
    )
  }
}

function closeSubscriptions() {
  try {
    for (const key in window.izo_subscriptions) {
      try {
        if (window.izo_subscriptions[key]) {
          window.izo_subscriptions[key].unsubscribe()
          delete window.izo_subscriptions[key]
        }
      } catch (e) {
        console.error(`Error while unsubscribing from ${key}`, e)
        handleError({
          type: 'collection/unsubscribe',
          description: `Encountered error attempting to unsubscribe from ${key}`,
          fatal: false,
          notify: false,
          error: e,
        })
      }
    }
  } catch (e) {
    console.error('Error while unsubscribing:', e)
    handleError({
      type: 'collection/unsubscribe',
      description:
        'Encountered error attempting to unsubscribe from collection updates',
      fatal: false,
      notify: false,
      error: e,
    })
  }
}

async function handleBounceProject(next, action) {
  // initialize this first, as it is used to determine whether processing is currently running
  const { collectionId, s3_prefix, projectId } = store.getState().collection
  await callApi(writeProjectJsonToS3, {
    id: collectionId,
    s3_prefix,
  })
  const keyList = [`${s3_prefix}/project.json`]
  await callApi(invokeDSP, {
    input: {
      operation: 'mixandstems',
      keyList,
      tag: projectId,
    },
  })
}

export default collectionHandler
