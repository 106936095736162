import React from 'react'
import { useAuth } from 'providers/AuthProvider'
import AppleIcon from 'assets/apple.png'
import FacebookIcon from 'assets/facebook.png'
import GoogleIcon from 'assets/google.svg'
import { log } from 'redux/analytics/analyticsReducer'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/base/Button'

const oAuthProviders = {
  Apple: 'SignInWithApple',
  Facebook: 'Facebook',
  Google: 'Google',
}

function useOAuthProvider(oAuthProvider) {
  const { handleSignOut, signIn } = useAuth()
  const dispatch = useDispatch()
  const elapsed = useSelector(store => store.audio.elapsed)

  const onSignIn = async () => {
    dispatch(
      log({ event: 'oAuthLoginAttempt', params: { provider: oAuthProvider } })
    )
    try {
      sessionStorage.setItem('cachedPlayheadPosition', elapsed)
    } catch (e) {}
    sessionStorage.setItem(
      'redirectURL',
      window.location.pathname.replace('/SpireLabs', '/')
    )
    signIn(oAuthProvider)
  }

  const onSignOut = () => {
    dispatch(log({ event: 'oAuthLogoutAttempt' }))
    try {
      sessionStorage.setItem('cachedPlayheadPosition', elapsed)
    } catch (e) {}
    sessionStorage.setItem(
      'redirectURL',
      window.location.pathname.replace('/SpireLabs', '/')
    )
    handleSignOut()
  }

  return {
    onSignIn,
    onSignOut,
  }
}

function AppleSignInBtn() {
  const { Apple } = oAuthProviders
  const { onSignIn } = useOAuthProvider(Apple)

  return (
    <Button type="primary" iconLeft={AppleIcon} onClick={onSignIn} fullWidth>
      Sign in with Apple
    </Button>
  )
}

function FacebookSignInBtn() {
  const { Facebook } = oAuthProviders
  const { onSignIn } = useOAuthProvider(Facebook)

  return (
    <Button type="primary" iconLeft={FacebookIcon} onClick={onSignIn} fullWidth>
      Sign in with Facebook
    </Button>
  )
}

function GoogleSignInBtn() {
  const { Google } = oAuthProviders
  const { onSignIn } = useOAuthProvider(Google)

  return (
    <Button type="primary" iconLeft={GoogleIcon} onClick={onSignIn} fullWidth>
      Sign in with Google
    </Button>
  )
}

function Login() {
  return (
    <>
      <GoogleSignInBtn />
      <FacebookSignInBtn />
      <AppleSignInBtn />
    </>
  )
}

export {
  Login,
  AppleSignInBtn,
  FacebookSignInBtn,
  GoogleSignInBtn,
  useOAuthProvider,
}
