import React, { useState, useEffect } from 'react'
import { callApi, subscribe } from 'graphql/izo_api'
import Input from 'components/base/Input'
import { doAutoIndent } from './utils'
import Heading from 'components/base/Heading'
import './LandingQueryTool.scss'
import Button from 'components/base/Button'

export default function LandingQueryTool() {
  const [query, setQuery] = useState(`{
  getCollectionById( id: "test-collection" ) {
    title
  }
}`)
  const [result, setResult] = useState()
  const [error, setError] = useState(false)
  const [sub, setSub] = useState([])

  function handleChange(e) {
    setQuery(e.target.value)
    setError(false)
  }

  function closeSubscriptions() {
    for (const s of sub) {
      s.unsubscribe()
    }
    setSub([])
    setResult('')
  }

  async function handleQuery() {
    if (query.toLowerCase().startsWith('subscription')) {
      try {
        setSub([
          ...sub,
          await subscribe(query, {}, (res, error) => {
            if (error) {
              setError(JSON.stringify(error.error.errors[0]))
              setResult('')
            } else {
              setResult(res.value.data)
            }
          }),
        ])
        setResult('subscribed...')
      } catch (e) {
        console.error('error creating gql sub:', e)
      }
    } else {
      setSub(null)
      try {
        const res = await callApi(query)
        setResult(res.data)
      } catch (e) {
        setError(JSON.stringify(e))
        console.error('error making gql query:', e)
      }
    }
  }

  function handleClear() {
    if (sub && sub.length > 0) {
      setResult('subscribed...')
    } else {
      setResult('')
    }
  }

  useEffect(() => {
    // handle auto indenting
    const tas = document.querySelectorAll('textarea')
    ;[].forEach.call(tas, function(ta) {
      doAutoIndent(ta, '  ')
    })
  }, [])

  return (
    <div id="LandingQueryTool" className="Tile">
      <Heading size={4}>Query AppSync</Heading>
      <div id="TextAreas">
        <Input
          id="query"
          type="textarea"
          value={query}
          onChange={handleChange}
          label="Query"
          rows={6}
        />
        <Input
          id="result"
          type="textarea"
          value={JSON.stringify(result, null, 2)}
          label="Result"
          readOnly
          rows={6}
        />
      </div>
      {error && (
        <p id="QueryError">
          <button className="secondary" onClick={() => setError(false)}>
            x
          </button>
          Error: {error}
        </p>
      )}
      <div id="Buttons">
        <Button type="tertiary" onClick={handleClear}>
          clear output
        </Button>
        <div id="RightButtons">
          <Button
            type="secondary"
            onClick={closeSubscriptions}
            disabled={sub && sub.length > 0 ? false : true}
          >
            Close Subs
          </Button>
          <Button type="secondary" onClick={handleQuery}>
            Run Query
          </Button>
        </div>
      </div>
    </div>
  )
}
