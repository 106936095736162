import React, { useEffect } from 'react'
import { labsProfileModalState } from 'utils/common'
import { useDispatch } from 'react-redux'
import { setAppState } from 'redux/app/appReducer'
import { useAuth } from 'providers/AuthProvider'
import UserIcon from 'assets/user.svg'
import { log } from 'redux/analytics/analyticsReducer'
import { ADMIN } from 'utils/constants'
import Heading from 'components/base/Heading'
import './PublicUserProfile.scss'

export default function PublicUserProfile({ publicUser, close }) {
  const links =
    publicUser && publicUser.links && publicUser.links.length
      ? JSON.parse(publicUser.links)
      : null
  const { user } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(log({ event: 'viewPublicUser' }))
  })

  function handleEdit() {
    close()
    setTimeout(() => {
      dispatch(
        setAppState({
          modal: user ? 'EditUserProfile' : 'SpireLabs',
          modalState: user ? null : labsProfileModalState,
        })
      )
    }, 320)
  }

  return publicUser ? (
    <div id="PublicUserProfile">
      <img src={publicUser.displayIcon || UserIcon} alt="User Display Icon" />
      <Heading size={2} wrap align="center">
        {publicUser.displayName}
      </Heading>
      {publicUser.city && (
        <Heading size={3}>
          {publicUser.city}
          {publicUser.country && `, ${publicUser.country}`}
        </Heading>
      )}
      {!publicUser.city && publicUser.country && (
        <Heading size={3}>{publicUser.country}</Heading>
      )}
      {publicUser.bio && <p>{publicUser.bio}</p>}
      {links ? (
        <div className="Links">
          {links.map((l, i) => (
            <a
              href={l.url}
              target="_blank"
              rel="noopener noreferrer"
              className="Link"
              key={i}
            >
              <div>
                <i className="fa fa-link" />
                {l.name}
              </div>
            </a>
          ))}
        </div>
      ) : null}
      {ADMIN && (
        <div className="UserId">
          UserId: {publicUser.userId}
          <br />
          <strong>*User ID is only visible on Admin Site*</strong>
        </div>
      )}
      <p className="EditProfile">
        <i className="fa fa-info-circle" /> Change how you appear to other users
        by <span onClick={handleEdit}>Updating your Profile</span>
      </p>
    </div>
  ) : (
    <div id="PublicUserProfile">
      <Heading size={1}>Uh oh!</Heading>
      <p>Unable to find user.</p>
      <button onClick={close}>Close</button>
    </div>
  )
}
