import React from 'react'
import LandingNewCollection from './LandingNewCollection/LandingNewCollection'
import LandingCollections from './LandingCollections/LandingCollections'
import LandingUserLookup from './LandingUserLookup/LandingUserLookup'
import LandingOfferCodes from './LandingOfferCodes/LandingOfferCodes'
import LandingQueryTool from './LandingQueryTool/LandingQueryTool'
import './AdminLanding.scss'

export default function AdminLanding() {
  return (
    <div id="AdminLanding">
      <div className="LandingTiles">
        <LandingCollections />
        <LandingUserLookup />
        <LandingNewCollection />
        <LandingOfferCodes />
        <LandingQueryTool />
      </div>
    </div>
  )
}
