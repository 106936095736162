import React from 'react'
import './AdminTabs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setView } from 'redux/app/appReducer'
import { useHistory, useLocation } from 'react-router'
import Heading from 'components/base/Heading'

const AdminTabs = () => {
  const view = useSelector(store => store.app.view)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const tabs = ['clips', 'song']

  return (
    <div id="AdminTabs">
      {tabs.map(t => {
        const className = t === view ? 'selected' : ''
        return (
          <div
            key={t}
            id={t}
            className={className}
            onClick={() => {
              const url = `${location.pathname}?tab=${t}`
              history.push(url)
              dispatch(setView(t))
            }}
          >
            <Heading size={5} align="center">
              {t === 'song' ? 'Mixes' : t === 'tracks' ? 'Stems' : 'Clips'}
            </Heading>
          </div>
        )
      })}
    </div>
  )
}

export default AdminTabs
