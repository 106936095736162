import React from 'react'
import { Emoji } from 'emoji-mart'
import { useAuth } from 'providers/AuthProvider'
import './Reaction.scss'

export default function Reaction({ emoji, users = [], onClick }) {
  const { profile } = useAuth()
  return emoji ? (
    <div
      className={`Reaction${
        profile && users.includes(profile.displayName) ? ' withBackground' : ''
      }`}
      onClick={onClick}
    >
      <Emoji
        set={'apple'}
        emoji={emoji}
        size={16}
        fallback={(emoji, props) => {
          return emoji ? `:${emoji.short_names[0]}:` : props.emoji
        }}
      />
      {users.length}
      <div className="ReactionUsers">{users.join(',\n')}</div>
    </div>
  ) : null
}
