import { createSlice } from '@reduxjs/toolkit'
import { MOBILE_THRESHOLD } from '../../utils/constants'

const initialState = {
  view: 'clips',
  menu: false,
  modal: false,
  modalState: {},
  mobile: window.innerWidth < MOBILE_THRESHOLD,
  errorLogs: [],
  errorState: false,
}

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppState(state, action) {
      return {
        ...state,
        ...action.payload,
        modalState: action.payload.modalState || {},
      }
    },
    setMenu(state, action) {
      return {
        ...state,
        menu: action.payload,
      }
    },
    setModal(state, action) {
      return {
        ...state,
        modal: action.payload,
      }
    },
    setView(state, action) {
      return {
        ...state,
        view: action.payload,
      }
    },
    handleError(state, action) {
      return {
        ...state,
        errorLogs: [...state.errorLogs, action.payload],
        errorState: state.errorState === true ? true : action.payload.fatal,
      }
    },
    resize(state, action) {
      if (action.payload.width > MOBILE_THRESHOLD && state.mobile) {
        return { ...state, mobile: false }
      }
      if (action.payload.width < MOBILE_THRESHOLD && !state.mobile) {
        return { ...state, mobile: true }
      } else return state
    },
    reset() {
      return initialState
    },
  },
})

export const {
  setAppState,
  setView,
  setMenu,
  setModal,
  handleError,
  resize,
} = app.actions

export default app.reducer
