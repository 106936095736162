import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import themeColors from 'styles/theme-colors.json'
import Heading from 'components/base/Heading'
import './SelectTheme.scss'

export default function SelectTheme({
  isAdmin,
  onSelect,
  currentColor,
  showText,
  style,
}) {
  const themeColor = useSelector(store => store.collection.themeColor)
  const [loadingColorChange, setLoadingColorChange] = useState(false)
  const [activeColor, setActiveColor] = useState(
    currentColor || themeColor || themeColors[0]
  )

  useEffect(() => {
    setLoadingColorChange(false)
    setActiveColor(currentColor || themeColor || themeColors[0])
  }, [themeColor, currentColor])

  return (
    <div id="SelectTheme" className={isAdmin ? 'isAdmin' : ''} style={style}>
      {showText && <Heading size={6}>Project Theme Color:</Heading>}
      <div id="Colors">
        {themeColors.map(color => (
          <div
            key={color.name}
            style={{ background: color.primary }}
            className={`Color ${
              color.name === activeColor.name ? 'selected' : ''
            } ${loadingColorChange === color.name ? 'loading-change' : ''}`}
            onClick={() => {
              onSelect(color)
              setLoadingColorChange(color.name)
            }}
          />
        ))}
      </div>
    </div>
  )
}
