import React, { useState, useEffect, memo } from 'react'
import Lottie from 'react-lottie'
import ProcessingAnimation from 'assets/processing.json'
import FinishedAnimation from 'assets/finished-animation.json'
import Ellipsis from 'components/base/Ellipsis'
import './Notifications.scss'
import { useSelector } from 'react-redux'

function Notifications() {
  const origin = useSelector(store => store.collection.origin)
  const processing = useSelector(store => store.collection.processing)
  const isPrivate = useSelector(store => store.collection.isPrivate)
  const [processes, setProcesses] = useState(null)

  useEffect(() => {
    processing && processing.length && parseUpdatedProcesses()
  }, [processing]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processes) {
      for (const p of processes) {
        if (isProcessFinished(p) && !p.hidden) {
          hideFinishedProcess(p, 3200)
        }
      }
    }
  }, [processes]) // eslint-disable-line react-hooks/exhaustive-deps

  function parseUpdatedProcesses() {
    const updated = []
    for (const p of processing.slice()) {
      if (isPrivate && p.operation === 'CreateMixAndStems') return
      const current = mapRedisFormatToObject({ ...p })
      const existing = processes
        ? processes.find(e => e.operation === current.operation)
        : null
      const duplicate = updated.find(e => e.operation === current.operation)
      if (duplicate) return
      current.hidden = false
      if (!processes && isProcessFinished(current)) {
        // if process is finished on initial load, hide immediately
        current.hidden = true
      }
      if (existing && existing.hidden && existing.status === current.status) {
        // keep notificaiton hidden if user already chose to hide it
        current.hidden = true
      }
      updated.push(current)
    }
    setProcesses(updated)
  }

  function hideFinishedProcess(process, delay = 1000) {
    const element = document.getElementById(
      process.operation.split(' ').join('')
    )
    element.className += delay > 1000 ? ' exit-with-delay' : ' exit'
    setTimeout(() => {
      const source = processes.find(p => p.operation === process.operation)
      if (source) {
        source.hidden = true
        setProcesses([
          ...processes.filter(p => p.operation !== process.operation),
          source,
        ])
      }
    }, delay)
  }

  const isProcessFinished = process =>
    process.status.includes('finished') || process.status.includes('failed')

  function mapRedisFormatToObject(process) {
    switch (process.operation) {
      case 'CreateMixAndStems': {
        process.operation =
          origin === 'web-public'
            ? 'Generating Spire Project Files'
            : 'Bouncing Stems and Mixes'
        break
      }
      case 'CreateMix': {
        process.operation = 'Generating your Mix'
        break
      }
      case 'processAudio': {
        process.operation = 'Repairing your Audio'
        break
      }
      case 'CreateEnhancedMix': {
        process.operation = 'Enhancing your Mix'
        break
      }
      case 'NewComments': {
        process.operation = 'New Comments!'
        break
      }
      default:
        break
    }
    return process
  }

  return processing && processing.length ? (
    <div id="Notifications">
      {processes &&
        processes
          .filter(p => !p.hidden)
          .map((p, i) => {
            return (
              <div
                key={p.operation}
                id={p.operation.split(' ').join('')}
                style={{ top: `${i * 60}px` }}
                className="Notification"
              >
                <Lottie
                  options={
                    isProcessFinished(p)
                      ? FinishedAnimation
                      : ProcessingAnimation
                  }
                  height={30}
                  width={30}
                />
                <span>
                  {p.operation}
                  {!isProcessFinished(p) && <Ellipsis />}
                </span>
                <button onClick={() => hideFinishedProcess(p)}>
                  <i className="fas fa-times" />
                </button>
              </div>
            )
          })}
    </div>
  ) : null
}

export default memo(Notifications)
