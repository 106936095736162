import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
const SHARE_PREFIX = 'https://share.izotope.com'

const MetaTags = () => {
  const collection = useSelector(store => store.collection)
  const { comments = [] } = collection
  const title = useSelector(store => store.collection.title)
  const error = useSelector(store => store.collection.error)
  const spireProject = useSelector(store => store.collection.spireProject)
  const visibility = useSelector(store => store.collection.visibility)

  const projectData = {
    createdAt: new Date(collection.createdAt),
    expiresAt: collection.suppressExpiration
      ? 'No expiration'
      : new Date(collection.expirationEpoch),
    commentCount: comments.length,
    reactionCount: getReactionCount(comments),
    commentUsersCount: getCommentUsersCount(comments),
  }
  function getReactionCount(comments) {
    try {
      let count = 0
      for (const c of comments) {
        count += c.reactions.length
      }
      return count
    } catch (e) {
      console.error('Error while counting reactions:', e)
    }
  }

  function getCommentUsersCount(comments) {
    try {
      const users = []
      for (const c of comments) {
        if (!users.includes(c.user.userId)) {
          users.push(c.user.userId)
        }
        for (const r of c.reactions) {
          if (!users.includes(r.user.userId)) {
            users.push(r.user.userId)
          }
        }
      }
      return users.length
    } catch (e) {
      console.error('Error while counting comment users:', e)
    }
  }

  return title ? (
    <Helmet id="MetaTags">
      <title>
        {visibility === 'privatePath' && spireProject && spireProject.name
          ? spireProject.name + ' | '
          : title
          ? title + ' | '
          : error
          ? ''
          : 'Loading Project | '}
        iZotope Spire
      </title>
      <meta
        property="og:title"
        content={
          visibility === 'privatePath'
            ? spireProject && spireProject.name
              ? spireProject.name
              : 'Spire Project'
            : title
        }
      />
      <meta
        name="twitter:card"
        content={`${collection.title} | iZotope Spire`}
      />
      <meta
        name="twitter:site"
        content="iZotope Spire | Create and share audio with anyone, from anywhere."
      />
      <meta name="twitter:creator" content="@izotope" />
      <meta property="project:createdAt" content={projectData.createdAt} />
      <meta property="project:expiresAt" content={projectData.expiresAt} />
      <meta
        property="project:commentCount"
        content={projectData.commentCount}
      />
      <meta
        property="project:reactionCount"
        content={projectData.reactionCount}
      />
      <meta
        property="project:commentUsersCount"
        content={projectData.commentUsersCount}
      />

      <meta
        property="og:url"
        content={
          collection.projectId
            ? `${SHARE_PREFIX}/p/${collection.projectId}`
            : SHARE_PREFIX
        }
      />
    </Helmet>
  ) : null
}

export default MetaTags
