import React, { useState } from 'react'
import moment from 'moment'
import Ellipsis from 'components/base/Ellipsis'
import { callApi, uploadOfferCodes } from 'graphql/izo_api'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import Input from 'components/base/Input'
import './LandingOfferCodes.scss'

function getDefaultExpiration() {
  const inSixMonths = moment(new Date())
    .endOf('day')
    .add(6, 'M')
    .subtract(1, 'day')
    .format('YYYY-MM-DDTkk:mm')
  return inSixMonths
}

export default function LandingOfferCodes() {
  const [form, setForm] = useState({
    expiration: getDefaultExpiration(),
    codes: '',
    platform: 'ios',
  })
  const [confirmed, setConfirmed] = useState(false)
  const [button, setButton] = useState('Upload Codes')

  function handleChange(e) {
    setForm({ ...form, [e.target.id]: e.target.value })
  }

  function handleConfirm() {
    if (!form.expiration || !moment(form.expiration).isValid()) {
      alert('Please enter a valid expiration date to upload new offer codes.')
      return
    }
    if (!validateCodes(form.codes)) {
      alert('Please enter valid offer codes.')
      return
    }
    setButton('Confirm Upload')
    setConfirmed(true)
  }

  function validateCodes(string) {
    if (!string || !string.length) return false
    const codes = string
      .split(',')
      .filter(i => i.length)
      .map(code => code.replace(/\r?\n|\r/g, ''))
    return codes && codes.length
  }

  async function handleUpload() {
    setButton('Uploading')
    const res = await callApi(uploadOfferCodes, {
      codes: form.codes,
      platform: form.platform,
      expiration: new Date(form.expiration),
    })
    try {
      const data = JSON.parse(res.data.uploadOfferCodes)
      if (data.success) {
        setForm({ ...form, codes: '' })
        setButton(`✅ Uploaded ${data.codes} codes`)
        setConfirmed(false)
        setTimeout(() => setButton('Upload Codes'), 3000)
      } else {
        setButton('❌ Upload Failed')
        setTimeout(() => setButton('Upload Codes'), 3000)
      }
    } catch (e) {
      console.error(e)
      setButton('❌ Upload Failed')
      setTimeout(() => setButton('Upload Codes'), 3000)
    }
  }

  return (
    <div id="LandingOfferCodes" className="Tile">
      <Heading size={4}>Upload New Offer Codes</Heading>
      <Heading size={6}>
        Stores App Store Codes for 6 month Spire Pro subscriptions.
      </Heading>
      <br />
      <Heading size={5}>Expiration Date</Heading>
      <Heading size={6}>
        Date represents 6 months from when codes were generated
      </Heading>
      <input
        id="expiration"
        type="datetime-local"
        value={form.expiration}
        onChange={handleChange}
      />
      <Heading size={5}>Offer Platform</Heading>
      <Heading size={6}>
        Defines the device platform that will use this set of codes
      </Heading>
      <select id="platform" value={form.platform} onChange={handleChange}>
        <option value="ios">iOS</option>
      </select>
      <br />
      <Input
        id="codes"
        type="textarea"
        label="Codes (separated by commas)"
        rows={10}
        value={form.codes}
        onChange={handleChange}
      />
      {confirmed ? (
        <div className="Buttons">
          <Button
            type="tertiary"
            onClick={() => {
              setButton('Upload Codes')
              setConfirmed(false)
            }}
          >
            cancel
          </Button>
          <Button
            type="secondary"
            onClick={button !== 'Uploading' ? handleUpload : null}
          >
            {button}
          </Button>
        </div>
      ) : (
        <Button type="secondary" fullWidth onClick={handleConfirm}>
          {button.includes('ing') ? (
            <>
              {button}
              <Ellipsis />
            </>
          ) : (
            button
          )}
        </Button>
      )}
    </div>
  )
}
