import { Storage } from 'aws-amplify'

export const generateS3Key = (file, userId) =>
  `icons/${userId}/${file.name
    .replace(!/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g, '_')
    .replace(')', '')
    .replace('(', '')
    .replace(' ', '')}`

export async function uploadFile(key, file) {
  const obj = await Storage.put(key, file, {
    level: 'public',
    contentType: file.type,
  })
  return obj
}

export function getKeyFromUrl(url) {
  if (!url) return null
  return isSignedUrl(url) ? url.split('/public/')[1].split('?')[0] : url
}

export function isSignedUrl(url) {
  return url ? url.includes('https://') : false
}
