import { UAParser } from 'ua-parser-js'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { Storage } from 'aws-amplify'
import store from 'redux/store'
import moment from 'moment'
import themeColors from 'styles/theme-colors.json'
import { v4 as uuid } from 'uuid'
import { callApi, createCollection } from 'graphql/izo_api'
import { NATIVE_APP_BASE_URL } from './constants'

export const parser = new UAParser()
export const os = parser.getOS().name

export const getBrowserName = () => parser.getBrowser().name

export const getBrowserVersion = () => parser.getBrowser().version

export const isUserAgentPrerender = () =>
  parser
    .getUA()
    .toString()
    .toLowerCase()
    .includes('prerender')

const adminToPublicMap = {
  'share-admin': 'share.izotope.com',
  share: 'share.izotope.com',
  'share-admin-uat': 'share-uat.izotope.com',
  'share-uat': 'share-uat.izotope.com',
  'share-admin-dev': 'share-dev.izotope.com',
  'share-dev': 'share-dev.izotope.com',
  // UNCOMMENT FOLLOWING LINE FOR DEBUGGING RENDER (LINK MIGHT NOT ACTUALLY WORK, DEPENDING ON .env.local)
  localhost: 'share-dev.izotope.com',
}

export function isFileSpireImportable(filename = '') {
  const ext = filename
    .toLowerCase()
    .split('.')
    .pop()

  if (ext === 'spire' && isDeviceSupportedBySpire()) {
    return true
  }
  return false
}

export function isDeviceSupportedBySpire() {
  if (['iOS', 'Android'].includes(os)) {
    return true
  }
  if (isIPadPro()) {
    return true
  }
  return false
}

export function isIPadPro() {
  return /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document
}

export function publicLinkToCollection(projectId) {
  const hostname = window.location.hostname.split('.')[0]

  if (hostname in adminToPublicMap) {
    const protocol = hostname.endsWith('-dev')
      ? window.location.protocol
      : 'https:'
    return protocol + '//' + adminToPublicMap[hostname] + '/p/' + projectId
  } else {
    return `https://share-dev.izotope.com/p/${projectId}`
  }
}

export function encodeIfNecessary(input) {
  try {
    if (decodeURIComponent(input) === input) {
      //Not already encoded or trivially encodable
      return encodeURIComponent(input)
    }
    return input
  } catch (err) {
    //decode failed on malformed URL, so encode
    return encodeURIComponent(input)
  }
}

export async function generateNativeAppImportLink(
  spire_file_signed_url,
  title = 'spire_project'
) {
  if (!spire_file_signed_url) return null
  return `${NATIVE_APP_BASE_URL}?title=${encodeIfNecessary(
    title
  )}&t=${encodeURIComponent(spire_file_signed_url)}&$uri_redirect_mode=1`
}

export function parseCollectionFromLocation(pathname = '') {
  const result =
    new RegExp(/\/c\/([^?/]+)/, 'ig').exec(pathname) ||
    new RegExp(/\/collections\/([^?/]+)/, 'ig').exec(pathname)
  if (result === null || result.length < 0) {
    return ''
  }

  return result[1]
}

export const checkSupportedFormats = (fileName, extensionsList = []) => {
  // .find returns undefined if no matches are found
  return extensionsList.find(ext =>
    fileName.toLowerCase().endsWith(ext.toLowerCase()) ? true : false
  )
}

export function formatTime(seconds) {
  if (seconds === null) return '-:--'
  const mins = Math.floor(seconds / 60)
  seconds = Math.floor(seconds % 60)
  if (seconds < 10) seconds = '0' + seconds
  return mins + ':' + seconds
}

export function safelyGetFieldFromObject(p, o) {
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
}

export function parseProviderFromError(
  errorMsg = '',
  matchString = 'Already found an entry for username ',
  regex = /(Facebook|Google|SignInWithApple)/
) {
  if (errorMsg === null || errorMsg === '') return ''

  const split = errorMsg.split(matchString)
  const username = split.length > 1 ? split[1] : ''
  const match = username.match(regex) // returns array or null
  const provider = match ? match[1] : ''

  return provider
}

export function getFileNameWithoutExtension(fname = '') {
  const dotIdx = fname.lastIndexOf('.')
  if (dotIdx === -1) {
    return fname
  }

  return fname.substring(0, dotIdx)
}

export function initializeSentry() {
  if (process.env.REACT_APP_FEATURE_FLAG_SENTRY === 'true') {
    window.izo_release_version = process.env.REACT_APP_RELEASE_VERSION
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
      release: process.env.REACT_APP_RELEASE_VERSION,
      integrations: [
        new Integrations.CaptureConsole({
          levels: ['error'],
        }),
      ],
      beforeSend: event => {
        if (isUserAgentPrerender()) return null
        const error_type = safelyGetFieldFromObject(
          ['exception', 'values', 0, 'type'],
          event
        )
        const error_value = safelyGetFieldFromObject(
          ['exception', 'values', 0, 'value'],
          event
        )
        // Filter Non-Errors events
        if (
          (error_type && error_type.includes('Non-Error')) ||
          (error_value && error_value.includes('Non-Error'))
        ) {
          return null
        }
        return event
      },
    })
  }
}

export function setViewportHeight() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
}

export async function getSignedUrlFromS3Key(key) {
  const IDENTITY_ID_UUID_REGEX =
    'private/us-east-1:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
  const relativeKey = key.substring(key.indexOf('collections'), key.length)
  const privateIdentityPrefix = key.match(IDENTITY_ID_UUID_REGEX)
  const prefix =
    key.match(/private/) &&
    privateIdentityPrefix &&
    privateIdentityPrefix !== ''
      ? `${privateIdentityPrefix[0]}/`
      : 'public/'
  // get either public or private s3 file
  return await Storage.get(relativeKey, {
    expires: 3600,
    level: 'public',
    customPrefix: { public: prefix },
  })
}

export const syncStateToProjectJson = syncState => {
  const projectJson = {
    project_file_version: 2,
    id: '',
    name: '',
    created: '',
    modified: '',
    time_signature: {},
    metronome_on: false,
    num_tracks: 0,
    tracks: [],
    dirty: false,
    tempo: 0,
    duration: 0.0,
    cached_clips: [],
    master_assistant_state: null,
  }

  projectJson.project_file_version = 2
  projectJson.created = syncState.created
  projectJson.id = syncState.id
  projectJson.num_tracks = syncState.entities.arrangement.value.length

  let duration = 0.0
  let modified = 0
  for (const entity in syncState.entities) {
    modified = Math.max(modified, syncState.entities[entity].timestamp)
    if (syncState.entities[entity].deleted) {
      continue
    }
    switch (syncState.entities[entity].category) {
      case 'lww':
        projectJson[entity] = syncState.entities[entity].value
        break
      case 'arrangement':
        for (const trackId of syncState.entities[entity].value) {
          projectJson.tracks.push({
            ...syncState.entities[trackId].value,
            ...syncState.entities.mix.value[trackId],
          })
          for (const clip of syncState.entities[trackId].value.clips) {
            const clipDurationFrames =
              clip.end_frame_in_file - clip.start_frame_in_file
            const clipEndFrame =
              clip.start_frame_in_timeline + clipDurationFrames
            duration = Math.max(duration, clipEndFrame / clip.sample_rate)
          }
        }
        break
      default:
        break
    }
  }
  projectJson.duration = duration
  projectJson.modified = modified
  return projectJson
}

export function getUpdatedSyncState(entities) {
  const { syncState, syncClientId } = store.getState().collection
  const updated = JSON.parse(JSON.stringify(syncState))
  for (const key in entities) {
    updated.entities[key] = entities[key]
    updated.entities[key].timestamp = Math.floor(Date.now() / 1000)
    if (!updated.entities[key].version[syncClientId]) {
      updated.entities[key].version[syncClientId] = [1, 0]
    } else {
      updated.entities[key].version[syncClientId][0] += 1
    }
  }
  return updated
}

export function convertGainToBase10(gain) {
  return Math.pow(10, gain / 20)
}

//  COLLECTION UTILS

const DEFAULT_METADATA = '{}'
const DEFAULT_VISIBILITY = 'publicPath'

/* ------------FORMATTING HELPERS--------------- */

const momentFormat = 'lll'

export function expirationFmt(collection) {
  if (expirationUndefined(collection)) {
    return 'Undefined'
  } else if (!collection.suppressExpiration) {
    return moment.unix(collection.expirationEpoch).format(momentFormat)
  } else {
    return 'Never'
  }
}

/* -----------------EXPIRATION RELATED-------------------*/

function expirationUndefined(c) {
  return !c.expirationEpoch && !c.suppressExpiration
}

// convert a 10 digit epoch to 13 digit then create date object
// returns null instead of throwing error on invalid date
export const convertIntEpochToDate = timestamp => {
  const paddedStr = `${timestamp}`.padEnd(13, '0')
  const longTs = parseInt(paddedStr, 10)
  const dt = new Date(longTs)
  const validDate = !isNaN(dt.valueOf())

  return validDate ? dt : null
}

// Exclude expirationDate if supressExpiration is true
export const applySuppressExpirationLogic = ({
  metadata = DEFAULT_METADATA,
  visibility = DEFAULT_VISIBILITY,
  suppressExpiration = false,
  expirationDate = null,
  ...input
}) => {
  // destructure expiration out of input object
  const fmtInput = suppressExpiration
    ? { ...input, metadata, visibility, suppressExpiration }
    : {
        ...input,
        metadata,
        visibility,
        suppressExpiration,
        expirationEpoch: parseInt(
          new Date(expirationDate)
            .valueOf()
            .toString()
            .substring(0, 10),
          10
        ),
      }

  return fmtInput
}

export const isExpiredCollection = ({
  suppressExpiration,
  expirationEpoch,
  visibility,
}) => {
  if (visibility === 'privatePath') return false
  if (!expirationEpoch) return false
  const expireDate = convertIntEpochToDate(expirationEpoch)
  const now = new Date().valueOf()
  if (suppressExpiration) return false
  if (!expireDate || isNaN(expireDate.valueOf()) || expireDate.valueOf() < now)
    return true
  return false
}

export const getExpirationMessage = ({
  suppressExpiration,
  expirationEpoch,
}) => {
  const isExpired = isExpiredCollection({ suppressExpiration, expirationEpoch })
  return suppressExpiration || !expirationEpoch
    ? null
    : `Expire${isExpired ? 'd' : 's'} ${moment(
        expirationEpoch * 1000
      ).fromNow()}`
}
export const defaultDateInputValue = (dt = new Date()) =>
  new Date(dt.setDate(dt.getDate() + 7))

export const setDefaultExpirationDate = ({ expirationEpoch }) =>
  expirationEpoch
    ? convertIntEpochToDate(expirationEpoch)
    : defaultDateInputValue()

export const formatForInputTypeDate = dt => {
  return `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${(
    '0' + dt.getDate()
  ).slice(-2)}`
}

export const parseDateFromInput = dateString => {
  const dt = new Date()
  dt.setFullYear(dateString.substring(0, 4))
  dt.setMonth(parseInt(dateString.substring(5, 7)) - 1)
  dt.setDate(dateString.substring(8, 10))
  return dt
}

export const getThemeColorObject = name => {
  return (
    themeColors.find(color => color.name === name) ||
    themeColors.find(color => color.primary === name) // handle backwards compatibility for pure hex string themeColors
  )
}

export const getTrackIcon = filename => {
  const trackName = filename.substring(
    filename.indexOf('_track_') + 9,
    filename.length
  )
  return getFileNameWithoutExtension(trackName)
}

export const createNewProjectId = () => uuid().toUpperCase()

export async function addSignedUrlsToComments(comments = []) {
  const wrapped = []
  for (const c of comments) {
    c.user.displayIcon = await getIconSignedUrl(c.user.displayIcon)
    for (const r of c.reactions) {
      r.user.displayIcon = await getIconSignedUrl(r.user.displayIcon)
    }
    wrapped.push(c)
  }
  return wrapped
}

const cachedIconUrls = {}

export async function getIconSignedUrl(key) {
  if (key) {
    if (cachedIconUrls[key]) return cachedIconUrls[key]
    const url = await Storage.get(key, { expires: 60 * 60 })
    cachedIconUrls[key] = url
    return url
  } else return null
}

export async function createNewCollection(
  {
    title,
    projectId = createNewProjectId(),
    description = '',
    expirationDate,
    suppressExpiration,
    themeColor,
    additionalParams,
  },
  authMode
) {
  try {
    return callApi(createCollection, {
      input: {
        title,
        projectId,
        description,
        expirationDate,
        suppressExpiration,
        themeColor,
        ...additionalParams,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export async function generateVisibilityPrefix(
  visibility = 'publicPath',
  identityId
) {
  return visibility === 'privatePath' && identityId
    ? `private/${identityId}`
    : `public`
}

export const labsProfileModalState = {
  title: "Let's Make it Official 😉",
  body:
    'Sign in to create a user profile and claim your Spire projects. Your personal information will always remain private.',
}

export const unauthedBeatToSpireModalState = {
  title: "Let's Make it Official 😉",
  body:
    'Sign in to create a user profile and create your first Spire project. Your personal information will always remain private.',
}

export const labsCommentModalState = {
  title: "Let's Get Collaborative 👍",
  body:
    'Sign in to share real-time comments and reactions with other Spire users. Your personal information will always remain private.',
}

const IDENTITY_ID_UUID_REGEX =
  'private/us-east-1:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

export async function getS3ObjectAmplify(s3key, options) {
  const relativeKey = s3key.substring(
    s3key.indexOf('collections'),
    s3key.length
  )
  const privateIdentityPrefix = s3key.match(IDENTITY_ID_UUID_REGEX)
  const prefix =
    s3key.match(/private/) &&
    privateIdentityPrefix &&
    privateIdentityPrefix !== ''
      ? `${privateIdentityPrefix[0]}/`
      : 'public/'
  const res = await Storage.get(relativeKey, {
    download: true,
    level: 'public',
    customPrefix: { public: prefix },
    ...options,
  })
  return res
}

export function generateSessionStorageClipPathId(
  file_name,
  start_frame,
  end_frame,
  gain
) {
  return `waveform_file:${file_name}${
    start_frame != null ? `_start:${start_frame}` : ''
  }${end_frame != null ? `_end:${end_frame}` : ''}${
    gain != null ? `_gain:${gain}` : ''
  }_width:${window.innerWidth}`
}
