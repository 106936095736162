import React, { useEffect, useState, useRef } from 'react'
import { Hub } from 'aws-amplify'
import CollectionsTable from '../CollectionsTable'
import VisibilityToggle from '../VisibilityToggle'
import Search from '../Search'
import useIsMounted from 'react-is-mounted-hook'
import {
  DistinctCollectionsById,
  getSearchParam,
  clearSearchParam,
} from './utils'
import { setModal } from 'redux/app/appReducer'
import { useDispatch } from 'react-redux'
import { callApi, listCollectionsBySearchParameter } from 'graphql/izo_api'
import Heading from 'components/base/Heading'
import Button from 'components/base/Button'
import './AdminCollections.scss'

function AdminCollections() {
  const isMounted = useIsMounted()
  const searchParam = getSearchParam()
  const [collections, setCollections] = useState(null)
  const [searchType, setSearchType] = useState(searchParam.type || 'title')
  const [searchValue, setSearchValue] = useState(searchParam.value || '')
  const [status, setStatus] = useState('idle')
  const [visibility, setVisibility] = useState(null)
  const debounceTimer = useRef(null)
  const [currentFilter, setCurrentFilter] = useState({
    value: null,
    type: null,
    visibility: null,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    initializeHub()
    return () => Hub.remove('auth')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCollections()
  }, [currentFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    debounceSearch()
  }, [searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  function debounceSearch() {
    clearTimeout(debounceTimer.current)
    debounceTimer.current = setTimeout(() => {
      status !== 'loading' &&
        setCurrentFilter({ type: searchType, value: searchValue, visibility })
    }, 500)
  }

  function initializeHub() {
    Hub.listen('auth', data => {
      const { payload } = data
      payload.event === 'signIn' && fetchCollections()
    })
  }

  async function fetchCollections() {
    try {
      setStatus('loading')
      const input = {
        searchType,
        searchValue,
        visibility,
      }
      const res = await callApi(listCollectionsBySearchParameter, { input })
      const { items } = res.data.listCollectionsBySearchParameter
      const updatedCollections = DistinctCollectionsById(items)
      if (isMounted()) {
        setCollections(updatedCollections)
        setStatus('idle')
      }
    } catch (e) {
      isMounted() && setStatus('error')
      console.error('Error while fetching collections:', e.errors[0].message)
    }
  }

  const toggleVisibility = () => {
    setVisibility(visibility ? null : 'privatePath')
    setCurrentFilter({ type: searchType, value: searchValue, visibility })
  }

  function handleClear() {
    clearSearchParam()
    setSearchValue('')
    setCurrentFilter({ type: null, value: null, visibility: visibility })
  }

  const defaultChildProps = {
    collections,
    status,
    handleSearch: () => {
      setCurrentFilter({ type: searchType, value: searchValue, visibility })
    },
    handleClear,
    searchValue,
    searchType,
    setSearchValue,
    setSearchType,
    currentFilter,
  }

  return (
    <div id="AdminCollections">
      <div className="Header">
        <Heading size={1}>Collections</Heading>
        <Button
          type="secondary"
          icon="fa-plus"
          onClick={() => dispatch(setModal('AdminCreate'))}
        />
        <VisibilityToggle
          toggleVisibility={toggleVisibility}
          visibility={visibility}
        />
        <Search {...defaultChildProps} />
      </div>
      <CollectionsTable {...defaultChildProps} />
    </div>
  )
}

export { AdminCollections as default, DistinctCollectionsById }
